import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppStateService } from '../services/state/app-state.service';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
    public constructor(private readonly appState: AppStateService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const tenant = this.appState.activeTenant.value;
        if (tenant) req = req.clone({ headers: req.headers.set('OneGo-Tenant', tenant.id) });
        return next.handle(req);
    }
}
