import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IAsset, IIssue, IPOI, ITask } from 'shared';

import { BaseEntityNamePipe } from '../../models/base-entity-name-pipe';
import { Entity, EntityType } from '../../models/entity.model';
import { AssetService } from '../services/asset.service';
import { IssueService } from '../services/issue.service';
import { TaskService } from '../services/task.service';
import { TenantService } from '../services/tenant.service';

@Pipe({
    name: 'entityName',
    pure: true,
})
export class EntityNamePipe extends BaseEntityNamePipe implements PipeTransform {
    public constructor(
        private assetService: AssetService,
        private tenantService: TenantService,
        private taskService: TaskService,
        private issueService: IssueService,
        translate: TranslateService
    ) {
        super(translate);
    }

    transform(entity: Entity, entityType: EntityType): Observable<string> {
        switch (entityType) {
            case 'TASK':
                return this.getTaskEntityName(entity as ITask);
            case 'ISSUE':
                return this.getIssueEntityName(entity as IIssue);
            case 'ASSET':
                return this.getAssetEntityName(entity as IAsset);
            case 'POI':
                return this.getPoiEntityName(entity as IPOI);
            default:
                return of(null);
        }
    }

    getTaskEntityName(entity: ITask): Observable<string> {
        return combineLatest([
            this.taskService.getTaskTypeForId(entity.typeId),
            this.tenantService.getSubjectTypeForId(entity.subjectTypeId),
        ]).pipe(switchMap(([taskType, subjectType]) => this.combineTypeAndSubjectType(taskType, subjectType)));
    }

    getIssueEntityName(entity: IIssue): Observable<string> {
        return combineLatest([
            this.issueService.getIssueTypeForId(entity.typeId),
            this.tenantService.getSubjectTypeForId(entity.subjectTypeId),
        ]).pipe(switchMap(([issueType, subjectType]) => this.combineTypeAndSubjectType(issueType, subjectType)));
    }

    getAssetEntityName(entity: IAsset): Observable<string> {
        return from(this.assetService.getAssetType(entity.typeId)).pipe(
            switchMap((assetType) => {
                return assetType ? this.translate.stream(assetType.name) : of(null);
            })
        );
    }

    getPoiEntityName(entity: IPOI): Observable<string> {
        return of(entity.description);
    }
}
