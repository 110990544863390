import {
    AfterViewInit,
    Component,
    DestroyRef,
    ElementRef,
    HostBinding,
    HostListener,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { saveAs } from 'file-saver';
import { BehaviorSubject, Observable, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';
import { IImage, fade } from 'shared';

import { Easings } from '../../../../utils/easings';
import { usingSafari } from '../../../../utils/vendor-checks';
import { AuthImagePipe } from '../../../pipes/auth-image.pipe';
import { DomComponent } from '../../../services/dom.service';

@Component({
    selector: 'app-image-viewer',
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.scss'],
    animations: [fade()],
})
export class ImageViewerComponent implements AfterViewInit {
    public __DOM_COMPONENT: DomComponent<ImageViewerComponent>;
    public images: Array<IImage> = [];
    public currentImageIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public animationTimers: Array<ReturnType<typeof setTimeout>> = [];

    @ViewChild('imageReel', { static: false })
    public imageReelEl: ElementRef;

    @ViewChildren('reelItem')
    public reelItems: QueryList<ElementRef>;

    @HostBinding('@popup')
    public popupAnimation = true;

    public scrollSnapType: 'x mandatory' | 'none' = 'x mandatory';
    public transforms: { [imageId: string]: number } = {};

    public constructor(private readonly authImage: AuthImagePipe, private readonly destroyRef: DestroyRef) {}

    private static getExtensionFromUrl(image: IImage): string {
        const extension = image.url.split('.').pop();
        return extension.indexOf('?') >= 0 ? extension.substring(0, extension.indexOf('?')) : 'jpg';
    }

    private static getExtensionFromData(imageData: string): string {
        if (imageData.includes('image/')) {
            return imageData.substring('data:image/'.length, imageData.indexOf(';base64'));
        } else {
            // Extract file type from the first character of the image's base64 data.
            // (https://stackoverflow.com/a/50111377/1835379)
            const base64Data = imageData.split('base64,').pop();
            return { '/': 'jpg', i: 'png', R: 'gif', U: 'webp' }[base64Data[0]];
        }
    }

    @HostListener('document:keydown.escape')
    public onEscapeDown(): void {
        this.__DOM_COMPONENT.remove();
    }

    public ngAfterViewInit(): void {
        this.updateScrollLeft(this.currentImageIndex$.value * this.imageReelEl.nativeElement.clientWidth, 0);
        this.updateScaleTransform(
            this.reelItems.toArray()[this.currentImageIndex$.value],
            this.images[this.currentImageIndex$.value]
        );

        fromEvent(window, 'resize')
            .pipe(debounceTime(200), takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.updateScaleTransform(
                    this.reelItems.toArray()[this.currentImageIndex$.value],
                    this.images[this.currentImageIndex$.value]
                );
            });

        this.currentImageIndex$.pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef)).subscribe((index) => {
            const reelItems = this.reelItems.toArray();
            if (index > 0) this.updateScaleTransform(reelItems[index - 1], this.images[index - 1]);
            this.updateScaleTransform(reelItems[index], this.images[index]);
            if (index + 1 < this.images.length) this.updateScaleTransform(reelItems[index + 1], this.images[index + 1]);
        });
    }

    public nextPhoto(): void {
        this.updateScrollLeft((this.currentImageIndex$.value + 1) * this.imageReelEl.nativeElement.clientWidth);
    }

    public previousPhoto(): void {
        this.updateScrollLeft((this.currentImageIndex$.value - 1) * this.imageReelEl.nativeElement.clientWidth);
    }

    public updateScrollLeft(scrollLeftTarget: number, duration = 0): void {
        // Always set to 0 for the time being
        const animationResolution = (1 / 60) * 1000;
        const scrollLeftOrigin = this.imageReelEl.nativeElement.scrollLeft;

        // Cancel any ongoing previous animation
        this.animationTimers.splice(0, this.animationTimers.length).forEach((t) => clearTimeout(t));

        // Disable scroll snapping so that scrollLeft may be modified.
        // Not doing this for Safari, as it breaks otherwise. Thanks Safari!
        if (!usingSafari()) {
            this.scrollSnapType = 'none';
        }

        // Schedule animation frames
        if (duration > 0) {
            for (let i = 0; i < duration; i += animationResolution) {
                const frameScrollLeft =
                    Easings.easeInOutCubic(i / duration) * (scrollLeftTarget - scrollLeftOrigin) + scrollLeftOrigin;
                this.animationTimers.push(
                    setTimeout(() => {
                        this.imageReelEl.nativeElement.scrollLeft = frameScrollLeft;
                    }, i)
                );
            }
        }

        // Schedule final frame
        this.animationTimers.push(
            setTimeout(() => {
                this.imageReelEl.nativeElement.scrollLeft = scrollLeftTarget;
                this.scrollSnapType = 'x mandatory';
                this.animationTimers.splice(0, this.animationTimers.length);
            }, duration)
        );
    }

    public onScroll(): void {
        if (!this.imageReelEl) return;
        const width = this.imageReelEl.nativeElement.clientWidth;
        const scroll = this.imageReelEl.nativeElement.scrollLeft;
        this.currentImageIndex$.next(
            Math.floor(Math.max(Math.min((scroll + width / 2) / width, this.images.length - 1), 0))
        );
    }

    public updateScaleTransform(reelItem: ElementRef, image: IImage): void {
        this.transforms[image.id] = Math.min(
            Math.min(
                reelItem.nativeElement.clientWidth / image.dimensions[0],
                reelItem.nativeElement.clientHeight / image.dimensions[1]
            ),
            1
        );
    }

    public downloadImage(): void {
        this.currentImageIndex$
            .pipe(
                take(1),
                switchMap((index) => this.loadImage(this.images[index])),
                tap(({ imageData, fileName }) => {
                    saveAs(imageData, fileName);
                })
            )
            .subscribe();
    }

    private loadImage(image: IImage): Observable<{ imageData: string; fileName: string }> {
        return this.authImage.transform(image.url).pipe(
            take(1),
            map((imageData) => {
                const extension = /\.\w{2-3}$/.test(image.url)
                    ? ImageViewerComponent.getExtensionFromUrl(image)
                    : imageData && ImageViewerComponent.getExtensionFromData(imageData);
                return { imageData, fileName: `${image.id}.${extension || 'jpg'}` };
            })
        );
    }
}
