import { Component, HostBinding, Input } from '@angular/core';
import { vshrink } from 'shared';

import { environment } from '../../../../../environments/environment';
import { Entity } from '../../../../models/entity.model';

@Component({
    selector: 'app-entity-list-item',
    templateUrl: './entity-list-item.component.html',
    styleUrls: ['./entity-list-item.component.scss'],
    animations: [vshrink()],
})
export class EntityListItemComponent {
    @Input() expanded = false;
    @Input() title: string;
    @Input() subtitle: string;
    @Input() summary: string;
    @Input() entity: Entity;
    @HostBinding('class.clickable') @Input() clickable = true;
    @HostBinding('class.selected') @Input() selected = false;
    devMode = environment.env === 'DEV';
}
