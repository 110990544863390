import { Injectable } from '@angular/core';
import { AuthService } from 'baseflow-auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ControlRoute } from '../../models/control-route.model';

@Injectable({ providedIn: 'root' })
export class ControlBarService {
    // Control Routing
    private readonly _routeStack: BehaviorSubject<Array<ControlRoute>> = new BehaviorSubject([]);

    private readonly _menuOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private readonly _shown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public readonly controlRoute: Observable<ControlRoute> = this._routeStack
        .asObservable()
        .pipe(map((stack) => stack[stack.length - 1]));

    public readonly lastControlRoute: Observable<ControlRoute> = this._routeStack
        .asObservable()
        .pipe(map((stack) => (stack.length >= 2 ? stack[stack.length - 2] : null)));

    public readonly menuOpen: Observable<boolean> = this._menuOpen.asObservable();
    public readonly shown: Observable<boolean> = this._shown.asObservable();

    private constructor(private authService: AuthService) {
        authService.loggedIn.pipe(filter((loggedIn) => loggedIn)).subscribe((loggedIn) => {
            this._menuOpen.next(false);
        });
    }

    pushRoute(route: ControlRoute) {
        if (!route.name) route.name = this.getNameForRoute(route);
        this._routeStack.next([...this._routeStack.value, route]);
    }

    popRoute(routes = 1) {
        const routeStack = this._routeStack.value;
        for (let i = 0; i < routes && routeStack.length > 1; i++) {
            routeStack.pop();
        }
        this._routeStack.next(routeStack);
    }

    replaceRoute(route: ControlRoute, replaceAll = false) {
        if (!route.name) route.name = this.getNameForRoute(route);
        const routeStack = replaceAll ? [] : this._routeStack.value || [];
        if (routeStack.length >= 1) {
            routeStack.splice(this._routeStack.value.length - 1, 1, route);
        } else {
            routeStack.push(route);
        }
        this._routeStack.next(routeStack);
    }

    show(value: boolean) {
        setTimeout(() => this._shown.next(value), 0);
    }

    toggle() {
        setTimeout(() => this._shown.next(!this._shown.value), 0);
    }

    showMenu(value: boolean) {
        setTimeout(() => this._menuOpen.next(value), 0);
    }

    toggleMenu() {
        setTimeout(() => this._menuOpen.next(!this._menuOpen.value), 0);
    }

    private getNameForRoute(route: ControlRoute) {
        switch (route.mode) {
            case 'LIST':
                return 'mapView.list.title';
            case 'REPORT':
                return 'mapView.report-wizard.title';
            case 'DETAIL':
                return `mapView.detail.title.${route.entityType}`;
        }
    }
}
