import { filter } from 'rxjs/operators';

import { ISocketEvent } from '../../../models/socket-event.model';
import { SocketService } from '../socket.service';

export abstract class BaseSocketEventHandler<T extends ISocketEvent> {
    protected constructor(socketService: SocketService) {
        socketService.socketEvents
            .pipe(filter((e) => e.eventDtoType === this.eventDtoType()))
            .subscribe(async (e) => await this.handleEvent(e as T));
    }

    protected abstract eventDtoType(): string;

    protected abstract handleEvent(event: T): Promise<void>;
}
