import { Component, DestroyRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IIssue, IIssueType, fade, hshrink, vshrink } from 'shared';

import { EntityAction } from '../../../../models/entity-action.model';
import { Feature } from '../../../models/feature.model';
import { ControlBarService } from '../../../services/control-bar.service';
import { IssueService } from '../../../services/issue.service';
import { LocationService } from '../../../services/location.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-issue-list-item',
    templateUrl: './issue-list-item.component.html',
    styleUrls: ['./issue-list-item.component.scss'],
    animations: [fade(), vshrink(), hshrink()],
})
export class IssueListItemComponent implements OnInit, OnChanges {
    @Input() protected issue: IIssue;
    @Input() protected issueType: IIssueType;
    @Input() protected selected = false;
    @Input() protected expanded = false;
    @Input() protected clickable = true;
    @Input() protected showActions = true;
    @Input() protected showOverflowActions = true;
    @Input() protected title?: string;
    @Input() protected subtitle?: string;
    @Input() protected disableOnSocketDisconnect: boolean;

    protected showDetails$: Observable<boolean>;
    protected simpleView = false;
    protected advancedEntityActionsCollapseAfter$: Observable<number>;
    protected actions: Array<EntityAction> = [];
    private progress: number;
    public ready = false;

    public constructor(
        private readonly controlBarService: ControlBarService,
        private readonly issueService: IssueService,
        private readonly userService: UserService,
        private readonly locationService: LocationService,
        private readonly translate: TranslateService,
        private readonly destroyRef: DestroyRef
    ) {}

    public ngOnInit(): void {
        this.advancedEntityActionsCollapseAfter$ = this.controlBarService.controlRoute.pipe(
            map((route) => (route && route.mode === 'LIST' ? 2 : 0))
        );
        this.showDetails$ = this.controlBarService.controlRoute.pipe(map((route) => route && route.mode !== 'DETAIL'));
        this.issueService.issues
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(async () => (this.actions = await this.issueService.buildActions(this.issue)));
        this.userService
            .hasFeature(Feature.SimpleListItem)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((simpleView) => (this.simpleView = simpleView));
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.issue && changes.issue.previousValue !== changes.issue.currentValue) {
            this.actions = await this.issueService.buildActions(this.issue);
            this.progress = await this.calculateProgress();

            setTimeout(() => (this.ready = true));
        }
    }

    protected goToDetails($event: MouseEvent): void {
        $event.stopImmediatePropagation();
        this.controlBarService.pushRoute({ mode: 'DETAIL', entityType: 'ISSUE', entityId: this.issue.id });
    }

    protected calculateProgress = async (): Promise<number> => {
        if (!this.issue.taskStatuses) return null;
        const total = Object.values(this.issue.taskStatuses).reduce((acc, e) => acc + e, 0);
        return total > 0 ? (this.issue.taskStatuses.DONE || 0) / total : null;
    };
}
