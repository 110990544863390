import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'baseflow-auth';
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { fade, fadeUp, hshrink, vshrink } from 'shared';

import { SocketService } from '../../../services/socket.service';
import { AppStateService } from '../../../services/state/app-state.service';
import { TenantService } from '../../../services/tenant.service';
import { UserService } from '../../../services/user.service';

interface Notification {
    title: Observable<string>;
    subtitle?: Observable<string>;
    showReconnectButton: boolean;
    icon: string;
}

const RECONNECT_ATTEMPTS = 2;

@Component({
    selector: 'app-socket-overlay',
    templateUrl: './socket-overlay.component.html',
    styleUrls: ['./socket-overlay.component.scss'],
    animations: [fadeUp('notification'), vshrink(), hshrink(), fade()],
})
export class SocketOverlayComponent {
    notification$: Observable<Notification>;

    public constructor(
        private readonly socketService: SocketService,
        private readonly authService: AuthService,
        private readonly userService: UserService,
        private readonly translate: TranslateService,
        private readonly tenantService: TenantService,
        private readonly appState: AppStateService
    ) {
        this.notification$ = combineLatest([appState.socketStatus.stream, appState.loggedInWithTenant.stream]).pipe(
            debounceTime(0),
            map(([socketStatus, loggedInWithTenant]) => {
                switch (socketStatus.status) {
                    case 'CONNECTING':
                        return {
                            title: this.translate.stream('comp.socket-overlay.CONNECTING.title'),
                            showReconnectButton: false,
                            icon: 'spinner',
                        };
                    case 'RECONNECTING':
                        if (socketStatus.attempt >= RECONNECT_ATTEMPTS) {
                            return {
                                title: this.translate.stream('comp.socket-overlay.RECONNECTING.title'),
                                subtitle: this.translate.stream('comp.socket-overlay.RECONNECTING.subtitle'),
                                showReconnectButton: false,
                                icon: 'spinner',
                            };
                        }
                        return null;
                    case 'DROPPED':
                        return {
                            title: this.translate.stream('comp.socket-overlay.DROPPED.title'),
                            subtitle: this.translate.stream('comp.socket-overlay.DROPPED.subtitle'),
                            showReconnectButton: true,
                            icon: 'wifi_off',
                        };
                    case 'DISCONNECTED':
                        if (loggedInWithTenant) {
                            return {
                                title: this.translate.stream('comp.socket-overlay.DISCONNECTED.title'),
                                subtitle: this.translate.stream('comp.socket-overlay.DISCONNECTED.subtitle'),
                                showReconnectButton: true,
                                icon: 'wifi_off',
                            };
                        }
                        return null;
                    case 'CONNECTED':
                    default:
                        return null;
                }
            })
        );
    }

    onReconnectPressed() {
        this.socketService.connect();
    }
}
