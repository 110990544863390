<div class="main-actions">
    <button
        class="btn"
        *ngIf="secondaryAction; else secondaryPlaceholder"
        [disabled]="
            disableOnSocketDisconnect
                ? (socketService.disableUI | async) || secondaryAction.disabled
                : secondaryAction.disabled
        "
        (click)="onClickAction($event, secondaryAction)"
    >
        <app-icon
            *ngIf="secondaryAction?.icon"
            @hshrink
            [key]="secondaryAction.icon"
            [style]="secondaryAction.iconStyle"
            class="icon"
        ></app-icon>
        <span>{{ secondaryAction.label | translate }}</span>
    </button>
    <ng-template #secondaryPlaceholder><div class="secondary-placeholder"></div></ng-template>
    <button
        class="btn color-secondary"
        *ngIf="primaryAction"
        [disabled]="
            disableOnSocketDisconnect
                ? (socketService.disableUI | async) || primaryAction.disabled
                : primaryAction.disabled
        "
        (click)="onClickAction($event, primaryAction)"
    >
        <app-icon
            *ngIf="primaryAction?.icon"
            @hshrink
            [key]="primaryAction.icon"
            [style]="primaryAction.iconStyle"
            class="icon"
        ></app-icon>
        <span>{{ primaryAction.label | translate }}</span>
    </button>
</div>

<div class="more-actions" *ngIf="showOverflow && overflowActions.length">
    <div class="more-actions-header" (click)="toggleOverflowExpand($event)">
        <span>{{ expandedMoreActions ? 'Minder acties' : 'Meer acties' }}</span>
        <i class="material-icons caret" [class.expanded]="expandedMoreActions">keyboard_arrow_down</i>
    </div>
    <div class="more-actions-collapsible" @vshrink *ngIf="expandedMoreActions">
        <button
            *ngFor="let action of overflowActions"
            [disabled]="
                disableOnSocketDisconnect ? (socketService.disableUI | async) || action.disabled : action.disabled
            "
            (click)="onClickAction($event, action)"
        >
            <i class="icon-{{ action.icon }}" *ngIf="action.icon"></i>
            <i class="icon-toilet"></i>
            <span>{{ action.label | translate }}</span>
        </button>
    </div>
</div>
