<div class="kv-row" [ngClass]="{ clickable: clickable, 'header-row': header }" @vshrink (click)="action()">
    <span class="key">
        <i class="material-icons" *ngIf="icon">{{ icon }}</i>
        <div class="title-wrapper">
            <span class="title">{{ title }}</span>
            <span *ngIf="subTitle">{{ subTitle }}</span>
        </div>
    </span>
    <ng-content></ng-content>
</div>
