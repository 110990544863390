import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, forkJoin } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import {
    ApiVersion,
    EntityFilters,
    FilterEntityType,
    IExternalSortingModel,
    IFilterConfigurationModel,
    IFilterModel,
    IFilterPredicateValue,
    ISortingModel,
    ITableParameters,
    SelectEntityFilter,
    TaskApiService,
} from 'shared';

import { PromptDialogActionConfig } from '../../models/simple-dialog.model';
import {
    EditFilterSetPopupComponent,
    EditFilterSetPopupValues,
} from '../components/modals/edit-filter-set-popup/edit-filter-set-popup.component';
import { AppSettingsService } from './app-settings.service';
import { DomService } from './dom.service';
import { PopupService } from './popup.service';

type ExternalOnlyFilterEntityKey = 'creatorid' | 'issuestatus' | 'issuetypeid' | 'taskstatus' | 'tasktypeid';
type ExternalFilterEntityKey<T> = keyof T | ExternalOnlyFilterEntityKey;

const filterColumnKeyMap: Record<ExternalOnlyFilterEntityKey, string> = {
    creatorid: 'creator',
    issuestatus: 'status',
    taskstatus: 'status',
    tasktypeid: 'typeid',
    issuetypeid: 'typeid',
};

const promptDialogConfig: Partial<PromptDialogActionConfig> = {
    placeholder: 'history.actionBar.popup.saveFilterSet.placeholder',
    minLength: 4,
    maxLength: 30,
    minLengthErrorKey: 'history.actionBar.popup.saveFilterSet.errors.minLengthName',
    maxLengthErrorKey: 'history.actionBar.popup.saveFilterSet.errors.maxLengthName',
};

@Injectable({ providedIn: 'root' })
export class FilterService<FilterEntity> {
    public constructor(
        private readonly http: HttpClient,
        private readonly appSettings: AppSettingsService,
        private readonly popupService: PopupService,
        private readonly taskApiService: TaskApiService,
        private readonly toastr: ToastrService,
        private readonly translateService: TranslateService,
        private readonly domService: DomService
    ) {}

    public getFiltersFor(getFiltersFor: FilterEntityType): Observable<Array<IFilterConfigurationModel<FilterEntity>>> {
        return this.getApiUrl(getFiltersFor).pipe(
            mergeMap((url) => this.http.get<Array<IFilterConfigurationModel<FilterEntity>>>(url))
        );
    }

    public getMappedProperty(filter: string): string {
        return filterColumnKeyMap[filter as ExternalOnlyFilterEntityKey] || filter;
    }

    public saveNewFilterPreset(filterEntityType: FilterEntityType, params: ITableParameters<FilterEntity>): void {
        this.popupService
            .showPrompt(
                'history.actionBar.popup.saveFilterSet.message',
                'history.actionBar.popup.saveFilterSet.title',
                promptDialogConfig
            )
            .pipe(
                filter((name) => !!name),
                switchMap((name) => this.taskApiService.postFilterPreset<FilterEntity>(name, filterEntityType)),
                switchMap((data) => this.savePresetFiltersAndSorting(data.id, params, filterEntityType)),
                catchError((error) => {
                    this.toastr.error(error, 'Error');
                    throw error;
                }),
                tap(() =>
                    this.toastr.success(this.translateService.instant('history.actionBar.popup.saveFilterSet.created'))
                )
            )
            .subscribe();
    }

    public editFilterPreset(
        presetId: string,
        filterEntityType: FilterEntityType,
        filterParams: ITableParameters<FilterEntity>
    ): void {
        const options: EditFilterSetPopupValues<FilterEntity> = {
            message: 'history.actionBar.popup.editFilterSet.message',
            title: 'history.actionBar.popup.editFilterSet.title',
            editValues: {
                tableParams: cloneDeep(filterParams),
                filterSetId: presetId,
                filterEntityType: filterEntityType,
            },
        };
        this.domService
            .appendComponentTo<EditFilterSetPopupComponent<FilterEntity>>(
                'overlay-container',
                EditFilterSetPopupComponent,
                { options }
            )
            .component.onClose.pipe(
                filter((isConfirmed) => !!isConfirmed),
                switchMap(() =>
                    this.taskApiService
                        .getFilterPreset<FilterEntity>(presetId, filterEntityType)
                        .pipe(map((oldPreset) => oldPreset))
                ),
                tap((preset) => {
                    filterParams.filters = this.mapToEntityFilters(
                        cloneDeep(preset.filters),
                        cloneDeep(filterParams.filters)
                    );
                    filterParams.sorting = this.mapToEntitySorting(
                        cloneDeep(preset.sorting),
                        cloneDeep(filterParams.sorting)
                    );
                    this.deletePresetFiltersAndSorting(preset.id, preset.filters, preset.sorting, filterEntityType);
                }),
                switchMap((preset) => this.savePresetFiltersAndSorting(preset.id, filterParams, filterEntityType)),
                catchError((error) => {
                    this.toastr.error(error, 'Error');
                    throw error;
                }),
                tap(() => {
                    this.toastr.success(this.translateService.instant('history.actionBar.popup.editFilterSet.edited'));
                })
            )
            .subscribe();
    }

    public editFilterPresetName(presetId: string, filterEntityType: FilterEntityType): void {
        this.popupService
            .showPrompt(
                'history.actionBar.popup.EditFilterSetName.message',
                'history.actionBar.popup.EditFilterSetName.title',
                promptDialogConfig
            )
            .pipe(
                filter((name) => !!name),
                switchMap((name) =>
                    this.taskApiService.patchFilterPreset<FilterEntity>(name, presetId, filterEntityType)
                ),
                catchError((error) => {
                    this.toastr.error(error, 'Error');
                    throw error;
                }),
                tap(() => {
                    this.toastr.success(this.translateService.instant('history.actionBar.popup.editFilterSet.edited'));
                })
            )
            .subscribe();
    }

    public deleteFilterPreset(
        filterPresetId: string,
        entityType: FilterEntityType
    ): Observable<IFilterConfigurationModel<FilterEntity>> {
        return this.popupService
            .showDelete(
                'history.actionBar.popup.deleteFilterSet.message',
                'history.actionBar.popup.deleteFilterSet.title'
            )
            .pipe(
                switchMap((isConfirmed) => {
                    if (!isConfirmed || !filterPresetId) return EMPTY;
                    return this.taskApiService.deleteFilterPreset<FilterEntity>(
                        filterPresetId,
                        entityType
                    ) as Observable<IFilterConfigurationModel<FilterEntity>>;
                }),
                catchError((error) => {
                    this.toastr.error(error, 'Error');
                    throw error;
                }),
                tap(() =>
                    this.toastr.success(
                        this.translateService.instant('history.actionBar.popup.deleteFilterSet.deleted')
                    )
                )
            );
    }

    public mapToEntityFilters(presetFiltersData: Array<IFilterModel>, filters: EntityFilters): EntityFilters {
        presetFiltersData.forEach((filter) => {
            for (const key in filters) {
                const mappedKey = this.getMappedProperty(filter.property.toLowerCase());
                if (Object.hasOwnProperty.call(filters, key) && key.toLowerCase() === mappedKey.toLowerCase()) {
                    (filters[key] as SelectEntityFilter).values.push(
                        ...(filter.predicates as IFilterPredicateValue).values
                    );
                }
            }
        });

        return filters;
    }

    public mapToEntitySorting(
        presetSorting: Array<ISortingModel<FilterEntity>>,
        currentSorting: Array<ISortingModel<FilterEntity>>
    ): Array<ISortingModel<FilterEntity>> {
        currentSorting.forEach((sorting) => {
            const matchingSort = presetSorting.find((sortingModel) => {
                const mappedProperty = this.getMappedProperty(sortingModel.property.toString().toLowerCase());

                return mappedProperty === sorting.property.toString().toLowerCase();
            });

            if (!matchingSort) {
                if (presetSorting.length > 0) {
                    const previous = presetSorting[presetSorting.length - 1].order;
                    sorting.order = previous + 1;
                }
                presetSorting.push(sorting);
            } else if (matchingSort.direction !== sorting.direction) {
                matchingSort.direction = sorting.direction;
            }
        });

        return presetSorting;
    }

    private transformFilters(filters: EntityFilters, entityType: FilterEntityType): Array<IFilterModel> {
        return Object.entries(filters)
            .filter(([, value]) => value.type === 'SELECT' && value.values.length > 0)
            .map(([property, value]) => ({
                type: 'value',
                property: this.mapEntityFilterKeyToEntityKey(
                    property as ExternalFilterEntityKey<FilterEntity>,
                    entityType
                ) as string,
                predicates: { values: 'values' in value ? value.values : value.range },
            }));
    }

    private transformSorting(
        sorting: Array<ISortingModel<FilterEntity>>,
        entityType: FilterEntityType
    ): Array<IExternalSortingModel<ExternalFilterEntityKey<FilterEntity>>> {
        return Object.values(sorting).map((value) => ({
            property: this.mapEntityFilterKeyToEntityKey(
                value.property,
                entityType
            ) as ExternalFilterEntityKey<FilterEntity>,
            direction: value.direction,
            order: value.order,
        }));
    }

    private mapEntityFilterKeyToEntityKey(
        key: ExternalFilterEntityKey<FilterEntity>,
        type: FilterEntityType
    ): ExternalFilterEntityKey<FilterEntity> {
        const keyMap = {
            creator: 'creatorid',
        } as Partial<Record<ExternalFilterEntityKey<FilterEntity>, ExternalFilterEntityKey<FilterEntity>>>;
        switch (type) {
            case FilterEntityType.Issue:
                Object.assign(keyMap, {
                    status: 'issuestatus',
                    typeId: 'issuetypeid',
                });
                break;
            case FilterEntityType.Task:
                Object.assign(keyMap, {
                    status: 'taskstatus',
                    typeId: 'tasktypeid',
                });
                break;
        }
        return keyMap[key] ?? key;
    }

    private getApiUrl(getFiltersFor: FilterEntityType, apiVersion: ApiVersion = 'v1'): Observable<string> {
        return this.appSettings.appSettings.pipe(
            take(1),
            map((appSettings) => {
                const entityType = {
                    0: `issues`,
                    1: `tasks`,
                }[getFiltersFor];

                return `${appSettings.url.api.tasks}/${apiVersion}/${entityType}/filters/presets`;
            })
        );
    }

    private savePresetFiltersAndSorting(
        presetId: string,
        params: ITableParameters<FilterEntity>,
        filterEntityType: FilterEntityType
    ): Observable<Array<ISortingModel<FilterEntity> | IFilterModel>> {
        const filterObservables = this.transformFilters(params.filters, filterEntityType).map((filter) =>
            this.taskApiService.postFilterPresetFilters(presetId, filter, filterEntityType)
        );

        const sortingObservables = this.transformSorting(params.sorting, filterEntityType).map((sort) =>
            this.taskApiService.postFilterPresetSorting<FilterEntity>(
                presetId,
                sort as IExternalSortingModel<ExternalFilterEntityKey<FilterEntity>>,
                filterEntityType
            )
        );

        return forkJoin([...filterObservables, ...sortingObservables]);
    }

    private deletePresetFiltersAndSorting(
        presetId: string,
        filters: Array<IFilterModel>,
        sorting: Array<ISortingModel<FilterEntity>>,
        filterEntityType: FilterEntityType
    ): void {
        const deleteFilterActions = filters.map((f) =>
            this.taskApiService.deleteFilterPresetFilters(presetId, f.id, filterEntityType)
        );
        const deleteSortingActions = sorting.map((s) =>
            this.taskApiService.deleteFilterPresetSorting<FilterEntity>(presetId, s.id, filterEntityType)
        );
        forkJoin([...deleteFilterActions, ...deleteSortingActions]).subscribe();
    }
}
