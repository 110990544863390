import { ILogger, LogLevel } from '@microsoft/signalr';

export class ConsoleLogger implements ILogger {
    private readonly logOutputMap: { [key in LogLevel]: (message: string) => void } = {
        [LogLevel.Trace]: console.trace,
        [LogLevel.Debug]: console.debug,
        [LogLevel.Information]: console.info,
        [LogLevel.Warning]: console.warn,
        [LogLevel.Error]: console.error,
        [LogLevel.Critical]: console.error,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        [LogLevel.None]: () => {},
    };

    public log(logLevel: LogLevel, message: string): void {
        return this.logOutputMap[logLevel](message);
    }
}
