import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from 'shared';

import { UserService } from '../services/user.service';

@Pipe({
    name: 'userShortName',
    pure: true,
})
export class UserShortNamePipe implements PipeTransform {
    public constructor(private userService: UserService) {}

    transform(user: IUser): string {
        return this.userService.getUserShortName(user);
    }
}
