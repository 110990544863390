import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { vshrink } from 'shared';
import { v4 as uuid } from 'uuid';

import { formatBytes } from '../../../pipes/file-size.pipe';

export interface ISelectedImage {
    id: string;
    file: File;
    objectURL: SafeResourceUrl;
}

const IMAGE_QUALITY = 0.9;

@Component({
    selector: 'app-image-selector',
    templateUrl: './image-selector.component.html',
    styleUrls: ['./image-selector.component.scss'],
    animations: [vshrink()],
})
export class ImageSelectorComponent implements OnInit, OnDestroy {
    images: Array<ISelectedImage>;
    @Input() fileLimit = 0;
    @Input() sizeLimit = 0;
    @Input() disabled: boolean;
    @Output() imagesChange: EventEmitter<Array<ISelectedImage>> = new EventEmitter<Array<ISelectedImage>>();

    public constructor(
        private toastr: ToastrService,
        private translate: TranslateService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        this.reset();
    }

    reset(): void {
        this.images?.forEach((image) => URL.revokeObjectURL(image.objectURL.toString()));
        this.images = [];
        this.imagesChange.emit(this.images);
    }

    trackImages(index: number, item: ISelectedImage): string {
        return item.id;
    }

    onFileInput($event: Event): void {
        this.getImages($event.target as HTMLInputElement)
            .then((imageBlobs: Array<Blob>) => {
                this.verifySizeLimits(imageBlobs);
                return imageBlobs;
            })
            .then((imageBlobs) => {
                imageBlobs.forEach((blob) => {
                    this.images.push({
                        id: uuid(),
                        file: new File([blob], `img-${uuid()}`),
                        objectURL: this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)),
                    });
                });

                this.imagesChange.emit(this.images);
            })
            .catch(() => {
                this.toastr.error(
                    this.translate.instant('comp.image-selector.error.generic.message', {
                        fileSize: formatBytes(this.sizeLimit),
                    }),
                    this.translate.instant('comp.image-selector.error.generic.title')
                );
            });
    }

    removeImage(index: number): void {
        this.images.splice(index, 1);
        this.imagesChange.emit(this.images);
    }

    ngOnDestroy(): void {
        this.images.forEach((image) => URL.revokeObjectURL(image.objectURL.toString()));
    }

    getImages(inputElement: HTMLInputElement): Promise<Array<Blob>> {
        return Promise.all(
            Array(this.fileLimit - (this.images.length ?? 0))
                .fill(0)
                .map((_, index) => inputElement.files.item(index))
                .filter((file) => file != null)
                .map((file) => this.convertAndCompressImage(file))
        );
    }

    convertAndCompressImage(file: File): Promise<Blob> {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => {
                const canvas = document.createElement('canvas');

                const ratio = Math.max(image.height / 1080, 1);
                const dimensions = [image.width / ratio, image.height / ratio];

                canvas.width = dimensions[0];
                canvas.height = dimensions[1];
                canvas.getContext('2d').drawImage(image, 0, 0, dimensions[0], dimensions[1]);

                canvas.toBlob((blob) => (blob ? resolve(blob) : reject(blob)), 'image/jpeg', IMAGE_QUALITY);
            };
            image.src = URL.createObjectURL(file);
        });
    }

    private verifySizeLimits(imageBlobs: Array<Blob>) {
        const totalSize =
            [...imageBlobs, ...this.images.map((i) => i.file)].reduce(
                (size, currentFile) => size + currentFile.size,
                0
            ) + 4096;

        if (this.sizeLimit && totalSize >= this.sizeLimit) {
            this.toastr.error(
                this.translate.instant('comp.image-selector.error.tooLarge.message', {
                    fileSize: formatBytes(this.sizeLimit),
                }),
                this.translate.instant('comp.image-selector.error.tooLarge.title')
            );
            return;
        }
    }
}
