import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-map-zoom-controls',
    templateUrl: './map-zoom-controls.component.html',
    styleUrls: ['./map-zoom-controls.component.scss'],
})
export class MapZoomControlsComponent {
    @Output() public zoomIn = new EventEmitter<void>();
    @Output() public zoomOut = new EventEmitter<void>();
}
