<div class="modal">
    <div class="modal-header">
        <div class="modal-title">{{ 'comp.block-reason-popup.presetTitle' | translate }}</div>
        <button class="modal-close" (click)="close()">
            <i class="material-icons">clear</i>
        </button>
    </div>
    <div class="modal-content">
        <div class="bf-input reason-select">
            <select name="taskType" [(ngModel)]="blockReasonId">
                <option [ngValue]="undefined">Select reason...</option>
                <option *ngFor="let reason of taskService.blockReasons | async" [ngValue]="reason.id">
                    {{ reason.name | translate }}
                </option>
                <option [ngValue]="'CUSTOM'">{{ 'comp.block-reason-popup.otherReason' | translate }}</option>
            </select>
            <i></i>
        </div>
        <div class="bf-input reason-note" *ngIf="blockReasonId === 'CUSTOM'" @vshrink>
            <textarea
                class="reason-input"
                [(ngModel)]="blockReasonNote"
                [placeholder]="'comp.block-reason-popup.customInputPlaceholder' | translate"
            ></textarea>
        </div>
    </div>
    <div class="modal-btn-row">
        <button
            class="btn color-secondary btn-large"
            (click)="onSubmit()"
            [disabled]="!blockReasonId || (blockReasonId === 'CUSTOM' && !blockReasonNote)"
        >
            <i class="material-icons">send</i>
            <span>{{ 'comp.block-reason-popup.submit' | translate }}</span>
        </button>
    </div>
</div>
