import { Component, Input } from '@angular/core';
import { hshrink, vshrink } from 'shared';

@Component({
    selector: 'app-list-item-flags',
    templateUrl: './list-item-flags.component.html',
    styleUrls: ['./list-item-flags.component.scss'],
    animations: [vshrink(), hshrink()],
})
export class ListItemFlagsComponent {
    @Input() urgent = false;
    @Input() comments = false;
}
