import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { IComment } from 'shared';

import { CommentService } from '../../../services/comment.service';
import { DomService } from '../../../services/dom.service';
import { ImageViewerService } from '../../../services/image-viewer.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-comment-item',
    templateUrl: './comment-item.component.html',
    styleUrls: ['./comment-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentItemComponent implements OnInit, OnChanges {
    private comment$: BehaviorSubject<IComment> = new BehaviorSubject<IComment>(null);
    @Input()
    public comment: IComment;

    public userFullName: string;

    public constructor(
        private readonly userService: UserService,
        private readonly translationService: TranslateService,
        private readonly commentService: CommentService,
        private readonly domService: DomService,
        private readonly cdr: ChangeDetectorRef,
        private readonly imageViewerService: ImageViewerService,
        private readonly destroyRef: DestroyRef
    ) {}

    ngOnInit(): void {
        this.comment$
            .pipe(
                filter((comment) => !!comment),
                switchMap((comment) => forkJoin([this.getUserInfo(), this.getCommentDetail()])),
                tap(([userFullName, commentDetail]) => {
                    this.userFullName = userFullName;
                    this.comment = commentDetail;
                    this.cdr.markForCheck();
                }),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const comment = changes.comment?.currentValue;
        if (comment) {
            this.comment$.next(comment);
        }
    }

    openImages(index: number): void {
        this.imageViewerService.openImages(this.comment.images, index);
    }

    private getUserInfo(): Observable<string> {
        const actor = this.comment.actor;
        if (actor?.type === 'PlatformUser') {
            return this.userService.getUser(actor?.userId).pipe(
                map((user) => user?.fullName),
                take(1),
                switchMap((fullName) =>
                    fullName ? of(fullName) : this.translationService.get('mapView.detail.comments.users.unknown')
                )
            );
        }
        return this.translationService.get('mapView.detail.comments.users.unknown');
    }

    private getCommentDetail(): Observable<IComment> {
        if (this.comment.numberOfImages > 0) {
            return this.commentService
                .getComment(this.comment.id)
                .pipe(tap((commentDetail: IComment) => (this.comment = commentDetail)));
        }
        return of(this.comment);
    }
}
