import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, isEqual } from 'lodash';
import { map, tap } from 'rxjs/operators';
import {
    EntityFilters,
    FilterEntityType,
    IFilterModel,
    IFilterPredicateValue,
    ISortingModel,
    ITableParameters,
    SelectEntityFilter,
    TaskApiService,
} from 'shared';

import { FilterService } from '../../../services/filter.service';
import { POIService } from '../../../services/poi.service';
import { UserService } from '../../../services/user.service';
import { BasePopupComponent } from '../base-popup/base-popup.component';

export interface EditFilterSetPopupValues<FilterEntity> {
    title: string;
    message: string;
    editValues: {
        tableParams: ITableParameters<FilterEntity>;
        filterSetId: string;
        filterEntityType: FilterEntityType;
    };
}
interface PropertyUpdate {
    property: string;
    updated: string;
    updatedValues: Array<string>;
}

@Component({
    selector: 'app-edit-filter-set-popup',
    templateUrl: './edit-filter-set-popup.component.html',
    styleUrls: ['./edit-filter-set-popup.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EditFilterSetPopupComponent<FilterEntity>
    extends BasePopupComponent<EditFilterSetPopupComponent<FilterEntity>>
    implements OnDestroy, OnInit
{
    public options: EditFilterSetPopupValues<FilterEntity>;
    public onClose = new EventEmitter<boolean | null>();
    public showMore: { [property: string]: boolean } = {};
    public filterSetName: string = null;
    public cutoffLength = 7;

    protected addedSorting = new Array<ISortingModel<FilterEntity>>();
    protected removedSorting = new Array<ISortingModel<FilterEntity>>();
    protected updatedFilters = new Array<PropertyUpdate>();

    public constructor(
        elementRef: ElementRef,
        private readonly taskApiService: TaskApiService,
        private filterService: FilterService<FilterEntity>,
        private translateService: TranslateService,
        private poiService: POIService,
        private userService: UserService
    ) {
        super(elementRef);
        this.popupOptions.padding = 0;
    }

    public ngOnInit(): void {
        const values = this.options.editValues;
        this.taskApiService
            .getFilterPreset(values.filterSetId, values.filterEntityType)
            .pipe(
                tap((filterSet) => {
                    this.filterSetName = filterSet.displayName;
                    values.tableParams.filters = this.filterService.mapToEntityFilters(
                        cloneDeep(filterSet.filters),
                        cloneDeep(values.tableParams.filters)
                    );
                    values.tableParams.sorting = this.filterService.mapToEntitySorting(
                        cloneDeep(filterSet.sorting),
                        cloneDeep(values.tableParams.sorting)
                    );
                }),
                tap((filterSet) => {
                    this.compareSorting(values.tableParams.sorting, filterSet.sorting);

                    this.updatedFilters = this.compareFilters(cloneDeep(values.tableParams.filters), filterSet.filters);
                })
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this.emitClose();
    }

    public checkArrays(): boolean {
        return this.updatedFilters.length === 0 && this.addedSorting.length === 0 && this.removedSorting.length === 0;
    }

    public toggleMoreValues(item: PropertyUpdate, $event?: MouseEvent): void {
        $event?.stopImmediatePropagation();
        this.showMore[item.property] = !this.showMore[item.property];
    }

    public close(isConfirmed: boolean = null, $event?: MouseEvent): void {
        $event?.stopImmediatePropagation();
        this.emitClose(isConfirmed as boolean);
        this.__DOM_COMPONENT.remove();
    }

    protected formatItem(item: ISortingModel<FilterEntity>, last: boolean): string {
        return (
            item.property.toString() +
            ' (' +
            (item.direction === 'ascending' ? item.direction.slice(0, 3) : item.direction.slice(0, 4)) +
            ')' +
            (!last ? ',' : '')
        );
    }

    private emitClose(onCloseData: boolean = null): void {
        this.onClose.emit(onCloseData);
        this.onClose.complete();
    }

    private compareSorting(
        newSorting: Array<ISortingModel<FilterEntity>>,
        filterSetSorting: Array<ISortingModel<FilterEntity>>
    ): void {
        newSorting.forEach((newSortItem) => {
            const foundItem = filterSetSorting.find((filterSetSortItem) =>
                isEqual(filterSetSortItem.property, newSortItem.property)
            );
            if (!foundItem) {
                this.addedSorting.push(newSortItem);
            } else if (newSortItem.direction !== foundItem.direction) {
                this.addedSorting.push(newSortItem);
                this.removedSorting.push(foundItem);
            }
        });
    }

    private compareFilters(newFilters: EntityFilters, filterSetArray: Array<IFilterModel>): Array<PropertyUpdate> {
        const updates: Array<PropertyUpdate> = [];
        const mappedFilterArray = filterSetArray.map((filter) => ({
            ...filter,
            property: this.filterService.getMappedProperty(filter.property).toLowerCase(),
        }));
        const keyReplacements: { [key: string]: string } = {
            typeId: FilterEntityType[this.options.editValues.filterEntityType].toLowerCase() + 'Type',
            poiId: 'location',
            subjectTypeId: 'subjectType',
        };

        for (const newFilterKey in newFilters) {
            const newFilter = newFilters[newFilterKey] as SelectEntityFilter;
            const existingFilter = mappedFilterArray.find(
                (filter) => filter.property.toLowerCase() === newFilterKey.toLowerCase()
            );

            if (existingFilter) {
                updates.push(
                    ...this.compareAndExtractFilterUpdates(
                        mappedFilterArray,
                        newFilterKey,
                        keyReplacements[newFilterKey] || newFilterKey,
                        newFilter.values
                    )
                );
            } else if (newFilter.values.length > 0) {
                const addedValues = newFilter.values.map((value) =>
                    this.mapFilterIdToTypeProperty(newFilterKey, value, this.options.editValues.filterEntityType)
                );

                if (addedValues.length > 0) {
                    updates.push({
                        property: keyReplacements[newFilterKey] || newFilterKey,
                        updated: 'added',
                        updatedValues: addedValues,
                    });
                }
            }
        }

        return updates;
    }

    private compareAndExtractFilterUpdates(
        mappedFilterArray: Array<IFilterModel>,
        key: string,
        replacedKey: string,
        valuesInFilter: Array<string>
    ): Array<PropertyUpdate> {
        const updates: Array<PropertyUpdate> = [];
        for (const prop in mappedFilterArray) {
            const filterProperty = mappedFilterArray[prop].property.toLowerCase();
            if (filterProperty.toLowerCase() === key.toLowerCase()) {
                const oldValues = mappedFilterArray[prop].predicates as IFilterPredicateValue;
                const addedValues = valuesInFilter
                    .filter((value) => !oldValues.values.includes(value))
                    .map((value) =>
                        this.mapFilterIdToTypeProperty(key, value, this.options.editValues.filterEntityType)
                    );
                if (addedValues.length > 0) {
                    updates.push({ property: replacedKey, updated: 'added', updatedValues: addedValues });
                }

                const removedValues = oldValues.values
                    .filter((value) => !valuesInFilter.includes(value))
                    .map((value) =>
                        this.mapFilterIdToTypeProperty(key, value, this.options.editValues.filterEntityType)
                    );

                if (removedValues.length > 0) {
                    updates.push({ property: replacedKey, updated: 'removed', updatedValues: removedValues });
                }
            }
        }
        return updates;
    }

    private mapFilterIdToTypeProperty(key: string, id: string, filterEntityType: FilterEntityType): string {
        let addedValue: string;
        switch (key.toLowerCase()) {
            case 'typeid':
                return this.translateService.instant(
                    filterEntityType === FilterEntityType.Issue ? `issueType.${id}` : `taskType.${id}`
                );
            case 'subjecttypeid':
                return this.translateService.instant(`subjectType.${id}`);
            case 'status':
                return this.translateService.instant(
                    filterEntityType === FilterEntityType.Issue
                        ? `comp.entity-status.issue.${id}`
                        : `comp.entity-status.task.${id}`
                );
            case 'assigneeid':
            case 'inspectorid':
            case 'creator':
                this.userService
                    .getUser(id)
                    .pipe(map((user) => (addedValue = user.fullName)))
                    .subscribe();
                break;
            case 'poiid':
                this.poiService
                    .getPOIForId(id)
                    .pipe(map((poi) => (addedValue = poi.description)))
                    .subscribe();
                break;
        }

        return addedValue;
    }
}
