import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, skip } from 'rxjs/operators';
import { fadeUp, vshrink } from 'shared';

import { Entity } from '../../../../models/entity.model';
import { BasePopupComponent } from '../../../../mtp-common/components/modals/base-popup/base-popup.component';
import { IGeneratedUrl } from '../../../../mtp-common/models/generated-url.interface';
import { SharingService } from '../../../../mtp-common/services/sharing.service';
import { TaskService } from '../../../../mtp-common/services/task.service';

@Component({
    selector: 'app-entity-share-popup',
    templateUrl: './entity-share-popup.component.html',
    styleUrls: ['./entity-share-popup.component.scss'],
    animations: [fadeUp('popup'), vshrink()],
})
export class EntitySharePopupComponent extends BasePopupComponent<EntitySharePopupComponent> implements OnInit {
    @Input() entityType: 'TASK' | 'ISSUE';
    @Input() entity: Entity;

    generatedUrl: IGeneratedUrl;
    copied = false;

    // Share link parameters
    urlExpiryDays: BehaviorSubject<string> = new BehaviorSubject<string>('30');
    allowFinish: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public constructor(
        elementRef: ElementRef,
        public taskService: TaskService,
        private sharingService: SharingService
    ) {
        super(elementRef);
        this.popupOptions.padding = 0;
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();
        this.generatedUrl = await this.generateUrl();
        this.subscribeInput();
    }

    @HostListener('click', ['$event'])
    clickBg($event: MouseEvent): void {
        if (this.closable && this.elementRef.nativeElement === $event.target) {
            $event.stopImmediatePropagation();
            this.__DOM_COMPONENT.remove();
        }
    }

    close(): void {
        this.__DOM_COMPONENT.remove();
    }

    generateUrl = async () => {
        if (this.generatedUrl?.shareId) {
            return this.sharingService.updateShareUrl(
                this.generatedUrl.shareId,
                Math.floor(Date.now() / 1000) + parseInt(this.urlExpiryDays.value, 10) * 24 * 3600,
                this.entityType === 'TASK' ? this.allowFinish.value : false
            );
        }
        return this.sharingService.generateShareUrl(
            this.entity.id,
            this.entityType,
            Math.floor(Date.now() / 1000) + parseInt(this.urlExpiryDays.value, 10) * 24 * 3600,
            this.entityType === 'TASK' ? this.allowFinish.value : false
        );
    };

    copyUrl(): Promise<void> {
        return navigator.clipboard.writeText(this.generatedUrl.shareUrl).then(() => {
            this.copied = true;
        });
    }

    shareUrl(): void {
        if (this.canShare()) {
            navigator
                .share({
                    text: 'Text',
                    title: 'Title',
                    url: this.generatedUrl.shareUrl,
                })
                .catch((err) => {
                    console.error(err);
                    this.copyUrl().catch(() => console.error('Failed to copy contents to clipboard'));
                });
        }
    }

    canShare(): boolean {
        return !!navigator.share;
    }

    private subscribeInput(): void {
        combineLatest([this.urlExpiryDays, this.allowFinish])
            .pipe(
                skip(1),
                debounceTime(300),
                map(([expiry, allowFinish]) => [parseInt(expiry), allowFinish] as [number, boolean]),
                filter(([expiry]) => !isNaN(expiry) && expiry > 0),
                distinctUntilChanged(
                    ([prevExpiry, prevAllowFinish], [currExpiry, currAllowFinish]) =>
                        prevExpiry === currExpiry && prevAllowFinish === currAllowFinish
                ),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(this.generateUrl);
    }
}
