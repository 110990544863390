<div class="header-top">
    <div class="title">
        <button class="btn btn-square btn-flat color-none" (click)="toggleControlMenu()" *ngIf="showMenuButton">
            <i class="material-icons">menu</i>
        </button>
        <span>{{ title }}</span>
    </div>
    <div class="header-buttons">
        <ng-content></ng-content>
    </div>
</div>
<div class="header-bars">
    <ng-content select="app-ml-side-control-header-bar"></ng-content>
</div>
