import { Component, ElementRef } from '@angular/core';
import { fadeUp } from 'shared';

import { ControlBarService } from '../../../services/control-bar.service';
import { BasePopupComponent } from '../../modals/base-popup/base-popup.component';

@Component({
    selector: 'app-map-mobile-return-overlay-component',
    templateUrl: './map-mobile-return-overlay.component.html',
    styleUrls: ['./map-mobile-return-overlay.component.scss'],
    animations: [fadeUp('popup')],
})
export class MapMobileReturnOverlayComponent extends BasePopupComponent<MapMobileReturnOverlayComponent> {
    public constructor(elementRef: ElementRef, private controlBarService: ControlBarService) {
        super(elementRef);
        this.popupOptions.padding = 0;
        this.popupOptions.pointerEvents = 'none';
    }

    onBack() {
        this.controlBarService.show(true);
        this.__DOM_COMPONENT.remove();
    }
}
