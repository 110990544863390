<div class="modal modal-md">
    <div class="modal-header">
        <div *ngIf="entityType" class="modal-title">
            {{ 'comp.entity-share-popup.title.' + entityType | translate }}
        </div>
        <button (click)="close()" class="modal-close">
            <i class="material-icons">clear</i>
        </button>
    </div>
    <div class="modal-content">
        <p *ngIf="entityType">{{ 'comp.entity-share-popup.message.' + entityType | translate }}</p>
        <div class="url-bar">
            <div class="bf-input with-icon">
                <input #urlInputEl [value]="generatedUrl?.shareUrl || ''" readonly type="text" />
                <span class="material-icons">share</span>
            </div>
            <div class="url-bar-action-buttons">
                <button (click)="copyUrl()" [disabled]="!generatedUrl" class="btn btn-primary color-secondary">
                    <span>
                        {{
                            copied
                                ? ('comp.entity-share-popup.copied' | translate)
                                : ('comp.entity-share-popup.copy' | translate)
                        }}
                    </span>
                </button>
                <button
                    (click)="shareUrl()"
                    *ngIf="canShare()"
                    [disabled]="!generatedUrl"
                    class="btn btn-primary color-secondary"
                >
                    <span>
                        {{ 'comp.entity-share-popup.share' | translate }}
                    </span>
                </button>
            </div>
        </div>
        <div class="option-row">
            <span class="pre-label">{{ 'comp.entity-share-popup.expiryDays' | translate }}</span>
            <input
                #expiryInput
                (change)="urlExpiryDays.next(expiryInput.value)"
                [value]="urlExpiryDays | async"
                class="small"
                max="999"
                min="1"
                type="number"
            />
            <span class="post-label">{{ 'comp.entity-share-popup.expiryDaysUnit' | translate }}</span>
        </div>
        <div *ngIf="entityType === 'TASK'" class="option-row">
            <span class="pre-label">{{ 'comp.entity-share-popup.allowFinish' | translate }}</span>
            <label class="bf-checkbox">
                <input
                    #allowFinishInput
                    (change)="allowFinish.next(allowFinishInput.checked)"
                    [checked]="allowFinish.value"
                    name="allowFinish"
                    type="checkbox"
                />
                <span class="bf-checkbox-slider"></span>
            </label>
        </div>
    </div>
</div>
