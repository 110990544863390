import { CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { AuthConfigRepositoryService, BaseflowAuthModule } from 'baseflow-auth';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { SpinnerComponent, TooltipDirective } from 'shared';

import { CollapsibleComponent } from './components/collapsible/collapsible.component';
import { AdvancedEntityActionsOverflowPopupComponent } from './components/entity-components/advanced-entity-actions/action-row-overflow-popup/advanced-entity-actions-overflow-popup.component';
import { AdvancedEntityActionsComponent } from './components/entity-components/advanced-entity-actions/advanced-entity-actions.component';
import { AssetListItemComponent } from './components/entity-components/asset-list-item/asset-list-item.component';
import { AuditLogEntryComponent } from './components/entity-components/audit-log/audit-log-entry/audit-log-entry.component';
import { AuditLogComponent } from './components/entity-components/audit-log/audit-log.component';
import { CommentItemComponent } from './components/entity-components/comment-item/comment-item.component';
import { CommentPaneComponent } from './components/entity-components/comment-pane/comment-pane.component';
import { DateRangeFilterComponent } from './components/entity-components/date-range-filter/date-range-filter.component';
import { EntityDetailsListItemComponent } from './components/entity-components/entity-details-list-item/entity-details-list-item.component';
import { EntityDetailsMapComponent } from './components/entity-components/entity-details-map/entity-details-map.component';
import { EntityFilterComponent } from './components/entity-components/entity-filter/entity-filter.component';
import { EntityListItemComponent } from './components/entity-components/entity-list-item/entity-list-item.component';
import { IssueProgressComponent } from './components/entity-components/entity-progress-bar/issue-progress.component';
import { EntityStatusComponent } from './components/entity-components/entity-status/entity-status.component';
import { ImageSelectorComponent } from './components/entity-components/image-selector/image-selector.component';
import { ImageUploadProgressComponent } from './components/entity-components/image-upload-progress/image-upload-progress.component';
import { IssueListItemComponent } from './components/entity-components/issue-list-item/issue-list-item.component';
import { LinkedTaskComponent } from './components/entity-components/linked-entity/linked-task/linked-task.component';
import { ListItemFlagsComponent } from './components/entity-components/list-item-flags/list-item-flags.component';
import { PhotoGridComponent } from './components/entity-components/photo-grid/photo-grid.component';
import { POIListItemComponent } from './components/entity-components/poi-list-item/poi-list-item.component';
import { SimpleEntityActionsComponent } from './components/entity-components/simple-entity-actions/simple-entity-actions.component';
import { TaskListItemComponent } from './components/entity-components/task-list-item/task-list-item.component';
import { CheckboxComponent } from './components/inputs/checkbox/checkbox.component';
import { DateSelectorComponent } from './components/inputs/date-selector/date-selector.component';
import { InlineTextFieldComponent } from './components/inputs/inline-textfield/inline-text-field.component';
import { LocationSelectListComponent } from './components/inputs/location-select/location-select-list/location-select-list.component';
import { LocationSelectComponent } from './components/inputs/location-select/location-select.component';
import { SegmentedButtonSelectorComponent } from './components/inputs/segmented-button-selector/segmented-button-selector.component';
import { BreadcrumbBarComponent } from './components/layouts/map-layout/components/breadcrumb-bar/breadcrumb-bar.component';
import { ControlMenuItemComponent } from './components/layouts/map-layout/components/control-menu/control-menu-item/control-menu-item.component';
import { ControlMenuComponent } from './components/layouts/map-layout/components/control-menu/control-menu.component';
import { PillBarComponent } from './components/layouts/map-layout/components/pill-bar/pill-bar.component';
import { MLOverlayControlsComponent } from './components/layouts/map-layout/m-l-overlay-controls/m-l-overlay-controls.component';
import { MLSideControlContentComponent } from './components/layouts/map-layout/m-l-side-control-content/m-l-side-control-content.component';
import { MLSideControlHeaderBarComponent } from './components/layouts/map-layout/m-l-side-control-header/m-l-side-control-header-bar/m-l-side-control-header-bar.component';
import { MLSideControlHeaderComponent } from './components/layouts/map-layout/m-l-side-control-header/m-l-side-control-header.component';
import { MapLayoutComponent } from './components/layouts/map-layout/map-layout.component';
import { MapMobileReturnOverlayComponent } from './components/map/map-mobile-return-overlay-component/map-mobile-return-overlay.component';
import { MapOrdinalControlComponent } from './components/map/map-ordinal-control/map-ordinal-control.component';
import { OrdinalSelectPopupComponent } from './components/map/map-ordinal-control/ordinal-select-popup/ordinal-select-popup.component';
import { MapSearchBarComponent } from './components/map/map-search-bar/map-search-bar.component';
import { MapZoomControlsComponent } from './components/map/map-zoom-controls/map-zoom-controls.component';
import { MapComponent } from './components/map/map/map.component';
import { MarkerComponent } from './components/map/marker/marker.component';
import { ActionsPopupComponent } from './components/modals/actions-popup/actions-popup.component';
import { BasePopupComponent } from './components/modals/base-popup/base-popup.component';
import { ConfirmInfoPopupComponent } from './components/modals/confirm-info-popup/confirm-info-popup.component';
import { EditFilterSetPopupComponent } from './components/modals/edit-filter-set-popup/edit-filter-set-popup.component';
import { ErrorPopupComponent } from './components/modals/error-popup/error-popup.component';
import { FilterSelectPopupComponent } from './components/modals/filter-select-popup/filter-select-popup.component';
import { FilterSetOptionsPopupComponent } from './components/modals/filter-set-options-popup/filter-set-options-popup.component';
import { ImageViewerComponent } from './components/modals/image-viewer/image-viewer.component';
import { SimpleSelectPopupComponent } from './components/modals/simple-select-popup/simple-select-popup.component';
import { SnackbarComponent } from './components/modals/snackbar/snackbar.component';
import { SocketOverlayComponent } from './components/modals/socket-overlay/socket-overlay.component';
import { UserSelectPopupComponent } from './components/modals/user-select-popup/user-select-popup.component';
import { SecondaryMenuComponent } from './components/secondary-menu/secondary-menu.component';
import { AppStateMonitorComponent } from './components/util/app-state-monitor/app-state-monitor.component';
import { BulkActionBarComponent } from './components/util/bulk-action-bar/bulk-action-bar.component';
import { CircularProgressIndicatorComponent } from './components/util/circular-progress-indicator/circular-progress-indicator.component';
import { ContextListHeaderComponent } from './components/util/context-list/context-list-header/context-list-header.component';
import { ContextListItemComponent } from './components/util/context-list/context-list-item/context-list-item.component';
import { ContextListComponent } from './components/util/context-list/context-list.component';
import { DetailPaneActionsMorePopupComponent } from './components/util/detail-pane-actions/detail-pane-actions-more-popup/detail-pane-actions-more-popup.component';
import { DetailPaneActionsComponent } from './components/util/detail-pane-actions/detail-pane-actions.component';
import { IconComponent } from './components/util/icon/icon.component';
import { ImageThumbComponent } from './components/util/image-thumb/image-thumb.component';
import { TabBarComponent } from './components/util/tab-bar/tab-bar.component';
import { TabComponent } from './components/util/tab-bar/tab/tab.component';
import { TableServerSideComponent } from './components/util/table-server-side/table-server-side.component';
import { TableComponent } from './components/util/table/table.component';
import { TimeframeDisplayComponent } from './components/util/timeframe-display/timeframe-display.component';
import { ViewContainerComponent } from './components/util/view-container/view-container.component';
import { BlurhashDirective } from './directives/blurhash.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { DefaultRouterLinkActiveDirective } from './directives/default-router-link-active.directive';
import { FocusOnHoverDirective } from './directives/focus-on-hover.directive';
import { FocusableDirective } from './directives/focusable.directive';
import { SmoothHeightDirective } from './directives/smooth-height.directive';
import { VarDirective } from './directives/var.directive';
import { ErrorInterceptor } from './interceptors/error-interceptor.service';
import { LanguageInterceptor } from './interceptors/language-interceptor.service';
import { TenantInterceptor } from './interceptors/tenant-interceptor.service';
import { UnauthorizedInterceptor } from './interceptors/unauthorized-interceptor.service';
import { AssetTypePipe } from './pipes/asset-type.pipe';
import { AuthImagePipe } from './pipes/auth-image.pipe';
import { EntityLocationDescriptionPipe } from './pipes/entity-location-description.pipe';
import { EntityNamePipe } from './pipes/entity-name.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { FromLocaleStringMapPipe } from './pipes/from-locale-string-map.pipe';
import { FuzzySearchPipe } from './pipes/fuzzy-search.pipe';
import { IssueTypePipe } from './pipes/issue-type.pipe';
import { LocationDescriptionPipe } from './pipes/location-description.pipe';
import { MarkedPipe } from './pipes/marked.pipe';
import { PoiCategoryPipe } from './pipes/poi-category.pipe';
import { PoiPipe } from './pipes/poi.pipe';
import { SanitizeStylePipe } from './pipes/sanitize-style.pipe';
import { SanitizeUrlPipe } from './pipes/sanitize-url.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { SubLocationNamePipe } from './pipes/sub-location-name.pipe';
import { SubjectTypePipe } from './pipes/subject-type.pipe';
import { TaskTypePipe } from './pipes/task-type.pipe';
import { UserFeaturePipe } from './pipes/user-feature.pipe';
import { UserPermissionPipe } from './pipes/user-permission.pipe';
import { UserShortNamePipe } from './pipes/user-short-name.pipe';
import { UserPipe } from './pipes/user.pipe';
import { AppSettingsService } from './services/app-settings.service';
import { GeolocatorService } from './services/geolocator.service';
import { IssueArchivedSocketEventHandler } from './services/socket-event-handlers/issue-archived.se-handler';
import { IssueCreatedSocketEventHandler } from './services/socket-event-handlers/issue-created.se-handler';
import { IssueStatusUpdatedSocketEventHandler } from './services/socket-event-handlers/issue-status-updated.se-handler';
import { IssueTypesUpdatedSocketEventHandler } from './services/socket-event-handlers/issue-types-updated.se-handler';
import { POICategoriesUpdatedSocketEventHandler } from './services/socket-event-handlers/poi-categories-updated.se-handler';
import { TaskArchivedSocketEventHandler } from './services/socket-event-handlers/task-archived.se-handler';
import { TaskAssignedSocketEventHandler } from './services/socket-event-handlers/task-assigned.se-handler';
import { TaskCreatedSocketEventHandler } from './services/socket-event-handlers/task-created.se-handler';
import { TaskPatchedSocketEventHandler } from './services/socket-event-handlers/task-patched.se-handler';
import { TaskPublishedSocketEventHandler } from './services/socket-event-handlers/task-published.se-handler';
import { TaskStatusUpdatedSocketEventHandler } from './services/socket-event-handlers/task-status-updated.se-handler';
import { TaskTypesUpdatedSocketEventHandler } from './services/socket-event-handlers/task-types-updated.se-handler';
import { TaskUnassignedSocketEventHandler } from './services/socket-event-handlers/task-unassigned.se-handler';
import { SocketService } from './services/socket.service';
import { AppStateService } from './services/state/app-state.service';
import { UpdaterService } from './services/updater.service';
import { UserService } from './services/user.service';

@NgModule({
    declarations: [
        // Components
        ActionsPopupComponent,
        AdvancedEntityActionsComponent,
        AdvancedEntityActionsOverflowPopupComponent,
        AppStateMonitorComponent,
        AssetListItemComponent,
        AuditLogComponent,
        AuditLogEntryComponent,
        BasePopupComponent,
        BreadcrumbBarComponent,
        BulkActionBarComponent,
        CheckboxComponent,
        CircularProgressIndicatorComponent,
        CollapsibleComponent,
        CommentItemComponent,
        CommentPaneComponent,
        ConfirmInfoPopupComponent,
        ContextListComponent,
        ContextListHeaderComponent,
        ContextListItemComponent,
        ControlMenuComponent,
        ControlMenuItemComponent,
        DateRangeFilterComponent,
        DateSelectorComponent,
        DetailPaneActionsComponent,
        DetailPaneActionsMorePopupComponent,
        EditFilterSetPopupComponent,
        EntityDetailsListItemComponent,
        EntityDetailsMapComponent,
        EntityFilterComponent,
        EntityListItemComponent,
        EntityStatusComponent,
        ErrorPopupComponent,
        FilterSelectPopupComponent,
        FilterSetOptionsPopupComponent,
        IconComponent,
        ImageSelectorComponent,
        ImageThumbComponent,
        ImageUploadProgressComponent,
        ImageViewerComponent,
        InlineTextFieldComponent,
        IssueListItemComponent,
        IssueProgressComponent,
        LinkedTaskComponent,
        ListItemFlagsComponent,
        LocationSelectComponent,
        LocationSelectListComponent,
        MLOverlayControlsComponent,
        MLSideControlContentComponent,
        MLSideControlHeaderBarComponent,
        MLSideControlHeaderComponent,
        MapComponent,
        MapLayoutComponent,
        MapMobileReturnOverlayComponent,
        MapOrdinalControlComponent,
        MapSearchBarComponent,
        MapZoomControlsComponent,
        MarkerComponent,
        OrdinalSelectPopupComponent,
        POIListItemComponent,
        PhotoGridComponent,
        PillBarComponent,
        SecondaryMenuComponent,
        SegmentedButtonSelectorComponent,
        SimpleEntityActionsComponent,
        SimpleSelectPopupComponent,
        SnackbarComponent,
        SocketOverlayComponent,
        TabBarComponent,
        TabComponent,
        TableComponent,
        TableServerSideComponent,
        TaskListItemComponent,
        UserSelectPopupComponent,
        ViewContainerComponent,
        // Pipes
        AssetTypePipe,
        AuthImagePipe,
        EntityLocationDescriptionPipe,
        EntityNamePipe,
        FileSizePipe,
        FromLocaleStringMapPipe,
        FuzzySearchPipe,
        IssueTypePipe,
        LocationDescriptionPipe,
        MarkedPipe,
        PoiCategoryPipe,
        PoiPipe,
        SanitizeStylePipe,
        SanitizeUrlPipe,
        SortPipe,
        SubLocationNamePipe,
        SubjectTypePipe,
        TaskTypePipe,
        UserFeaturePipe,
        UserPermissionPipe,
        UserPipe,
        UserShortNamePipe,
        // Directives
        BlurhashDirective,
        ClickOutsideDirective,
        DefaultRouterLinkActiveDirective,
        FocusOnHoverDirective,
        FocusableDirective,
        SmoothHeightDirective,
        VarDirective,
        TimeframeDisplayComponent,
    ],
    providers: [
        // Interceptors
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LanguageInterceptor,
            multi: true,
            deps: [UserService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TenantInterceptor,
            multi: true,
            deps: [AppStateService],
        },
        // 3rd party
        MatDatepickerModule,
        // Pipes
        AssetTypePipe,
        AuthImagePipe,
        DatePipe,
        EntityLocationDescriptionPipe,
        EntityNamePipe,
        FileSizePipe,
        FromLocaleStringMapPipe,
        FuzzySearchPipe,
        IssueTypePipe,
        LocationDescriptionPipe,
        MarkedPipe,
        PoiCategoryPipe,
        PoiPipe,
        SanitizeStylePipe,
        SanitizeUrlPipe,
        SortPipe,
        SubLocationNamePipe,
        SubjectTypePipe,
        TaskTypePipe,
        TranslatePipe,
        UserFeaturePipe,
        UserPermissionPipe,
        UserPipe,
        UserShortNamePipe,
    ],
    imports: [
        CommonModule,
        NgPipesModule,
        MomentModule,
        BaseflowAuthModule,
        TranslateModule.forChild({
            extend: true,
        }),
        FormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        RouterModule,
        QRCodeModule,
        NgOptimizedImage,
        // Standalone components / directives
        SpinnerComponent,
        TooltipDirective,
    ],
    exports: [
        // Components
        ActionsPopupComponent,
        AdvancedEntityActionsComponent,
        AdvancedEntityActionsOverflowPopupComponent,
        AppStateMonitorComponent,
        AssetListItemComponent,
        AuditLogComponent,
        AuditLogEntryComponent,
        BasePopupComponent,
        BreadcrumbBarComponent,
        BulkActionBarComponent,
        CheckboxComponent,
        CircularProgressIndicatorComponent,
        CollapsibleComponent,
        CommentItemComponent,
        CommentPaneComponent,
        ContextListComponent,
        ContextListHeaderComponent,
        ContextListItemComponent,
        ControlMenuComponent,
        DateRangeFilterComponent,
        DateSelectorComponent,
        DetailPaneActionsComponent,
        DetailPaneActionsMorePopupComponent,
        EntityDetailsListItemComponent,
        EntityDetailsMapComponent,
        EntityFilterComponent,
        EntityListItemComponent,
        EntityStatusComponent,
        ErrorPopupComponent,
        FilterSelectPopupComponent,
        FilterSetOptionsPopupComponent,
        ImageSelectorComponent,
        ImageThumbComponent,
        ImageUploadProgressComponent,
        ImageViewerComponent,
        InlineTextFieldComponent,
        IssueListItemComponent,
        IssueProgressComponent,
        LinkedTaskComponent,
        ListItemFlagsComponent,
        LocationSelectComponent,
        LocationSelectListComponent,
        MLOverlayControlsComponent,
        MLSideControlContentComponent,
        MLSideControlHeaderBarComponent,
        MLSideControlHeaderComponent,
        MapComponent,
        MapLayoutComponent,
        MapMobileReturnOverlayComponent,
        MapOrdinalControlComponent,
        MapSearchBarComponent,
        MapZoomControlsComponent,
        MarkerComponent,
        OrdinalSelectPopupComponent,
        POIListItemComponent,
        PhotoGridComponent,
        PillBarComponent,
        SecondaryMenuComponent,
        SegmentedButtonSelectorComponent,
        SimpleEntityActionsComponent,
        SimpleSelectPopupComponent,
        SnackbarComponent,
        SocketOverlayComponent,
        TabBarComponent,
        TabComponent,
        TableComponent,
        TableServerSideComponent,
        TaskListItemComponent,
        UserSelectPopupComponent,
        ViewContainerComponent,
        // Pipes
        AssetTypePipe,
        AuthImagePipe,
        EntityNamePipe,
        FileSizePipe,
        FuzzySearchPipe,
        FromLocaleStringMapPipe,
        IssueTypePipe,
        LocationDescriptionPipe,
        EntityLocationDescriptionPipe,
        MarkedPipe,
        PoiCategoryPipe,
        PoiPipe,
        SanitizeStylePipe,
        SanitizeUrlPipe,
        SubjectTypePipe,
        SubLocationNamePipe,
        TaskTypePipe,
        SortPipe,
        UserFeaturePipe,
        UserPermissionPipe,
        UserPipe,
        UserShortNamePipe,
        // Directives
        BlurhashDirective,
        ClickOutsideDirective,
        DefaultRouterLinkActiveDirective,
        FocusOnHoverDirective,
        FocusableDirective,
        NgOptimizedImage,
        SmoothHeightDirective,
        TooltipDirective,
        VarDirective,
        // Shared components
        SpinnerComponent,
        TimeframeDisplayComponent,
    ],
})
export class MtpCommonModule {
    public constructor(
        // "Unused" services injected to trigger init
        private readonly appSettingsService: AppSettingsService,
        private readonly authConfigRepository: AuthConfigRepositoryService,
        private readonly translate: TranslateService,
        private readonly updater: UpdaterService,
        private readonly geolocatorService: GeolocatorService,
        private readonly socketService: SocketService,
        private readonly taskAssignedSocketEventHandler: TaskAssignedSocketEventHandler,
        private readonly taskUnassignedSocketEventHandler: TaskUnassignedSocketEventHandler,
        private readonly taskCreatedSocketEventHandler: TaskCreatedSocketEventHandler,
        private readonly taskPatchedSocketEventHandler: TaskPatchedSocketEventHandler,
        private readonly taskStatusUpdatedSocketEventHandler: TaskStatusUpdatedSocketEventHandler,
        private readonly taskTypesUpdatedSocketEventHandler: TaskTypesUpdatedSocketEventHandler,
        private readonly issueTypesUpdatedSocketEventHandler: IssueTypesUpdatedSocketEventHandler,
        private readonly poiCategoriesUpdatedSocketEventHandler: POICategoriesUpdatedSocketEventHandler,
        private readonly issueCreatedSocketEventHandler: IssueCreatedSocketEventHandler,
        private readonly issueStatusUpdatedSocketEventHandler: IssueStatusUpdatedSocketEventHandler,
        private readonly issueArchivedSocketEventHandler: IssueArchivedSocketEventHandler,
        private readonly taskArchivedSocketEventHandler: TaskArchivedSocketEventHandler,
        private readonly taskPublishedSocketEventHandler: TaskPublishedSocketEventHandler
    ) {}
}
