import { Component, Input } from '@angular/core';
import { fade } from 'shared';

@Component({
    selector: 'app-image-upload-progress',
    templateUrl: './image-upload-progress.component.html',
    styleUrls: ['./image-upload-progress.component.scss'],
    animations: [fade()],
})
export class ImageUploadProgressComponent {
    @Input() progress = 0;
    @Input() label: string;
}
