<div class="image-selector">
    <div class="image-preview" *ngFor="let image of images; index as i; trackBy: trackImages" @vshrink>
        <img [src]="image.objectURL" />
        <div class="remove-image" (click)="removeImage(i)" @vshrink>
            <i class="material-icons">delete</i>
        </div>
    </div>
    <button
        class="btn btn-outline color-secondary"
        type="button"
        (click)="fileInput.click()"
        [disabled]="disabled || (fileLimit && images.length >= fileLimit)"
    >
        <i class="material-icons">camera_alt</i>
    </button>
</div>
<input #fileInput type="file" accept="image/*" hidden (change)="onFileInput($event)" multiple />
