<div class="popup-arrow"></div>
<app-context-list>
    <div *ngFor="let action of actions" @listItem>
        <app-context-list-item
            appFocusable
            appFocusOnHover
            [disabled]="action.disabled"
            (click)="!action.disabled ? onActionClick(action, $event) : null"
            (keydown.enter)="onActionClick(action, $event)"
        >
            {{ action.label | translate }}
        </app-context-list-item>
    </div>
</app-context-list>
