<div class="wrapper" [ngClass]="'column-count-' + ((actions?.length ?? 0) + 1)">
    <div class="actions-primary">
        <div class="selection-count">
            {{ 'comp.table.actionBar.changeCount' | translate : { count: selectionCount } }}
        </div>
        <button
            class="action action-primary"
            *ngFor="let action of actionsPrimary"
            [disabled]="isDisabled(action) | async"
            (click)="action.onClick($event)"
        >
            <app-icon class="icon" [key]="action.icon" [style]="'outlined'"></app-icon>
            <span>{{ action.label | translate : { count: selectionCount } }}</span>
        </button>
    </div>
    <div class="actions-secondary" *ngFor="let action of actionsSecondary">
        <button
            class="action action-secondary"
            [disabled]="isDisabled(action) | async"
            (click)="action.onClick($event)"
            [ogsTooltip]="action.label"
        >
            <app-icon class="icon" [key]="action.icon" [style]="'outlined'"></app-icon>
        </button>
    </div>
    <button *ngIf="actionsMore.length" class="action action-more" (click)="openMoreActionsMenu($event)">
        <app-icon class="icon" [key]="'more_vert'"></app-icon>
        <span>{{ 'comp.bulk-action-bar.moreActions' | translate }}</span>
    </button>
    <div>
        <div class="bulk-progress-bar" *ngIf="isNumber(progress)" @fade>
            <div class="bulk-progress-bar-fill" [style.width]="'calc(' + progress * 100 + '% + 6px)'"></div>
        </div>
    </div>
</div>
