import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { ISubjectType } from 'shared';

import { TenantService } from '../services/tenant.service';

@Pipe({
    name: 'subjectType',
    pure: true,
})
export class SubjectTypePipe implements PipeTransform {
    public constructor(private tenantService: TenantService) {}

    transform(subjectTypeId: string): Observable<ISubjectType> {
        return this.tenantService.getSubjectTypeForId(subjectTypeId);
    }
}
