import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import Fuse from 'fuse.js';
import { Observable, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import { ILocation, IPOI, vshrink } from 'shared';

import { GeolocatorService } from '../../../../services/geolocator.service';
import { POIService } from '../../../../services/poi.service';

const MAX_RESULTS = 6;

interface POILocationSelectResult {
    type: 'POI';
    poi: IPOI;
    location: ILocation;
}

interface CurrentLocationSelectResult {
    type: 'CURRENT_LOCATION';
    location: ILocation;
}

interface ExactLocationSelectResult {
    type: 'EXACT';
    location: ILocation;
}

export type LocationSelectResult = ExactLocationSelectResult | POILocationSelectResult | CurrentLocationSelectResult;

@Component({
    selector: 'app-location-select-list',
    templateUrl: './location-select-list.component.html',
    styleUrls: ['./location-select-list.component.scss'],
    animations: [vshrink('listItem'), vshrink('listHeader')],
})
export class LocationSelectListComponent implements AfterViewInit {
    @ViewChild('searchInputEl') searchInputEl: ElementRef<HTMLInputElement>;
    results: Observable<Array<IPOI>>;

    @Output() focusResult: EventEmitter<LocationSelectResult> = new EventEmitter<LocationSelectResult>();
    @Output() selectResult: EventEmitter<LocationSelectResult> = new EventEmitter<LocationSelectResult>();

    public constructor(private poiService: POIService, public geolocatorService: GeolocatorService) {}

    ngAfterViewInit() {
        this.results = fromEvent<KeyboardEvent>(this.searchInputEl.nativeElement, 'input').pipe(
            debounceTime(300),
            map((event) => (event.target as HTMLInputElement).value),
            distinctUntilChanged(),
            mergeMap((query) => this.getResults(query))
        );
    }

    getResults(query: string): Observable<Array<IPOI>> {
        return this.poiService.searchPOIs({}).pipe(
            map((pois) => {
                // Define fuse instance for fuzzy search
                const fuse = new Fuse(pois, {
                    keys: [
                        { name: 'description', weight: 0.8 },
                        { name: 'location.description', weight: 0.2 },
                    ],
                    shouldSort: true,
                    includeScore: true,
                    threshold: 0.5,
                });
                // Execute fuzzy search
                const fuseResults: Array<Fuse.FuseResult<IPOI>> = fuse.search<IPOI>(query);
                return fuseResults.slice(0, MAX_RESULTS).map((r) => r.item);
            })
        );
    }

    trackPOIBy(index: number, item: IPOI) {
        return item.id;
    }

    onSearchEnterDown() {
        let sibling = this.searchInputEl.nativeElement.nextElementSibling as HTMLElement;
        while (sibling != null && ((sibling.tabIndex !== 0 && !sibling.tabIndex) || sibling.tabIndex < 0))
            sibling = sibling.nextElementSibling as HTMLElement;
        if (sibling && sibling.tabIndex >= 0) sibling.focus();
    }

    focusPOI(poi: IPOI) {
        this.focusResult.emit({
            type: 'POI',
            poi,
            location: poi.location,
        });
    }

    selectPOI(poi: IPOI) {
        this.selectResult.emit({
            type: 'POI',
            poi,
            location: poi.location,
        });
    }

    async focusCurrentLocation() {
        const location = this.geolocatorService.lastKnownLocation;
        this.focusResult.emit({
            type: 'CURRENT_LOCATION',
            location,
        });
    }

    async selectCurrentLocation() {
        const location = this.geolocatorService.lastKnownLocation;
        this.selectResult.emit({
            type: 'CURRENT_LOCATION',
            location,
        });
    }
}
