import { Component } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { chain } from 'lodash';
import { map, take, tap } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { DerivedStateValue, StateValue } from '../../../../utils/state-value';
import { AppStateService } from '../../../services/state/app-state.service';

interface IStateValueEntry {
    key: string;
    expanded: boolean;
    value: StateValue<any> | DerivedStateValue<any>;
}

interface IMonitorConfig {
    enabled: boolean;
    expanded: boolean;
    expandedValues: Partial<Record<keyof AppStateService, boolean>>;
}

const STORAGE_KEY = 'APP_STATE_MONITOR_CONFIG';

@Component({
    selector: 'app-app-state-monitor',
    templateUrl: './app-state-monitor.component.html',
    styleUrls: ['./app-state-monitor.component.scss'],
})
export class AppStateMonitorComponent {
    protected config: IMonitorConfig;
    protected stateValues: Array<IStateValueEntry>;

    public constructor(private readonly appState: AppStateService, private readonly storage: StorageMap) {
        if (environment.env !== 'DEV') return;

        this.initFromStorage();
        this.exposeActivationMethod();
    }

    protected toggleMonitor(): void {
        this.config.expanded = !this.config.expanded;
        this.saveConfig();
    }

    protected toggleStateValue(entry: IStateValueEntry): void {
        entry.expanded = !entry.expanded;
        this.config.expandedValues = chain(this.stateValues).keyBy('key').mapValues('expanded').value();
        this.saveConfig();
    }

    private initFromStorage(): void {
        this.storage
            .get(STORAGE_KEY)
            .pipe(
                map((config) => config as IMonitorConfig),
                tap((config) => {
                    this.config = config ?? {
                        expanded: false,
                        expandedValues: {},
                        enabled: false,
                    };

                    this.stateValues = Object.keys(this.appState).map((key) => {
                        const appStateKey = key as keyof AppStateService;
                        return {
                            key: appStateKey,
                            expanded: (config && config.expandedValues[appStateKey]) ?? false,
                            value: this.appState[appStateKey],
                        };
                    });
                }),
                take(1)
            )
            .subscribe();
    }

    private saveConfig(): void {
        this.storage.set(STORAGE_KEY, this.config).subscribe();
    }

    private enableAppStateMonitor(): void {
        this.config.enabled = true;
        this.saveConfig();
    }

    private exposeActivationMethod(): void {
        const global = window as unknown as { enableAppStateMonitor: () => void };
        global.enableAppStateMonitor = this.enableAppStateMonitor.bind(this);
    }
}
