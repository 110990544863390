import { inject } from '@angular/core';
import { ITask, ITaskType } from 'shared';

import { SVG_MARKER_PIN } from '../../../../../../resources';
import { TaskService } from '../../../../services/task.service';
import {
    CachedMarker,
    GenericMapMarkerClickEvent,
    GenericMarkerMapLayerController,
    MarkerFocus,
    QueryHandler,
} from './base-controllers/generic-marker.map-layer-controller';

// Note: These values match the marker styling. Changing them here will require changes in the marker styling as well and vice-versa.
export const TASK_MARKER_SIZE = {
    width: 27,
    height: 37,
    x: -13,
    y: -37,
};

export class TaskMapLayerController extends GenericMarkerMapLayerController<ITask> {
    private taskService: TaskService = inject(TaskService);

    public constructor(options?: { queryHandler: QueryHandler<ITask> }) {
        super({ queryHandler: options?.queryHandler, markerAnchor: 'bottom' });
    }

    public static buildElementForOptions(options: { type?: ITaskType; focus?: MarkerFocus } = {}): HTMLDivElement {
        const el = document.createElement('div');

        const markerEl = document.createElement('div');
        markerEl.classList.add('mtp-map-marker', 'mtp-map-task-marker');

        const shape = document.createElement('div');
        shape.classList.add('mtp-map-task-marker-shape');
        shape.innerHTML = SVG_MARKER_PIN;
        markerEl.appendChild(shape);

        const iconId = options.type?.iconId;
        if (iconId) {
            const icon = document.createElement('i');
            icon.classList.add('icon-' + iconId);
            markerEl.appendChild(icon);
        }

        markerEl.classList.toggle('focused', options.focus === 'focused');
        markerEl.classList.toggle('blurred', options.focus === 'blurred');

        el.appendChild(markerEl);
        return el;
    }

    public override buildElement(marker: CachedMarker<ITask>): Element {
        return TaskMapLayerController.buildElementForOptions({
            type: this.taskService.getTaskTypeForIdSync(marker.entity.typeId),
            focus: marker.focus,
        });
    }

    protected createCachedMarker(entity: ITask, focus: MarkerFocus): CachedMarker<ITask> {
        return {
            entity,
            focus,
        };
    }

    protected override buildClickEvent(cachedMarker: CachedMarker<ITask>): GenericMapMarkerClickEvent<ITask> {
        const event = super.buildClickEvent(cachedMarker);
        event.type = 'TASK';
        return event;
    }
}
