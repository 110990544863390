import { Component, ElementRef } from '@angular/core';
import { fadeUp, hshrink, vshrink } from 'shared';

import { EntityAction } from '../../../../../models/entity-action.model';
import { SocketService } from '../../../../services/socket.service';
import { BasePopupComponent } from '../../../modals/base-popup/base-popup.component';

@Component({
    selector: 'app-detail-pane-actions-more-popup',
    templateUrl: './detail-pane-actions-more-popup.component.html',
    styleUrls: ['./detail-pane-actions-more-popup.component.scss'],
    animations: [fadeUp('popup'), vshrink('listItem'), vshrink('listHeader'), hshrink()],
})
export class DetailPaneActionsMorePopupComponent extends BasePopupComponent<DetailPaneActionsMorePopupComponent> {
    actions: Array<EntityAction>;

    public constructor(elementRef: ElementRef, public socketService: SocketService) {
        super(elementRef);
        this.popupOptions.padding = 24;
    }

    public onClickAction(action: EntityAction, $event: MouseEvent): void {
        if (!action.disabled) {
            action.onClick($event);
            this.__DOM_COMPONENT.remove();
        }
    }
}
