import { Component, DestroyRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPOI, IPOICategory, fade, hshrink, vshrink } from 'shared';

import { EntityAction } from '../../../../models/entity-action.model';
import { Feature } from '../../../models/feature.model';
import { ControlBarService } from '../../../services/control-bar.service';
import { POIService } from '../../../services/poi.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-poi-list-item',
    templateUrl: './poi-list-item.component.html',
    styleUrls: ['./poi-list-item.component.scss'],
    animations: [fade(), vshrink(), hshrink()],
})
export class POIListItemComponent implements OnInit, OnChanges {
    @Input() poi: IPOI;
    @Input() poiCategory: IPOICategory;
    @Input() selected = false;
    @Input() expanded = false;
    @Input() clickable = true;
    @Input() showActions = true;
    @Input() showOverflowActions = true;
    @Input() title?: string | undefined;
    @Input() subtitle?: string | undefined;
    @Input() disableOnSocketDisconnect: boolean;
    showDetails$: Observable<boolean>;
    simpleView$: Observable<boolean>;
    advancedEntityActionsCollapseAfter$: Observable<number>;
    actions: Array<EntityAction> = [];
    ready = false;

    public constructor(
        private controlBarService: ControlBarService,
        public poiService: POIService,
        private userService: UserService,
        private readonly destroyRef: DestroyRef
    ) {}

    ngOnInit(): void {
        this.advancedEntityActionsCollapseAfter$ = this.controlBarService.controlRoute.pipe(
            map((route) => (route && route.mode === 'LIST' ? 2 : 0))
        );
        this.showDetails$ = this.controlBarService.controlRoute.pipe(map((route) => route && route.mode !== 'DETAIL'));
        this.poiService.pois
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(async () => (this.actions = await this.poiService.buildActions(this.poi)));
        this.simpleView$ = this.userService.hasFeature(Feature.SimpleListItem);
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.poi && changes.poi.previousValue !== changes.poi.currentValue) {
            this.actions = await this.poiService.buildActions(this.poi);
            setTimeout(() => (this.ready = true));
        }
    }

    goToDetails($event: MouseEvent) {
        $event.stopImmediatePropagation();
        this.controlBarService.pushRoute({ mode: 'DETAIL', entityType: 'POI', entityId: this.poi.id });
    }

    protected getDefaultSubtitle(): string {
        const tokens = [this.poiCategory.name];
        if (this.poi.building) tokens.push(this.poi.building.name);
        return tokens.join(' · ');
    }
}
