<ng-container *ngIf="poi">
    <app-map
        class="map"
        [ordinal]="mapOrdinal | async"
        [mapConfig]="mapConfig | async"
        [layerControllers]="layerControllers"
        #map
    ></app-map>
    <div class="footer" *ngIf="poi.description">
        <i class="material-icons-outlined">location_on</i>
        <span>{{ poi.description }}</span>
        <ng-container *ngIf="poi.categoryId">
            <span class="light">&bull;</span>
            <span class="light">{{ (poi.categoryId | poiCategory | async)?.name }}</span>
        </ng-container>
    </div>
</ng-container>
