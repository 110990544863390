import { Component, ElementRef, OnInit } from '@angular/core';
import { fadeUp } from 'shared';

import { BasePopupComponent } from '../base-popup/base-popup.component';

interface SnackbarAction {
    primary?: boolean;
    label: string;
    onClick: () => void;
}

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
    animations: [fadeUp('popup')],
})
export class SnackbarComponent extends BasePopupComponent<SnackbarComponent> implements OnInit {
    _progress = 0;

    text = '';
    actions: Array<SnackbarAction> = [];
    duration = 5000;

    public constructor(elementRef: ElementRef) {
        super(elementRef);
        this.popupOptions.padding = 0;
        this.popupOptions.closeByClickOutside = false;
        this.popupOptions.pointerEvents = 'none';
        this.popupOptions.closeByEsc = false;
    }

    ngOnInit(): void {
        super.ngOnInit();
        for (let i = 0; i <= this.duration; i += Math.min(500, this.duration - i + 1)) {
            setTimeout(() => {
                this._progress = Math.min(i / this.duration, 1);
                if (i === this.duration) setTimeout(() => this.__DOM_COMPONENT.remove(), 500);
            }, i);
        }
    }
}
