export function ordinal(locale: string, n: number): string {
    switch (locale) {
        case 'en': {
            if (n >= 11 && n <= 13) return `${n}th`;
            switch (n % 10) {
                case 1:
                    return `${n}st`;
                case 2:
                    return `${n}nd`;
                case 3:
                    return `${n}rd`;
            }
            return `${n}th`;
        }
        case 'nl': {
            return `${n}e`;
        }
        default:
            return n.toString(10);
    }
}

export function oxford(tokens: Array<string>, conjunction: string): string {
    const l = tokens.length;
    if (!l) return '';
    if (l < 2) return tokens[0];
    if (l < 3) return tokens.join(` ${conjunction} `);
    tokens = tokens.slice();
    tokens[l - 1] = `${conjunction} ${tokens[l - 1]}`;
    return tokens.join(', ');
}
