import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { ILocation } from 'shared';

import { LocationService } from '../services/location.service';

@Pipe({
    name: 'locationDescription',
    pure: false,
})
export class LocationDescriptionPipe implements PipeTransform {
    public constructor(private locationService: LocationService) {}

    public transform(location: ILocation): Observable<string> {
        return this.locationService.getLocationDescription(location);
    }
}
