import { Component, EventEmitter, Output } from '@angular/core';

import { ITableData } from '../../util/table/interfaces/table-data.interface';
import { BasePopupComponent } from '../base-popup/base-popup.component';

@Component({
    selector: 'app-filter-set-options-popup',
    templateUrl: './filter-set-options-popup.component.html',
    styleUrls: ['./filter-set-options-popup.component.scss'],
})
export class FilterSetOptionsPopupComponent<T extends ITableData> extends BasePopupComponent<
    FilterSetOptionsPopupComponent<T>
> {
    @Output() public filterDeleted = new EventEmitter<string>();
    @Output() public filterEditName = new EventEmitter<string>();
    @Output() public filterEdit = new EventEmitter<string>();

    public filterPresetId: string;

    public deleteFilter($event: MouseEvent): void {
        $event.stopImmediatePropagation();
        this.filterDeleted.emit(this.filterPresetId);
    }

    public editFilterName($event: MouseEvent): void {
        $event.stopImmediatePropagation();
        this.filterEditName.emit(this.filterPresetId);
    }

    public editFilter($event: MouseEvent): void {
        $event.stopImmediatePropagation();
        this.filterEdit.emit(this.filterPresetId);
    }
}
