import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { IFilterConfigurationModel } from 'shared';

import { FuzzySearchPipe } from '../../../pipes/fuzzy-search.pipe';
import { SortPipe } from '../../../pipes/sort.pipe';
import { ITableData } from '../../util/table/interfaces/table-data.interface';
import { BaseSearchablePopupComponent } from '../base-popup/base-searchable-popup.component';

export interface MenuData {
    presetId: string;
    $event: MouseEvent;
}

@Component({
    selector: 'app-filter-select-popup',
    templateUrl: './filter-select-popup.component.html',
    styleUrls: ['./filter-select-popup.component.scss'],
})
export class FilterSelectPopupComponent<T extends ITableData>
    extends BaseSearchablePopupComponent<IFilterConfigurationModel<T>>
    implements AfterViewInit
{
    @Output() public openEditMenu = new EventEmitter<MenuData>();
    @Output() public savePreset = new EventEmitter<boolean>();

    public selectedId?: string;
    public noFilters = false;

    public constructor(elementRef: ElementRef, sortPipe: SortPipe, fuzzySearchPipe: FuzzySearchPipe) {
        super(elementRef, sortPipe, fuzzySearchPipe);
    }

    public ngAfterViewInit(): void {
        this.initialize('displayName', this.allEntities$);
    }

    protected addNewFilterPreset($event: MouseEvent): void {
        if (this.noFilters) return;
        $event.stopImmediatePropagation();
        this.savePreset.emit(true);
    }

    protected toggleDropdown(presetId: string, $event: MouseEvent): void {
        $event.stopImmediatePropagation();
        this.openEditMenu.emit({ presetId, $event });
    }
}
