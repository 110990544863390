import { Component, Input } from '@angular/core';
import { hshrink } from 'shared';

import { DomComponent, DomService } from '../../../services/dom.service';
import { SocketService } from '../../../services/socket.service';
import { AdvancedEntityActionsOverflowPopupComponent } from './action-row-overflow-popup/advanced-entity-actions-overflow-popup.component';

export interface Action {
    label: string;
    onClick: ($event: MouseEvent) => void;
    disabled?: boolean;
}

@Component({
    selector: 'app-advanced-entity-actions',
    templateUrl: './advanced-entity-actions.component.html',
    styleUrls: ['./advanced-entity-actions.component.scss'],
    animations: [hshrink('action')],
})
export class AdvancedEntityActionsComponent {
    _actions: Array<Action> = [];

    @Input() set actions(actions: Array<Action>) {
        this._actions = actions;
        if (this.collapseAfter > 0) {
            this.directActions = actions.slice(
                0,
                actions.length > this.collapseAfter ? this.collapseAfter - (this.showOverflow ? 1 : 0) : actions.length
            );
            this.overflowActions = actions.slice(
                actions.length > this.collapseAfter ? this.collapseAfter - (this.showOverflow ? 1 : 0) : actions.length,
                actions.length
            );
        } else {
            this.directActions = actions;
            this.overflowActions = [];
        }
    }

    _collapseAfter = 0;
    @Input() set collapseAfter(value: number) {
        this._collapseAfter = value;
        this.actions = this._actions;
    }

    get collapseAfter() {
        return this._collapseAfter;
    }

    _showOverflow = true;
    @Input() set showOverflow(value: boolean) {
        this._showOverflow = value;
        this.actions = this._actions;
    }

    get showOverflow() {
        return this._showOverflow;
    }

    @Input() disableOnSocketDisconnect: boolean;
    directActions: Array<Action> = [];
    overflowActions: Array<Action> = [];
    overflowPopup: DomComponent<AdvancedEntityActionsOverflowPopupComponent>;

    public constructor(public socketService: SocketService, private domService: DomService) {}

    trackActionBy(index: number, item: Action) {
        return item.label;
    }

    overflowClicked($event: MouseEvent) {
        $event.stopImmediatePropagation();
        if (this.overflowPopup) {
            this.overflowPopup.remove();
        } else {
            this.overflowPopup = this.domService.appendComponentTo<AdvancedEntityActionsOverflowPopupComponent>(
                'overlay-container',
                AdvancedEntityActionsOverflowPopupComponent,
                {
                    padding: 20,
                    origin: [$event.clientX, $event.clientY],
                    actions: this.overflowActions,
                }
            );
            this.overflowPopup.on('remove', () => (this.overflowPopup = null));
        }
    }
}
