import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GeoJSON } from 'leaflet';
import { uniq } from 'lodash';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import {
    AssetApiService,
    IAsset,
    IAssetMapGroup,
    IAssetMapResult,
    IAssetScore,
    IAssetType,
    ILocalizedStringAssetScoreType,
    IMapViewPort,
    INamedAssetType,
    LocaleStringMap,
} from 'shared';
import { v4 as uuid } from 'uuid';

interface BaseAssetQuery {
    typeId?: string;
}

interface AssetMapQuery extends BaseAssetQuery {
    viewPort: IMapViewPort;
    floor: number;
    gridSize: [number, number];
}

@Injectable({ providedIn: 'root' })
export class AssetService {
    private typeCache: { [id: string]: INamedAssetType } = {};
    private readonly _assetMapGroups: BehaviorSubject<Array<IAssetMapGroup>> = new BehaviorSubject<
        Array<IAssetMapGroup>
    >([]);

    private readonly _mapAssets: BehaviorSubject<Array<IAsset>> = new BehaviorSubject<Array<IAsset>>([]);
    public readonly assetMapGroups: Observable<Array<IAssetMapGroup>> = this._assetMapGroups.asObservable();
    public readonly mapAssets: Observable<Array<IAsset>> = this._mapAssets.asObservable();

    public constructor(private assetApiService: AssetApiService, private translate: TranslateService) {}

    //
    // Data fetching
    //

    public getAsset(id: string): Observable<IAsset> {
        return this.assetApiService.getAsset(id);
    }

    public async executeMapQuery(query: AssetMapQuery): Promise<IAssetMapResult> {
        // Run query
        const result = await firstValueFrom(
            this.assetApiService.getAssetGroups(query.floor, query.viewPort, query.gridSize)
        );
        // Cache relevant asset types
        await this.cacheAssetTypes((result.assets || []).map((a) => a.typeId));
        // Inject random uuids to groups for easier handling
        if (result.groups) result.groups.forEach((g) => (g.id = uuid()));
        // Update map resources
        this._assetMapGroups.next(result.groups || []);
        this._mapAssets.next(result.assets || []);
        // Return query result
        return result;
    }

    public async cacheAssetTypes(typeIds: Array<string>): Promise<void> {
        await Promise.all(uniq(typeIds).map((typeId: string) => this.getAssetType(typeId)));
    }

    public async getAssetType(typeId: string): Promise<INamedAssetType | null> {
        // Return from cache if available
        if (this.typeCache[typeId]) return this.typeCache[typeId];
        // Fetch from API
        const type = await firstValueFrom(this.assetApiService.getAssetType(typeId));
        if (!type) {
            console.warn('Could not cache asset type for typeid', typeId);
            return null;
        }
        // Add language map to cache
        if (typeof type.name === 'object') {
            Object.entries(type.name).forEach(([lang, name]) => {
                this.translate.setTranslation(lang, { assetType: { [type.id]: name } }, true);
            });
        }
        // Map to named type, cache it, and return it
        return (this.typeCache[typeId] = {
            ...type,
            name: typeof type.name === 'object' ? `assetType.${type.id}` : type.name,
        });
    }

    // Gets (a very inaccurate) approximation of the viewport from a GeoJson polygon.
    // This is a temporary solution until the asset service is updated to take GeoJson polygons as input for the viewport.
    public getViewPortFromPolygon(polygon: GeoJSON.Polygon): IMapViewPort {
        const outer = polygon.coordinates[0];
        const latitudes = outer.map((p) => p[1]);
        const longitudes = outer.map((p) => p[0]);
        const minLat = Math.min(...latitudes);
        const maxLat = Math.max(...latitudes);
        const minLong = Math.min(...longitudes);
        const maxLong = Math.max(...longitudes);
        return {
            a: [minLat, minLong],
            b: [maxLat, maxLong],
        };
    }

    public async getScoreForAssetScore(score?: IAssetScore): Promise<string | null> {
        if (!score) return null;
        switch (score.scoreType) {
            case 'Number': {
                return score.numberScore.toString(10);
            }
            case 'LocalizedString': {
                if (!score.assetTypeId) return null;
                const assetType = await this.getAssetType(score.assetTypeId);
                if (!assetType) return null;
                const stringScore = score.uuidScore;
                if (!stringScore) return null;
                const optionNames = (assetType.scoreType as ILocalizedStringAssetScoreType).allowedValues.find(
                    (value) => value.id === stringScore
                )?.name as LocaleStringMap;
                if (!optionNames)
                    console.warn(`Could not find score option '${stringScore}' on assetType '${score.assetTypeId}'`);
                return optionNames?.[this.translate.currentLang] ?? optionNames?.en ?? '-';
            }
            default:
                return null;
        }
    }
}
