import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StorageMap } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { orderBy } from 'lodash';
import { combineLatest } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { EntityFilters, ISortingModel, Pagination, invert } from 'shared';

import { isString } from '../../../../utils/type-utils';
import { DomService } from '../../../services/dom.service';
import { ITableComponentColumn } from './interfaces/table-component-column.interface';
import { ITableData } from './interfaces/table-data.interface';
import { ITableRow } from './interfaces/table-row.interface';
import { DEFAULT_STEP, TableBaseComponent } from './table-base.component';

type TableDataMutation<T> = [Array<ITableRow<T>>, Pagination, EntityFilters, Array<ISortingModel<T>>];

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [invert()],
    // encapsulation: ViewEncapsulation.None,
})
export class TableComponent<T extends ITableData> extends TableBaseComponent<T> implements OnInit, OnChanges {
    public constructor(
        cdr: ChangeDetectorRef,
        domService: DomService,
        storage: StorageMap,
        private readonly translate: TranslateService
    ) {
        super(cdr, domService, storage);
    }

    public ngOnInit(): void {
        this.initBase();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.onHeadingChanges(changes);

        if (changes.data?.currentValue) {
            const data = changes.data.currentValue;
            this.generateFilterOptions(data);
            this.data$.next(data);
        }
    }

    protected subscribeData(): void {
        combineLatest([this.data$, this.pagination$, this.getFilters(), this.sorting$])
            .pipe(
                debounceTime(0),
                filter(([data]) => data !== null),
                map(this.filterData),
                map(([data, pagination, filters, sorting]) => this.sortData([data, pagination, filters, sorting])),
                map(([data, pagination, filters, sorting]) => this.paginateData([data, pagination, filters, sorting])),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(([data, pagination]) => {
                this.cdr.markForCheck();
                this.pagination = pagination;
                this.tableData = data;
                this.selectedIds.forEach((id) => {
                    if (!this.data.find((tr) => tr.value.id === id)) {
                        this.onRowCheckChange(id, false);
                    }
                });
            });
    }

    protected translateValue<T>(row: ITableRow<T>, header: ITableComponentColumn<T>): string | T[keyof T] {
        const value = row.value[header.name];
        return value && typeof value === 'string' ? this.translate.instant(value) : value;
    }

    private generateFilterOptions(data: Array<ITableRow<T>>): void {
        const generateSelectFilterOptions = (headerName: keyof T) => {
            return data
                .map((row) => row.value[headerName])
                .filter((value) => value != null)
                .filter((value, index, self) => self.indexOf(value) === index)
                .map((label) => ({ label, value: label }));
        };
        const filterOptionGenerators = {
            SELECT: generateSelectFilterOptions,
            DATE_RANGE: (): void => null,
        };
        this.filterOptions = this.heading
            .map((header) => {
                return [header.name, filterOptionGenerators[header.filterable]?.(header.name)];
            })
            .reduce((prev: { [key: string]: any }, curr) => {
                prev[curr[0] as string] = curr[1];
                return prev;
            }, {});
    }

    private filterData([data, pagination, filters, sorting]: TableDataMutation<T>): TableDataMutation<T> {
        data = Object.entries(filters).reduce((previousValue, [key, value]) => {
            switch (value.type) {
                case 'SELECT':
                    if (value.values.length > 0) {
                        previousValue = previousValue.filter((e) => value.values.includes((e as any).value[key]));
                    }
                    break;
                case 'DATE_RANGE':
                    if (value.range.start && value.range.end) {
                        previousValue = previousValue.filter(
                            (e) =>
                                (e as any).value[key] >= value.range.start.getTime() / 1000 &&
                                (e as any).value[key] <= value.range.end.getTime() / 1000
                        );
                    }
                    break;
                default:
                    throw new Error('Unsupported filter value type');
            }
            return previousValue;
        }, data);
        return [data, pagination, filters, sorting];
    }

    private paginateData([data, pagination, filters, sorting]: TableDataMutation<T>): TableDataMutation<T> {
        pagination.step = this.pagination.step ?? DEFAULT_STEP;
        pagination.total = data.length;
        data = data.slice(pagination.start, pagination.start + pagination.step);
        return [data, pagination, filters, sorting];
    }

    private sortData([data, pagination, filters, sorting]: TableDataMutation<T>): TableDataMutation<T> {
        if (!sorting.length) return [data, pagination, filters, sorting];

        const iteratees = sorting.map((sort) => (tableRow: ITableRow<T>) => {
            let value = tableRow.value[sort.property];
            value =
                value && this.shouldBeTranslated(sort.property)
                    ? this.translate.instant(value as any as string)
                    : value;
            return isString(value) ? value.toLowerCase() : value;
        });

        const sortOrders = sorting.map((sort) => (sort.direction === 'ascending' ? 'asc' : 'desc'));

        data = orderBy(data, iteratees, sortOrders);

        return [data, pagination, filters, sorting];
    }

    private shouldBeTranslated(property: keyof T): boolean {
        return this.heading.some((heading) => heading.sortable === 'translate' && heading.name === property);
    }
}
