<div class="entity-filter" [ngClass]="{ light: isLight }" (clickOutside)="showDropDown = false">
    <button class="entity-filter-toggle" (click)="showDropDown = !showDropDown">
        <div class="toggle-label title" [class.selected]="dateRange.start && dateRange.end">
            <span>{{ placeholder }}</span>
        </div>
    </button>
    <div class="entity-filter-dropdown" *ngIf="showDropDown" @fadeUp>
        <div class="calendar-wrapper" (click)="onCalendarClick()" [class.disabled]="disabled">
            <mat-calendar [selected]="dateRange" (selectedChange)="onSelectedChange($event)"></mat-calendar>
        </div>
        <button
            class="btn btn-flat btn-small btn-clear-filter"
            [disabled]="!(dateRange.start || dateRange.end)"
            (click)="resetInput()"
        >
            {{ 'comp.date-range-filter.clearFilter' | translate }}
        </button>
    </div>
</div>
