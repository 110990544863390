import { HttpClient } from '@angular/common/http';
import { InjectionToken, inject } from '@angular/core';
import { ApiVersion } from 'baseflow-auth';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { map, take } from 'rxjs/operators';

export abstract class BaseRepositoryService {
    protected http: HttpClient = inject(HttpClient);
    protected baseUrls: BehaviorSubject<RepositoryBaseUrlConfig> = inject(REPOSITORY_BASE_URL_TOKEN);

    constructor(private baseUrlKey: keyof RepositoryBaseUrlConfig) {}

    protected getApiUrl(resource: string, apiVersion: ApiVersion = 'v1'): Observable<string> {
        return this.baseUrls.pipe(
            filter(Boolean),
            map((baseUrls) => baseUrls[this.baseUrlKey]),
            filter(Boolean),
            take(1),
            map((baseUrl) => `${baseUrl}/${apiVersion}${resource.startsWith('/') ? resource : '/' + resource}`)
        );
    }
}

export interface RepositoryBaseUrlConfig {
    userApi: string;
    tenantApi: string;
    taskApi: string;
    reportingApi: string;
    assetLinkApi: string;
    assetApi: string;
}

export const REPOSITORY_BASE_URL_TOKEN = new InjectionToken<BehaviorSubject<RepositoryBaseUrlConfig | null>>(
    'REPOSITORY_BASE_URL_TOKEN'
);

export const RepositoryBaseUrlConfigFactory = () => new BehaviorSubject<RepositoryBaseUrlConfig | null>(null);
