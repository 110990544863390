import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, firstValueFrom, timer } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DEFAULT_ORDINAL, IMapIMDFData, IMapIMDFStyles, IOrdinal, ITenantConfig, TenantApiService } from 'shared';

import { DerivedStateValue } from '../../utils/state-value';
import { AppStateService } from './state/app-state.service';

@Injectable({ providedIn: 'root' })
export class MapService {
    // Map data
    private readonly _mapConfig: BehaviorSubject<ITenantConfig> = new BehaviorSubject<ITenantConfig>(null);
    private readonly _imdfData: BehaviorSubject<IMapIMDFData> = new BehaviorSubject<IMapIMDFData>(null);
    private readonly _imdfStyles: BehaviorSubject<IMapIMDFStyles> = new BehaviorSubject<IMapIMDFStyles>(null);

    public readonly mapConfig: Observable<ITenantConfig> = this._mapConfig.asObservable();
    public readonly imdfData: Observable<IMapIMDFData> = this._imdfData.asObservable();
    public readonly imdfStyles: Observable<IMapIMDFStyles> = this._imdfStyles.asObservable();

    public readonly ordinals: DerivedStateValue<Array<IOrdinal>> = new DerivedStateValue<Array<IOrdinal>>(
        this._mapConfig.pipe(map((c) => c?.ordinals ?? [DEFAULT_ORDINAL]))
    );

    private constructor(
        private readonly tenantApiService: TenantApiService,
        private readonly appState: AppStateService
    ) {
        // Fetch everything
        this.appState.loggedInWithTenant.stream
            .pipe(
                switchMap((loggedInWithTenant) => {
                    if (!loggedInWithTenant) {
                        this._mapConfig.next(null);
                        return EMPTY;
                    }
                    return timer(0, 10000).pipe(filter(() => !this._mapConfig.value));
                })
            )
            .subscribe(() => this.fetchMapConfig());
    }

    //
    // PUBLIC UTILITIES
    //

    public async fetchMapConfig(tenantId?: string): Promise<void> {
        tenantId ??= this.appState.activeTenantId.value;
        const config = await firstValueFrom(this.tenantApiService.getConfig(tenantId));
        if (!config) return;

        if (config.imdf?.mapId) {
            const imdfData = await firstValueFrom(this.tenantApiService.getIMDFData(config.imdf.mapId, tenantId));
            const imdfStyles = await firstValueFrom(this.tenantApiService.getIMDFStyles(config.imdf.mapId, tenantId));

            if (imdfData && imdfStyles) {
                this._imdfData.next(imdfData);
                this._imdfStyles.next(imdfStyles);
            }
        }

        this._mapConfig.next(config);
    }
}
