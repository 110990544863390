import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Output,
    ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, Subject, combineLatest, fromEvent } from 'rxjs';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import { fadeUp, vshrink } from 'shared';

import { FuzzySearchPipe } from '../../../pipes/fuzzy-search.pipe';
import { SortPipe } from '../../../pipes/sort.pipe';
import { BasePopupComponent } from './base-popup.component';

@Component({ template: '', animations: [fadeUp('popup'), vshrink('listItem'), vshrink('listHeader')] })
export abstract class BaseSearchablePopupComponent<TModel>
    extends BasePopupComponent<BaseSearchablePopupComponent<TModel>>
    implements AfterViewInit
{
    @Output()
    public popupResult: EventEmitter<TModel> = new EventEmitter<TModel>();

    // Internal
    @ViewChild('searchInputEl')
    protected $searchInputEl: ElementRef<HTMLInputElement>;

    protected allEntities$: Observable<Array<TModel>>;
    protected searchResultEntities: Array<TModel>;

    protected query$: Subject<string> = new Subject<string>();

    protected constructor(
        elementRef: ElementRef,
        private readonly sortPipe: SortPipe,
        private readonly fuzzySearchPipe: FuzzySearchPipe
    ) {
        super(elementRef);
        this.popupOptions.hasArrow = true;
    }

    public ngAfterViewInit(): void {
        fromEvent(this.$searchInputEl.nativeElement, 'input')
            .pipe(
                map((e) => (e.target as HTMLInputElement).value),
                debounceTime(300),
                takeUntilDestroyed(this.destroyRef),
                tap((query) => {
                    this.query$.next(query);
                    this.cdr.detectChanges();
                })
            )
            .subscribe();
    }

    protected initialize(
        key: keyof TModel,
        entities: Observable<Array<TModel>>,
        maxResults: number = Number.MAX_SAFE_INTEGER
    ): void {
        if (!entities) {
            console.error('No entities were passed to the BaseSearchablePopupComponent');
            return;
        }

        combineLatest([this.query$.pipe(startWith('')), entities])
            .pipe(
                map(([query, entities]) =>
                    query
                        ? this.fuzzySearchPipe.transform(entities, [key as string], query, { translate: true })
                        : this.sortPipe.transform(entities, key, { sortOnTranslation: true })
                ),
                takeUntilDestroyed(this.destroyRef),

                tap((entities) => (this.searchResultEntities = entities.slice(0, maxResults)))
            )
            .subscribe();
    }

    protected onSearchEnterDown(): void {
        // Focus first result when pressing enter on search
        let sibling = this.$searchInputEl.nativeElement.nextElementSibling as HTMLElement;
        while (sibling != null && ((sibling.tabIndex !== 0 && !sibling.tabIndex) || sibling.tabIndex < 0))
            sibling = sibling.nextElementSibling as HTMLElement;
        if (sibling && sibling.tabIndex >= 0) sibling.focus();
    }

    protected trackById(_index: number, item: { id: string }): string {
        return item.id;
    }
}
