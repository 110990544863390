import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ITask, vshrink } from 'shared';

@Component({
    selector: 'app-linked-task',
    templateUrl: './linked-task.component.html',
    styleUrls: ['./linked-task.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [vshrink()],
})
export class LinkedTaskComponent {
    @Input()
    task: ITask | undefined;
}
