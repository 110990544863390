import { Directive, DoCheck, ElementRef, HostBinding, HostListener } from '@angular/core';

@Directive({
    selector: '[appFocusable]',
})
export class FocusableDirective implements DoCheck {
    @HostBinding('attr.tabindex') tabindex = 1;

    public constructor(private elementRef: ElementRef<HTMLElement>) {}

    get disabled(): boolean {
        return (
            (this.elementRef.nativeElement as any).disabled ||
            this.elementRef.nativeElement.classList.contains('disabled')
        );
    }

    ngDoCheck(): void {
        this.tabindex = this.disabled ? -1 : 1;
    }

    @HostListener('mouseenter') onHover() {
        if (!this.disabled) this.elementRef.nativeElement.focus();
    }

    @HostListener('focus') onFocus() {
        if (this.disabled) this.elementRef.nativeElement.blur();
    }

    @HostListener('keydown.arrowdown', ['$event'])
    onArrowDown(event: KeyboardEvent) {
        let sibling: HTMLElement = this.elementRef.nativeElement.nextElementSibling as HTMLElement;
        while (sibling != null && ((sibling.tabIndex !== 0 && !sibling.tabIndex) || sibling.tabIndex < 0))
            sibling = sibling.nextElementSibling as HTMLElement;
        if (sibling != null && sibling.tabIndex >= 0) sibling.focus();
    }

    @HostListener('keydown.arrowup', ['$event'])
    onArrowUp(event: KeyboardEvent) {
        let sibling: HTMLElement = this.elementRef.nativeElement.previousElementSibling as HTMLElement;
        while (sibling != null && ((sibling.tabIndex !== 0 && !sibling.tabIndex) || sibling.tabIndex < 0))
            sibling = sibling.previousElementSibling as HTMLElement;
        if (sibling != null && sibling.tabIndex >= 0) sibling.focus();
    }
}
