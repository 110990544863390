import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IIssue } from 'shared';

import { IssueService } from '../issue.service';
import { SharingService } from '../sharing.service';
import { SocketService } from '../socket.service';
import { BaseSocketEventHandler } from './base-socket-event-handler';

interface IssueStatusUpdatedSocketEvent {
    eventDtoType: 'IssueStatusUpdated';
    issue: IIssue;
}

@Injectable({ providedIn: 'root' })
export class IssueStatusUpdatedSocketEventHandler extends BaseSocketEventHandler<IssueStatusUpdatedSocketEvent> {
    protected constructor(
        private issueService: IssueService,
        socketService: SocketService,
        private sharingService: SharingService
    ) {
        super(socketService);
    }

    protected eventDtoType(): string {
        return 'IssueStatusUpdated';
    }

    protected handleEvent = async (event: IssueStatusUpdatedSocketEvent): Promise<void> => {
        // Get issue
        const issue = await firstValueFrom(
            this.issueService.issues.pipe(map((issues) => issues.find((t) => t.id === event.issue.id)))
        );
        // Update issue
        if (issue) {
            issue.status = event.issue.status;
            issue.taskStatuses = event.issue.taskStatuses;
            await this.issueService.localUpdate(issue);
        }
        // Get shared issue
        const sharedEntity = await firstValueFrom(this.sharingService.sharedEntity.pipe(take(1)));
        // Update shared issue
        if (sharedEntity && sharedEntity.type === 'SHARED_ISSUE' && sharedEntity.issue.id === event.issue.id) {
            sharedEntity.issue.status = event.issue.status;
            sharedEntity.issue.taskStatuses = event.issue.taskStatuses;
            this.sharingService.updateSharedEntity(sharedEntity);
        }
    };
}
