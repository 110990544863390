import { IIssue } from 'shared';

import { SVG_MARKER_PIN } from '../../../../../../resources';
import {
    CachedMarker,
    GenericMapMarkerClickEvent,
    GenericMarkerMapLayerController,
    MarkerFocus,
    QueryHandler,
} from './base-controllers/generic-marker.map-layer-controller';

// Note: These values match the marker styling. Changing them here will require changes in the marker styling as well and vice-versa.
export const ISSUE_MARKER_SIZE = {
    width: 27,
    height: 37,
    x: -13,
    y: -37,
};

export class IssueMapLayerController extends GenericMarkerMapLayerController<IIssue> {
    public constructor(options?: { queryHandler: QueryHandler<IIssue> }) {
        super({ queryHandler: options?.queryHandler, markerAnchor: 'bottom' });
    }

    public static buildElementForOptions(options: { focus?: MarkerFocus } = {}): HTMLDivElement {
        const el = document.createElement('div');

        const markerEl = document.createElement('div');
        markerEl.classList.add('mtp-map-marker', 'mtp-map-issue-marker');

        const shape = document.createElement('div');
        shape.classList.add('mtp-map-issue-marker-shape');
        shape.innerHTML = SVG_MARKER_PIN;
        markerEl.appendChild(shape);

        const icon = document.createElement('i');
        icon.classList.add('icon-flag');
        markerEl.appendChild(icon);

        markerEl.classList.toggle('focused', options.focus === 'focused');
        markerEl.classList.toggle('blurred', options.focus === 'blurred');

        el.appendChild(markerEl);
        return el;
    }

    public override buildElement(marker: CachedMarker<IIssue>): Element {
        return IssueMapLayerController.buildElementForOptions({
            focus: marker.focus,
        });
    }

    protected createCachedMarker(entity: IIssue, focus: MarkerFocus): CachedMarker<IIssue> {
        return {
            entity,
            focus,
        };
    }

    protected override buildClickEvent(cachedMarker: CachedMarker<IIssue>): GenericMapMarkerClickEvent<IIssue> {
        const event = super.buildClickEvent(cachedMarker);
        event.type = 'ISSUE';
        return event;
    }
}
