import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'baseflow-auth';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { INameable, fadeLeft, vshrink } from 'shared';

import buildVersion from '../../../../../../../assets/build-version.json';
import { Feature } from '../../../../../models/feature.model';
import { ControlBarService } from '../../../../../services/control-bar.service';
import { AppStateService } from '../../../../../services/state/app-state.service';
import { TenantService } from '../../../../../services/tenant.service';
import { UserService } from '../../../../../services/user.service';

@Component({
    selector: 'app-control-menu',
    templateUrl: './control-menu.component.html',
    styleUrls: ['./control-menu.component.scss'],
    animations: [fadeLeft('menu'), vshrink()],
})
export class ControlMenuComponent implements OnInit {
    @HostBinding('@menu') menuAnimation = true;
    languages: Array<INameable> = [
        { id: 'en', name: 'English' },
        { id: 'nl', name: 'Nederlands' },
    ];

    buildVersion = buildVersion.buildVersion;

    protected tenantName: Observable<string>;

    protected readonly Feature = Feature;

    public constructor(
        protected readonly controlBarService: ControlBarService,
        protected readonly appState: AppStateService,
        private readonly authService: AuthService,
        private readonly tenantService: TenantService,
        private readonly router: Router,
        private readonly translate: TranslateService,
        private readonly userService: UserService
    ) {}

    public ngOnInit(): void {
        this.tenantName = this.appState.activeTenant.stream.pipe(map((tenant) => tenant?.name));
    }

    get currentLang(): string {
        return this.translate.currentLang;
    }

    setLang(languageKey: string): void {
        this.userService.saveLanguage(languageKey);
    }

    onLogOut = (): void => {
        this.authService.logout().then(() => {
            this.tenantService.selectTenant(null, null);
            return this.router.navigate(['/']);
        });
    };

    onVersionClick(): void {
        window.location.reload();
    }
}
