import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { IPOICategory } from 'shared';

import { POIService } from '../services/poi.service';

@Pipe({
    name: 'poiCategory',
    pure: true,
})
export class PoiCategoryPipe implements PipeTransform {
    public constructor(private poiService: POIService) {}

    transform(poiCategoryId: string): Observable<IPOICategory> {
        return this.poiService.getCategoryForId(poiCategoryId);
    }
}
