import { Injectable } from '@angular/core';

import { POIService } from '../poi.service';
import { SocketService } from '../socket.service';
import { TenantService } from '../tenant.service';
import { BaseSocketEventHandler } from './base-socket-event-handler';

interface POICategoriesUpdatedSocketEvent {
    eventDtoType: 'PoiCategoriesUpdated';
}

@Injectable({ providedIn: 'root' })
export class POICategoriesUpdatedSocketEventHandler extends BaseSocketEventHandler<POICategoriesUpdatedSocketEvent> {
    protected constructor(
        socketService: SocketService,
        private tenantService: TenantService,
        private poiService: POIService
    ) {
        super(socketService);
    }

    protected eventDtoType(): string {
        return 'PoiCategoriesUpdated';
    }

    protected handleEvent = async (_: POICategoriesUpdatedSocketEvent): Promise<void> => {
        await this.tenantService.refreshConfig();
        await this.poiService.refreshPOIs();
    };
}
