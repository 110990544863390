<div class="image-reel" #imageReel (scroll)="onScroll()" [style.scroll-snap-type]="scrollSnapType">
    <div class="image-reel-item" *ngFor="let image of images" #reelItem>
        <ng-container *ngVar="image.url | authImage | async as imageLoaded">
            <div
                class="image"
                *ngIf="imageLoaded"
                [style.background-image]="'url(' + image.url + ')' | sanitizeStyle"
                [style.width]="image.dimensions[0] + 'px'"
                [style.height]="image.dimensions[1] + 'px'"
                [style.transform]="'scale(' + (transforms[image.id] || 0) + ')'"
            ></div>
            <ogs-spinner *ngIf="!imageLoaded" @fade></ogs-spinner>
        </ng-container>
    </div>
</div>
<div class="header-bar">
    <div class="title">
        <span>{{ (currentImageIndex$ | async) + 1 }}/{{ images.length }}</span>
    </div>
    <div class="actions">
        <button class="btn btn-round" (click)="downloadImage()"><i class="material-icons">cloud_download</i></button>
        <button class="btn btn-round" (click)="__DOM_COMPONENT.remove()"><i class="material-icons">clear</i></button>
    </div>
</div>
<div class="action-overlay">
    <button
        class="btn btn-round btn-prev-image"
        (click)="previousPhoto()"
        [disabled]="(currentImageIndex$ | async) === 0"
    >
        <i class="material-icons">navigate_before</i>
    </button>
    <button
        class="btn btn-round btn-next-image"
        (click)="nextPhoto()"
        [disabled]="(currentImageIndex$ | async) >= images.length - 1"
    >
        <i class="material-icons">navigate_next</i>
    </button>
</div>
