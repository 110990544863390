import { Observable, ReplaySubject, Subscription, firstValueFrom } from 'rxjs';
import { filter, timeout } from 'rxjs/operators';

export class StateValue<T> {
    public readonly stream: Observable<T>;

    private readonly _state: ReplaySubject<T>;
    private lastKnownValue: T | undefined;

    public constructor(initialValue?: T) {
        this._state = new ReplaySubject(1);
        if (initialValue || initialValue === null) this.value = initialValue;
        this.stream = this._state.asObservable();
    }

    public get value(): T {
        return this.lastKnownValue;
    }

    public set value(value: T) {
        this.lastKnownValue = value;
        this._state.next(value);
    }
}

export class DerivedStateValue<T> {
    public readonly stream: Observable<T>;

    private subscription: Subscription;
    private latestValue?: T;

    public constructor(stream: Observable<T>) {
        this.stream = stream;
        this.subscription = this.stream.subscribe((value) => (this.latestValue = value));
    }

    public get value(): T | undefined {
        return this.latestValue;
    }

    public nextValue(options?: { timeout?: number }): Promise<T> {
        let stream = this.stream;
        if (options?.timeout) stream = stream.pipe(timeout(options.timeout));
        return firstValueFrom(stream);
    }

    public nextNonNullValue(options?: { timeout?: number }): Promise<T> {
        let stream = this.stream.pipe(filter(Boolean));
        if (options?.timeout) stream = stream.pipe(timeout(options.timeout));
        return firstValueFrom(stream);
    }

    public dispose(): void {
        this.subscription.unsubscribe();
    }
}
