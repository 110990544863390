import { Pipe, PipeTransform } from '@angular/core';
import marked from 'marked';

@Pipe({
    name: 'marked',
})
export class MarkedPipe implements PipeTransform {
    transform(value: any): any {
        return value && value.length > 0 ? marked(value) : value;
    }
}
