import { inject } from '@angular/core';
import { isEqual } from 'lodash';
import mapboxgl from 'mapbox-gl';
import { IPOI, IPOIMapCategory } from 'shared';

import { POIService } from '../../../../services/poi.service';
import {
    CachedMarker,
    GenericMapMarkerClickEvent,
    GenericMarkerMapLayerController,
    MarkerFocus,
    QueryHandler,
} from './base-controllers/generic-marker.map-layer-controller';

// Note: These values match the marker styling. Changing them here will require changes in the marker styling as well and vice-versa.
export const POI_ICON_SIZE = {
    width: 32,
    height: 32,
    x: -16,
    y: -16,
};
export const POI_DOT_SIZE = {
    width: 8,
    height: 8,
};
export const POI_LABEL_SIZE = {
    width: 100,
    height: 40,
};

interface POICachedMarker extends CachedMarker<IPOI> {
    mode?: MarkerMode;
    rendered: boolean;
    poiCategory: IPOIMapCategory;
}

type MarkerMode = 'full' | 'icon_only' | 'dot_only' | 'none';

export class PoiMapLayerController extends GenericMarkerMapLayerController<IPOI, POICachedMarker> {
    private poiService = inject(POIService);

    public constructor(options?: { queryHandler?: QueryHandler<IPOI> }) {
        super({ queryHandler: options?.queryHandler, handleRendering: false });
    }

    public static buildElementForOptions(options: {
        mode: MarkerMode;
        poiCategory: IPOIMapCategory;
        label?: string;
        focus?: MarkerFocus;
    }): HTMLDivElement {
        const builder = new POIIconBuilder(options.mode).setColor(options.poiCategory.color);
        if (options.focus) builder.setFocus(options.focus);
        switch (options.mode) {
            case 'full':
                builder.setIcon(options.poiCategory.iconId);
                if (options.label) builder.setLabel(options.label);
                break;
            case 'icon_only':
                builder.setIcon(options.poiCategory.iconId);
                break;
            case 'dot_only':
                break;
        }
        return builder.build();
    }

    public override buildElement(marker: POICachedMarker): Element {
        return PoiMapLayerController.buildElementForOptions({
            mode: marker.mode,
            poiCategory: marker.poiCategory,
            label: marker.entity.description,
            focus: marker.focus,
        });
    }

    protected override updateMarkers(entities: Array<IPOI>): void {
        entities = entities.filter((poi) => this.poiService.getCategoryForPOISync(poi)?.appearOnMap);
        super.updateMarkers(entities);
        // Determine a new mode for each marker to be rendered, depending on their overlap
        this.fixOverlaps();
        // Render the new and updated markers
        this.markerCache.filter((m) => !m.rendered).forEach((m) => this.renderMarker(m));
    }

    protected override createCachedMarker(entity: IPOI, focus: MarkerFocus): POICachedMarker {
        return {
            entity,
            focus,
            mode: 'none',
            rendered: false,
            poiCategory: this.poiService.getCategoryForPOISync(entity) as IPOIMapCategory,
        };
    }

    protected override renderMarker(cachedMarker: POICachedMarker): void {
        const el = this.buildElement(cachedMarker);
        const marker = new mapboxgl.Marker(el as HTMLElement);
        marker.setLngLat([cachedMarker.entity.location.longitude, cachedMarker.entity.location.latitude]);
        marker.addTo(this.map);
        cachedMarker.marker = marker;
        cachedMarker.rendered = true;
        this.registerClickHandler(cachedMarker);
    }

    protected override addMarker(entity: IPOI): POICachedMarker {
        const cachedMarker = super.addMarker(entity);
        cachedMarker.mode = this.getMarkerModeForZoom(entity, cachedMarker.poiCategory, this.map.getZoom());
        return cachedMarker;
    }

    protected override updateMarker(cachedMarker: POICachedMarker, entity: IPOI): void {
        super.updateMarker(cachedMarker, entity);
        cachedMarker.mode = this.getMarkerModeForZoom(entity, cachedMarker.poiCategory, this.map.getZoom());
        cachedMarker.rendered = false;
    }

    protected override isMarkerUpToDate(args: {
        cachedMarker: POICachedMarker;
        oldEntity: IPOI;
        newEntity: IPOI;
        newZoom: number;
    }): boolean {
        // If the new marker mode would be different for the new zoom level, we need to update the marker
        const newMode = this.getMarkerModeForZoom(args.newEntity, args.cachedMarker.poiCategory, args.newZoom);
        if (newMode !== args.cachedMarker.mode) return false;
        // If the poi has changed in any way, we need to update the marker.
        if (!isEqual(args.oldEntity, args.newEntity)) return false;
        // If the poi overlaps with another marker, we need to update the marker.
        if (
            this.markerCache
                .filter((m) => m.entity.id !== args.newEntity.id)
                .find((m) => this.doMarkersOverlap(m, args.cachedMarker))
        )
            return false;
        // Marker is up-to-date
        return true;
    }

    protected override buildClickEvent(cachedMarker: POICachedMarker): GenericMapMarkerClickEvent<IPOI> {
        const event = super.buildClickEvent(cachedMarker);
        event.type = 'POI';
        return event;
    }

    private getMarkerModeForZoom(poi: IPOI, category: IPOIMapCategory, zoomLevel: number): MarkerMode {
        // Define zoom ranges
        const iconZoom: [number, number] = [category.iconMinZoom || 0, category.iconMaxZoom || 1000];
        const dotZoom: [number, number] = [category.dotMinZoom || 1, iconZoom[0]];
        const labelZoom: [number, number] = [
            category.titleMinZoom || iconZoom[0],
            category.titleMaxZoom || iconZoom[1],
        ];
        // Determine what zoom ranges we are in
        const inRange = (range: [number, number]): boolean => zoomLevel >= range[0] && zoomLevel < range[1];
        if (inRange(iconZoom) && inRange(labelZoom) && poi.description) return 'full';
        if (inRange(iconZoom) && (!inRange(labelZoom) || !poi.description)) return 'icon_only';
        if (inRange(dotZoom) || inRange(iconZoom)) return 'dot_only';
        return 'none';
    }

    private fixOverlaps(): void {
        let changeMade = false;
        do {
            for (const marker of this.markerCache) {
                // If the marker is already rendered, we don't need to do anything
                if (marker.rendered) continue;
                // If the marker is already a dot, we don't need to do anything
                if (marker.mode === 'dot_only') return;
                // Find all markers that would overlap with this one
                const overlappingMarkers = this.markerCache.filter((m) => this.doMarkersOverlap(marker, m));
                // If there are no overlapping markers, we don't need to do anything
                if (overlappingMarkers.length === 0) return;
                // If there are overlapping markers, we turn down the size of this marker
                changeMade = true;
                switch (marker.mode) {
                    case 'full':
                        marker.mode = 'icon_only';
                        break;
                    case 'icon_only':
                        marker.mode = 'dot_only';
                        break;
                    default:
                        changeMade = false;
                        break;
                }
            }
        } while (changeMade);
    }

    private doMarkersOverlap(a: POICachedMarker, b: POICachedMarker): boolean {
        // A marker cannot overlap itself
        if (a === b) return false;
        // Calculate their bounds
        const aBounds = this.getMarkerBounds(a);
        const bBounds = this.getMarkerBounds(b);
        // Check if they overlap
        return (
            aBounds.x + aBounds.width > bBounds.x &&
            bBounds.x + bBounds.width > aBounds.x &&
            aBounds.y + aBounds.height > bBounds.y &&
            bBounds.y + bBounds.height > aBounds.y
        );
    }

    private getMarkerBounds(marker: POICachedMarker): { x: number; y: number; width: number; height: number } {
        const point = this.map.project([marker.entity.location.longitude, marker.entity.location.latitude]);
        switch (marker.mode) {
            case 'icon_only':
                return {
                    x: point.x - POI_ICON_SIZE.width / 2,
                    y: point.y - POI_ICON_SIZE.height / 2,
                    width: POI_ICON_SIZE.width,
                    height: POI_ICON_SIZE.height,
                };
            case 'dot_only':
                return {
                    x: point.x - POI_DOT_SIZE.width / 2,
                    y: point.y - POI_DOT_SIZE.height / 2,
                    width: POI_DOT_SIZE.width,
                    height: POI_DOT_SIZE.height,
                };
            case 'none':
                return { x: point.x, y: point.y, width: 0, height: 0 };
            case 'full':
                return {
                    x: point.x - POI_LABEL_SIZE.width / 2,
                    y: point.y - POI_ICON_SIZE.height / 2,
                    width: POI_LABEL_SIZE.width,
                    height: POI_ICON_SIZE.height + POI_LABEL_SIZE.height,
                };
        }
    }
}

class POIIconBuilder {
    private showIcon = false;
    private iconId?: string;
    private color?: string;
    private label?: string;
    private focus: MarkerFocus;

    public constructor(private mode: MarkerMode) {}

    public setIcon(iconId?: string): POIIconBuilder {
        this.iconId = iconId;
        this.showIcon = true;
        return this;
    }

    public setLabel(label: string): POIIconBuilder {
        this.showIcon = true;
        this.label = label;
        return this;
    }

    public setColor(color: string): POIIconBuilder {
        this.color = color;
        return this;
    }

    public setFocus(focus: MarkerFocus): POIIconBuilder {
        this.focus = focus;
        return this;
    }

    public build(): HTMLDivElement {
        const el = document.createElement('div');
        const marker = document.createElement('div');
        marker.classList.add('mtp-map-marker', 'mtp-map-poi-marker', `mode-${this.mode}`);
        if (this.showIcon) {
            // Construct icon wrapper
            const iconWrapper = document.createElement('div');
            iconWrapper.classList.add('icon');
            if (this.color) iconWrapper.style.backgroundColor = this.color;
            // Construct icon
            const icon = document.createElement('i');
            if (this.iconId) icon.classList.add(`icon-${this.iconId}`);
            // Add icon to wrapper
            iconWrapper.appendChild(icon);
            // Add wrapper to marker
            marker.appendChild(iconWrapper);
            // Construct label
            if (this.label) {
                const label = document.createElement('div');
                label.classList.add('label');
                label.innerText = this.label;
                // Add label to marker
                marker.appendChild(label);
            }
        } else {
            const dot = document.createElement('div');
            dot.classList.add('dot');
            if (this.color) dot.style.backgroundColor = this.color;
            marker.appendChild(dot);
        }
        marker.classList.toggle('focused', this.focus === 'focused');
        marker.classList.toggle('blurred', this.focus === 'blurred');
        el.appendChild(marker);
        return el;
    }
}
