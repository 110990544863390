import { Component, Input } from '@angular/core';
import { hshrink, vshrink } from 'shared';

import { EntityAction } from '../../../../models/entity-action.model';
import { SocketService } from '../../../services/socket.service';

@Component({
    selector: 'app-simple-entity-actions',
    templateUrl: './simple-entity-actions.component.html',
    styleUrls: ['./simple-entity-actions.component.scss'],
    animations: [vshrink(), hshrink()],
})
export class SimpleEntityActionsComponent {
    @Input() largeStyle = false;
    @Input() showOverflow = false;
    @Input() disableOnSocketDisconnect = false;
    @Input() actions: Array<EntityAction> = [];
    expandedMoreActions = false;

    public constructor(public socketService: SocketService) {}

    get primaryAction(): EntityAction {
        return this.actions.length >= 1 ? this.actions[0] : null;
    }

    get secondaryAction(): EntityAction {
        return this.actions.length >= 2 ? this.actions[1] : null;
    }

    get overflowActions(): Array<EntityAction> {
        return this.actions.slice(2);
    }

    toggleOverflowExpand(event: MouseEvent) {
        event.stopImmediatePropagation();
        this.expandedMoreActions = !this.expandedMoreActions;
    }

    onClickAction(event: MouseEvent, secondaryAction: EntityAction) {
        event.stopImmediatePropagation();
        secondaryAction.onClick(event);
    }
}
