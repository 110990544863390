import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[clickOutside]',
})
export class ClickOutsideDirective {
    @Output()
    public clickOutside = new EventEmitter();

    public constructor(private _elementRef: ElementRef) {}

    @HostListener('document:click', ['$event'])
    public onClick(event: MouseEvent) {
        const clickedInside = event.composedPath().includes(this._elementRef.nativeElement);
        if (!clickedInside) this.clickOutside.emit(null);
    }
}
