import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';

import {
    IAsset,
    IAssetMapResult,
    IAssetScore,
    IAssetTemplate,
    IAssetTemplatePOST,
    IAssetTemplatePUT,
    IAssetType,
    IAssetTypePOST,
    IAssetTypePUT,
    IMapViewPort,
    IPaginatedTableResponse,
    ISortingModel,
    Pagination,
} from '../../models';
import { EntityFilters, PaginatedApiBaseService } from './paginated-api-base.service';

@Injectable({ providedIn: 'root' })
export class AssetApiService extends PaginatedApiBaseService {
    constructor() {
        super('assetApi');
    }

    public getAsset(assetId: string): Observable<IAsset> {
        return this.getApiUrl(`/assets/${assetId}`).pipe(switchMap((url) => this.http.get<IAsset>(url)));
    }

    public getAssets(): Observable<Array<IAsset>> {
        return this.getApiUrl('/assets', 'v2').pipe(mergeMap((url) => this.http.get<Array<IAsset>>(url)));
    }

    public getAssetFilters<TFilterModel>(): Observable<TFilterModel> {
        return this.getApiUrl('/assets/filters').pipe(mergeMap((url) => this.http.get<TFilterModel>(url)));
    }

    public getAssetsPaginated<TableModel>(
        pagination: Pagination,
        filters: EntityFilters,
        sorting: Array<ISortingModel<TableModel>>,
        filterPreset?: string
    ): Observable<IPaginatedTableResponse<IAsset>> {
        return this.getApiUrl('/assets', 'v2').pipe(
            this.paginatedHttpGet<IAsset, TableModel>(pagination, filters, sorting, filterPreset)
        );
    }

    public getAssetType(typeId: string): Observable<IAssetType> {
        return this.getApiUrl(`/assettypes/${typeId}`).pipe(switchMap((url) => this.http.get<IAssetType>(url)));
    }

    public getAssetTypes(): Observable<Array<IAssetType>> {
        return this.getApiUrl('/assettypes').pipe(mergeMap((url) => this.http.get<Array<IAssetType>>(url)));
    }

    public postAssetType(type: IAssetTypePOST): Observable<IAssetType> {
        return this.getApiUrl('/assettypes').pipe(mergeMap((url) => this.http.post<IAssetType>(url, type)));
    }

    public deleteAssetType(assetTypeId: string): Observable<IAssetType> {
        return this.getApiUrl(`/assettypes/${assetTypeId}`).pipe(mergeMap((url) => this.http.delete<IAssetType>(url)));
    }

    public putAssetType(assetTypeId: string, assetType: IAssetTypePUT): Observable<IAssetType> {
        return this.getApiUrl(`/assettypes/${assetTypeId}`).pipe(
            mergeMap((url) => this.http.put<IAssetType>(url, assetType))
        );
    }

    public getAssetTemplates(): Observable<Array<any>> {
        return this.getApiUrl('/assettemplates').pipe(mergeMap((url) => this.http.get<Array<any>>(url)));
    }

    public getAssetTemplate(assetTemplateId: string): Observable<IAssetTemplate> {
        return this.getApiUrl(`/assettemplates/${assetTemplateId}`).pipe(
            mergeMap((url) => this.http.get<IAssetTemplate>(url))
        );
    }

    public postAssetTemplate(assetTemplate: IAssetTemplatePOST): Observable<IAssetTemplate> {
        return this.getApiUrl('/assettemplates').pipe(
            mergeMap((url) => this.http.post<IAssetTemplate>(url, assetTemplate))
        );
    }

    public putAssetTemplate(assetTemplateId: string, assetTemplate: IAssetTemplatePUT): Observable<IAssetTemplate> {
        return this.getApiUrl(`/assettemplates/${assetTemplateId}`).pipe(
            mergeMap((url) => this.http.put<IAssetTemplate>(url, assetTemplate))
        );
    }

    public deleteAssetTemplate(assetTemplateId: string): Observable<IAssetTemplate> {
        return this.getApiUrl(`/assettemplates/${assetTemplateId}`).pipe(
            mergeMap((url) => this.http.delete<IAssetTemplate>(url))
        );
    }

    public getAssetGroups(
        floor: number,
        viewport: IMapViewPort,
        gridSize: [number, number] = [8, 8]
    ): Observable<IAssetMapResult> {
        const params = new HttpParams()
            .append('floor', floor.toFixed(0))
            .append('viewportA', `${viewport.a[0]},${viewport.a[1]}`)
            .append('viewportB', `${viewport.b[0]},${viewport.b[1]}`)
            .append('gridSize', gridSize.join(','));
        return this.getApiUrl('/assets').pipe(mergeMap((url) => this.http.get<IAssetMapResult>(url, { params })));
    }

    public linkAssetToPOI(assetId: string, poiId: string | null): Observable<IAsset> {
        return this.getApiUrl(`/assets/${assetId}/linkPoi`, 'v2').pipe(
            mergeMap((url) => this.http.put<IAsset>(url, { poiId }))
        );
    }

    public scoreAsset(assetId: string, score: IAssetScore): Observable<IAsset> {
        return this.getApiUrl(`/assets/${assetId}/scores`).pipe(mergeMap((url) => this.http.post<IAsset>(url, score)));
    }
}
