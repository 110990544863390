import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { of, timer } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';

import cachedBuildVersion from '../../../assets/build-version.json';

interface IBuildVersion {
    buildVersion: string;
}

@Injectable({ providedIn: 'root' })
export class UpdaterService {
    private constructor(private swUpdate: SwUpdate, private http: HttpClient) {
        this.setupUpdateInterval();
        this.checkForServiceWorker();
        this.subscribeUnrecoverableState();
    }

    manualUpdateCheck() {
        this.http
            .get<IBuildVersion>('/assets/build-version.json?ngsw-bypass=1')
            .pipe(
                catchError((e) => {
                    console.warn('Could not check for new version', e);
                    return of(null);
                })
            )
            .subscribe((buildVersion) => {
                if (cachedBuildVersion.buildVersion !== buildVersion?.buildVersion) {
                    this.applyUpdate();
                }
            });
    }

    private applyUpdate(): void {
        console.log('New update found! Reloading...');
        this.swUpdate
            .activateUpdate()
            .then(() => window.location.reload())
            .catch(() => window.location.reload());
    }

    private checkForUpdate(): void {
        // Check for updates through the service worker or manually if service worker is not enabled
        if (this.swUpdate?.isEnabled) {
            this.swUpdate
                .checkForUpdate()
                .catch(() => console.warn('Failed to check for updates via the service worker!'));
        } else {
            this.manualUpdateCheck();
        }
    }

    private checkForServiceWorker(): void {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.versionUpdates
                .pipe(
                    filter((event) => event.type === 'VERSION_READY'),
                    tap(() => this.applyUpdate())
                )
                .subscribe();
        } else {
            console.warn('Service worker not enabled');
        }
    }

    private setupUpdateInterval(): void {
        timer(0, 60000)
            .pipe(tap(() => this.checkForUpdate()))
            .subscribe();
    }

    private subscribeUnrecoverableState() {
        this.swUpdate.unrecoverable.subscribe((event) => {
            console.error(`Encountered an unrecoverable state. Reloading webapp!`);
            window.location.reload();
        });
    }
}
