import { DestroyRef, Directive, HostBinding, OnInit, Optional } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterLinkWithHref } from '@angular/router';
import { filter, tap } from 'rxjs/operators';

const activeRouteClassName = 'active';

@Directive({ selector: '[routerLink]' })
export class DefaultRouterLinkActiveDirective implements OnInit {
    @HostBinding(`class.${activeRouteClassName}`)
    protected active = false;

    public constructor(
        private router: Router,
        @Optional() private routerLinkWithHref: RouterLinkWithHref,
        private readonly destroyRef: DestroyRef
    ) {}

    public ngOnInit(): void {
        this.updateActive();

        this.router.events
            .pipe(
                filter((e) => e instanceof NavigationEnd),
                takeUntilDestroyed(this.destroyRef),
                tap(() => this.updateActive())
            )
            .subscribe();
    }

    private updateActive(): void {
        if (this.routerLinkWithHref) {
            this.active = this.router.isActive(this.routerLinkWithHref.urlTree, {
                paths: 'subset',
                queryParams: 'subset',
                fragment: 'ignored',
                matrixParams: 'ignored',
            });
        }
    }
}
