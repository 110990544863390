import { Injectable } from '@angular/core';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { ITask } from 'shared';

import { SocketService } from '../socket.service';
import { TaskService } from '../task.service';
import { BaseSocketEventHandler } from './base-socket-event-handler';

interface TaskPatchedSocketEvent {
    eventDtoType: 'TaskPatched';
    assignedById: string;
    previousAssigneeId: string;
    task: ITask;
}

@Injectable({ providedIn: 'root' })
export class TaskPatchedSocketEventHandler extends BaseSocketEventHandler<TaskPatchedSocketEvent> {
    protected constructor(private taskService: TaskService, socketService: SocketService) {
        super(socketService);
    }

    protected eventDtoType(): string {
        return 'TaskPatched';
    }

    protected async handleEvent(event: TaskPatchedSocketEvent): Promise<void> {
        // Get task from local service
        this.taskService.tasks
            .pipe(
                take(1),
                map((tasks) => tasks.find((t) => t.id === event.task.id)),
                filter((t) => !!t),
                switchMap(() => {
                    return this.taskService.localUpdate({ ...event.task });
                })
            )
            .subscribe();
    }
}
