import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ITask } from 'shared';

import { SharingService } from '../sharing.service';
import { SocketService } from '../socket.service';
import { TaskService } from '../task.service';
import { BaseSocketEventHandler } from './base-socket-event-handler';

interface TaskArchivedSocketEvent {
    eventDtoType: 'TaskArchived';
    task: ITask;
}

@Injectable({ providedIn: 'root' })
export class TaskArchivedSocketEventHandler extends BaseSocketEventHandler<TaskArchivedSocketEvent> {
    protected constructor(
        private taskService: TaskService,
        socketService: SocketService,
        private sharingService: SharingService
    ) {
        super(socketService);
    }

    protected eventDtoType(): string {
        return 'TaskArchived';
    }

    protected async handleEvent(event: TaskArchivedSocketEvent): Promise<void> {
        // Remove task from local task service
        await this.taskService.localRemove(event.task.id);
        // Get shared task
        const sharedEntity = await firstValueFrom(this.sharingService.sharedEntity);
        // Update shared issue
        if (sharedEntity && sharedEntity.type === 'SHARED_TASK' && sharedEntity.task.id === event.task.id) {
            sharedEntity.task.archived = true;
            this.sharingService.updateSharedEntity(sharedEntity);
        }
    }
}
