import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { UserService } from '../services/user.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
    public constructor(private readonly userService: UserService, private readonly translate: TranslateService) {}

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const currentLanguage = this.translate?.currentLang;
        return of(currentLanguage).pipe(
            // Fetch profile language if language is not yet set
            switchMap((currentLanguage) => (currentLanguage ? of(currentLanguage) : this.userService.getLanguage())),
            switchMap((language) =>
                next.handle(
                    req.clone({
                        headers: req.headers.set('X-Profile-Language', language ?? 'en'),
                    })
                )
            )
        );
    }
}
