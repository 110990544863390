import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

export interface SegmentedButtonOption {
    id: string;
    label: string;
}

@Component({
    selector: 'app-segmented-button-selector',
    templateUrl: './segmented-button-selector.component.html',
    styleUrls: ['./segmented-button-selector.component.scss'],
})
export class SegmentedButtonSelectorComponent implements OnChanges {
    @Input() options: Array<SegmentedButtonOption> = [];
    @Input() selectedOption?: SegmentedButtonOption;
    @Output() selectedOptionChange: EventEmitter<SegmentedButtonOption> = new EventEmitter<SegmentedButtonOption>();

    ngOnChanges(changes: SimpleChanges): void {
        if (this.selectedOption && !this.options.map((o) => o.id).includes(this.selectedOption.id)) {
            this.selectedOption = this.options[0];
            this.selectedOptionChange.emit(this.selectedOption);
        }
    }
}
