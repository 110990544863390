<div class="btn-row">
    <button
        *ngFor="let action of directActions; trackBy: trackActionBy"
        class="btn btn-small"
        (click)="action.onClick ? action.onClick($event) : null"
        [disabled]="action.disabled || (disableOnSocketDisconnect ? (socketService.disableUI | async) : false)"
        @action
    >
        <span>{{ action.label | translate }}</span>
    </button>
    <button
        *ngIf="overflowActions?.length && showOverflow"
        class="btn btn-small"
        (click)="overflowClicked($event)"
        [disabled]="disableOnSocketDisconnect ? (socketService.disableUI | async) : false"
        @action
    >
        <i class="material-icons">more_vert</i>
    </button>
</div>
