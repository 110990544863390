<div class="comment">
    <div class="title">
        <span class="actor">{{ userFullName }}:</span>
        <span>{{ comment.timestamp | amFromUnix | amCalendar | ucfirst }}</span>
    </div>
    <p class="text-container" *ngIf="comment.comment">{{ comment.comment }}</p>
    <div class="image-container" *ngIf="comment.images">
        <app-image-thumb
            *ngFor="let image of comment.images; let index = index"
            [image]="image"
            (click)="openImages(index)"
        ></app-image-thumb>
    </div>
</div>
