import { Component, Input } from '@angular/core';
import { IIssueProgress } from 'shared';

@Component({
    selector: 'app-issue-progress',
    templateUrl: './issue-progress.component.html',
    styleUrls: ['./issue-progress.component.scss'],
})
export class IssueProgressComponent {
    @Input() taskStatuses: IIssueProgress = {} as IIssueProgress;

    get tasksTotal(): number {
        return Object.values(this.taskStatuses || {}).reduce((acc, e) => acc + e, 0);
    }

    get tasksComplete(): number {
        return (this.taskStatuses.DONE || 0) + (this.taskStatuses.CANCELLED || 0);
    }
}
