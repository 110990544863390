import { Component, Input } from '@angular/core';
import { IImage } from 'shared';

import { ImageViewerService } from '../../../services/image-viewer.service';

@Component({
    selector: 'app-photo-grid',
    templateUrl: './photo-grid.component.html',
    styleUrls: ['./photo-grid.component.scss'],
})
export class PhotoGridComponent {
    @Input() images: Array<IImage>;
    @Input() title: string;
    @Input() showAsGrid = false;
    @Input() largeFirstTile = false;

    public constructor(private readonly imageViewerService: ImageViewerService) {}

    openImages(index: number): void {
        this.imageViewerService.openImages(this.images, index);
    }
}
