import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { BehaviorSubject, Observable, Subject, firstValueFrom } from 'rxjs';
import { filter, map, switchMap, take, timeout } from 'rxjs/operators';
import {
    IImage,
    IIssue,
    IIssuePATCH,
    IIssuePOST,
    IIssueProgress,
    IIssueType,
    INamedIssueType,
    TaskApiService,
} from 'shared';

import { ControlRoute } from '../../models/control-route.model';
import { EntityAction, FilterableEntityAction } from '../../models/entity-action.model';
import { EntityEvent } from '../../models/entity-event.action';
import { EntitySharePopupComponent } from '../../mtp-main/components/modals/entity-share-popup/entity-share-popup.component';
import { LazyTranslateLoader } from '../../utils/lazy-translate-loader';
import { SnackbarComponent } from '../components/modals/snackbar/snackbar.component';
import { ControlBarService } from './control-bar.service';
import { DomService } from './dom.service';
import { POIService } from './poi.service';
import { AppStateService } from './state/app-state.service';
import { TaskService } from './task.service';
import { TenantService } from './tenant.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class IssueService {
    private readonly _issues: BehaviorSubject<Array<IIssue>> = new BehaviorSubject([]);
    public readonly issues: Observable<Array<IIssue>> = this._issues.asObservable();
    private readonly _issueTypes: BehaviorSubject<Array<INamedIssueType>> = new BehaviorSubject<Array<INamedIssueType>>(
        []
    );
    public readonly issueTypes: Observable<Array<INamedIssueType>> = this._issueTypes.asObservable();
    private readonly _events: Subject<EntityEvent<IIssue>> = new Subject<EntityEvent<IIssue>>();
    public readonly events: Observable<EntityEvent<IIssue>> = this._events.asObservable();

    public constructor(
        private readonly userService: UserService,
        private readonly tenantService: TenantService,
        private readonly poiService: POIService,
        private readonly tasksApi: TaskApiService,
        private readonly translate: TranslateService,
        private readonly taskService: TaskService,
        private readonly domService: DomService,
        private readonly appState: AppStateService,
        private readonly controlBarService: ControlBarService,
        private readonly router: Router
    ) {
        // Clear data on logout
        this.appState.user.stream.pipe(filter((user) => !user)).subscribe((_) => {
            this._issueTypes.next([]);
            this._issues.next([]);
        });
        // Refresh on socket connect
        this.appState.socketStatus.stream
            .pipe(
                filter((s) => s.status === 'CONNECTED'),
                // Wait for the user to be known
                switchMap((_) =>
                    this.appState.user.stream.pipe(
                        filter((user) => !!user),
                        timeout(3000),
                        take(1)
                    )
                )
            )
            .subscribe((_) => {
                this.refreshIssueTypes();
                this.refreshIssues();
            });
        // Update linked tasks when they are updated
        this.taskService.tasks.subscribe((tasks) => {
            let issuesUpdated = false;
            const issues = this._issues.value;
            issues.forEach((issue) => {
                tasks.forEach((task) => {
                    const linkedTask = (issue.tasks || []).find((t) => t.id === task.id);
                    // Check if a task was linked
                    if (!linkedTask && task.issueId === issue.id) {
                        issue.tasks = (issue.tasks || []).concat([task]);
                    }
                    // Check if a task was updated
                    else if (linkedTask && !isEqual(task, linkedTask)) {
                        issuesUpdated = true;
                        Object.assign(linkedTask, task);
                    }
                });
                // Check if a task was unlinked
                issue.tasks = (issue.tasks || []).filter((task) => tasks.find((t) => t.id === task.id));
            });
            if (issuesUpdated) this._issues.next(issues);
        });
    }

    //
    // Utilities
    //

    public updateIssueTypes(newTypes: Array<IIssueType>): void {
        const newNamedTypes = newTypes.map((type) => {
            this.applyTranslationsForIssueType(type);
            return IssueService.mapIssueTypeToNamedType(type);
        });
        const newIds = newTypes.map((namedType) => namedType.id);
        this._issueTypes.next([
            ...this._issueTypes.value.filter((type) => !newIds.includes(type.id)),
            ...newNamedTypes,
        ]);
    }

    public getIssue(id: string): Observable<IIssue> {
        return this.issues.pipe(map((issues) => issues.find((issue) => issue.id === id)));
    }

    public getIssuesForIdsSync(entityIds: Array<string>): Array<IIssue> {
        return this._issues.value.filter((task) => entityIds.includes(task.id));
    }

    public hasIssue(id: string): Observable<boolean> {
        return this.getIssue(id).pipe(map((issue) => !!issue));
    }

    public async localUpdate(issue: IIssue): Promise<IIssue> {
        const issues = await firstValueFrom(this._issues);
        const currentIssue = issues.find((t) => t.id === issue.id);
        if (!currentIssue) return null;
        Object.assign(currentIssue, issue);
        this._issues.next(issues);
        this._events.next({ type: 'updated', entity: currentIssue });
        return currentIssue;
    }

    public localAdd = async (issue: IIssue): Promise<IIssue> => {
        const issues = await firstValueFrom(this._issues);
        if (issues.find((t) => t.id === issue.id)) return null;
        issues.push(issue);
        this._issues.next(issues);
        this._events.next({ type: 'added', entity: issue });
        return issue;
    };

    public async localRemove(issueId: string): Promise<IIssue | null> {
        const issues = await firstValueFrom(this._issues);
        const index = issues.findIndex((i) => i.id === issueId);
        let issue = null;
        if (index >= 0) issue = issues.splice(index, 1)[0];
        this._issues.next(issues);
        this._events.next({ type: 'removed', entity: issue });
        return issue;
    }

    public async localClose(issueId: string): Promise<IIssue> {
        return firstValueFrom(
            this._issues.pipe(
                take(1),
                map((issues) => issues.find((issue) => issue.id === issueId)),
                filter((issue) => !!issue),
                map((issue) => {
                    issue.status = 'CLOSED';
                    return issue;
                }),
                switchMap((issue) => this.localUpdate(issue))
            )
        );
    }

    public createIssue(issue: IIssuePOST): Observable<IIssue> {
        return (
            this.tasksApi
                .postIssue(issue)
                // Add issue locally
                .pipe(switchMap((i) => this.localAdd(i)))
        );
    }

    public updateIssue(issue: IIssuePATCH): Observable<IIssue> {
        return (
            this.tasksApi
                .patchIssue(issue.id, issue)
                // Update issue locally
                .pipe(switchMap((i) => this.localUpdate(i)))
        );
    }

    public uploadPhoto(id: string, file: File): Observable<HttpEvent<IImage>> {
        return this.tasksApi.postIssueImage(id, file);
    }

    public getIssueTypeForId(id: string): Observable<INamedIssueType> {
        return this.issueTypes.pipe(map((its) => its.find((it) => it.id === id)));
    }

    public getIssueTypeForIdSync(id: string): INamedIssueType {
        return this._issueTypes.value.find((it) => it.id === id);
    }

    public buildActions = async (issue: IIssue): Promise<Array<EntityAction>> => {
        const actions: Array<EntityAction> = [];
        if (!issue) return actions;

        // Order actions by priority
        return [
            await this._buildCloseEntityAction(issue),
            await this._buildShareEntityAction(issue),
            await this._buildEditEntityAction(issue),
        ].filter((a) => a.applicable);
    };

    //
    // Actions
    //

    public async fetchDetails(issueId: string): Promise<IIssue> {
        const issue = await firstValueFrom(this.tasksApi.getIssue(issueId));
        await this.localUpdate(issue);
        return issue;
    }

    public async closeIssue(issueId: string): Promise<IIssue> {
        const issue = await firstValueFrom(this.tasksApi.closeIssue(issueId));
        await this.localUpdate(issue);
        return issue;
    }

    //
    // Data fetching
    //

    public async refreshIssueTypes(): Promise<Array<IIssueType>> {
        // Fetch issue types
        const config = await firstValueFrom(this.tenantService.config);
        const issueTypes = config.taskApi.issueTypes;
        // Wait for base translations to have loaded
        await firstValueFrom((this.translate.currentLoader as LazyTranslateLoader).translationsLoaded$);
        // Extract translations and append them
        issueTypes.forEach((type) => this.applyTranslationsForIssueType(type));
        // Map to named type
        const namedIssueTypes = issueTypes.map(IssueService.mapIssueTypeToNamedType);
        // Publish issue types
        this._issueTypes.next(namedIssueTypes);
        return issueTypes;
    }

    private async refreshIssues(): Promise<Array<IIssue>> {
        // Check permission
        if (!(await this.userService.checkPermission('Tasks.ReadIssues'))) return [];
        // Fetch issues
        const issues: Array<IIssue> = await firstValueFrom(this.tasksApi.getIssues());
        // Emit new issues
        this._issues.next(issues);
        // Return new issues
        return issues;
    }

    private applyTranslationsForIssueType(type: IIssueType): void {
        if (type.name && typeof type.name !== 'object') return;
        Object.entries(type.name).forEach(([lang, name]) => {
            this.translate.setTranslation(lang, { issueType: { [type.id]: name } }, true);
        });
    }

    private async _buildEditEntityAction(issue: IIssue): Promise<FilterableEntityAction> {
        return {
            label: 'comp.issue-list-item.action.edit',
            onClick: () => {
                const controlRoute: ControlRoute = {
                    mode: 'REPORT',
                    reportMode: 'ISSUE',
                    updateId: issue.id,
                    step: 'LOCATION',
                    subjectType: this.tenantService.getSubjectTypeForIdSync(issue.subjectTypeId),
                    issueType: this.getIssueTypeForIdSync(issue.typeId),
                    description: issue.description,
                    location: issue.location,
                    linkedPOI: issue.poiId ? this.poiService.getPOIForIdSync(issue.poiId) : undefined,
                    navigateOnSubmitted: this.router.url,
                };
                if (this.router.url.startsWith('/map')) {
                    this.controlBarService.pushRoute(controlRoute);
                } else {
                    this.router.navigate(['/map'], { state: { controlRoute } });
                }
            },
            icon: 'icon-edit',
            iconStyle: 'outlined',
            disabled: !['TODO', 'IN_PROGRESS'].includes(issue.status),
            applicable: await this.userService.checkPermission('Tasks.ManageIssues'),
        };
    }

    private async _buildCloseEntityAction(issue: IIssue): Promise<FilterableEntityAction> {
        return {
            label: 'comp.issue-list-item.action.close',
            onClick: async ($event) => {
                $event.stopImmediatePropagation();
                await this.closeIssue(issue.id);
                this.showStatusChangeNotification('CLOSED');
            },
            icon: 'icon-close',
            disabled:
                ['TODO', 'IN_PROGRESS', 'BLOCKED'].filter((s) => issue.taskStatuses[s as keyof IIssueProgress] > 0)
                    .length > 0,
            applicable:
                (await this.userService.checkPermission('Tasks.CloseIssues')) &&
                issue.status !== 'CLOSED' &&
                issue.status !== 'IN_PROGRESS',
        };
    }

    private async _buildShareEntityAction(issue: IIssue): Promise<FilterableEntityAction> {
        return {
            label: 'comp.entity-share-popup.share',
            applicable: await this.userService.checkPermission('Tasks.ShareIssues'),
            disabled: false,
            icon: 'icon-share',
            iconStyle: 'outlined',
            onClick: async ($event): Promise<void> => {
                $event.stopImmediatePropagation();
                this.domService.appendComponentTo<EntitySharePopupComponent>(
                    'overlay-container',
                    EntitySharePopupComponent,
                    {
                        entity: issue,
                        entityType: 'ISSUE',
                    }
                );
            },
        };
    }

    private showStatusChangeNotification(type: 'CLOSED'): void {
        const snackbar = this.domService.appendComponentTo<SnackbarComponent>('overlay-container', SnackbarComponent, {
            text: this.translate.instant(`misc.statusChange.issue.${type.toLowerCase()}`),
            actions: [
                {
                    label: this.translate.instant('misc.statusChange.actions.dismiss'),
                    onClick: () => snackbar.remove(),
                },
            ],
        });
    }

    private static mapIssueTypeToNamedType(type: IIssueType): INamedIssueType {
        return {
            id: type.id,
            name: typeof type.name === 'object' ? `issueType.${type.id}` : type.name,
            archived: type.archived,
        };
    }
}
