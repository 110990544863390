import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Pipe({
    name: 'authImage',
    pure: true,
})
export class AuthImagePipe implements PipeTransform {
    public constructor(private http: HttpClient) {}

    public transform(url: string): Observable<string> {
        return this.http.get(url, { responseType: 'blob' }).pipe(
            switchMap((response) => {
                const fileReader = new FileReader();
                const fileReader$ = fromEvent(fileReader, 'loadend').pipe(
                    map(({ target: reader }) => (reader as FileReader).result as string)
                );
                fileReader.readAsDataURL(response);

                return fileReader$;
            })
        );
    }
}
