import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IPOIMapCategory, ITaskType } from 'shared';

import { HTMLRenderer } from '../../../services/dom.service';
import { ISSUE_MARKER_SIZE, IssueMapLayerController } from '../map/layer-controllers/issue.map-layer-controller';
import { POI_ICON_SIZE, PoiMapLayerController } from '../map/layer-controllers/poi.map-layer-controller';
import { TASK_MARKER_SIZE, TaskMapLayerController } from '../map/layer-controllers/task.map-layer-controller';

@Component({
    selector: 'app-marker',
    templateUrl: './marker.component.html',
    styleUrls: ['./marker.component.scss'],
})
export class MarkerComponent implements AfterViewInit, OnChanges, HTMLRenderer {
    @Input() label = '';
    @Input() type: 'ISSUE_TYPE' | 'TASK_TYPE' | 'POI_CATEGORY' = 'ISSUE_TYPE';
    @Input() data: any;

    @ViewChild('contentWrapper', { static: false }) contentWrapperEl: ElementRef;

    public constructor(private sanitizer: DomSanitizer) {}

    public renderAsHtml(): SafeHtml {
        const el = document.createElement('div');
        this.update(el);
        return this.sanitizer.bypassSecurityTrustHtml(el.outerHTML);
    }

    public ngAfterViewInit(): void {
        this.update();
    }

    public ngOnChanges(): void {
        if (this.contentWrapperEl) this.update();
    }

    private update(container?: HTMLElement): void {
        const { element, dimensions } = this.buildMarker();
        container ??= this.contentWrapperEl.nativeElement;
        while (container.firstChild) container.firstChild.remove();
        container.appendChild(element ?? document.createElement('div'));
        container.style.width = `${dimensions?.width ?? 0}px`;
        container.style.height = `${dimensions?.height ?? 0}px`;
    }

    private buildMarker(): {
        element?: HTMLElement;
        dimensions?: { width?: number; height?: number; x?: number; y?: number };
    } {
        switch (this.type) {
            case 'ISSUE_TYPE':
                if (!this.data) return {};
                return {
                    element: IssueMapLayerController.buildElementForOptions(),
                    dimensions: ISSUE_MARKER_SIZE,
                };
            case 'TASK_TYPE':
                if (!this.data) return {};
                return {
                    element: TaskMapLayerController.buildElementForOptions({ type: this.data as ITaskType }),
                    dimensions: TASK_MARKER_SIZE,
                };
            case 'POI_CATEGORY':
                if (!this.data) {
                    this.data = { iconId: 'food', color: '#0A0A82' };
                }
                return {
                    element: PoiMapLayerController.buildElementForOptions({
                        mode: 'icon_only',
                        poiCategory: this.data as IPOIMapCategory,
                    }),
                    dimensions: POI_ICON_SIZE,
                };
            default:
                return {};
        }
    }
}
