<div class="table">
    <div
        class="table-body"
        [style.grid-template-columns]="
            (selectable ? '44px' : '') + ' repeat(' + enabledHeading?.length + ', minmax(0, 1fr))'
        "
    >
        <div class="table-header">
            <div class="table-header-bg"></div>
            <div class="cell cell-checkbox" *ngIf="selectable">
                <app-checkbox
                    [disabled]="!tableData?.length"
                    [checked]="isPageSelected()"
                    (checkedChange)="onSelectPage()"
                ></app-checkbox>
            </div>
            <div class="cell" *ngFor="let header of enabledHeading">
                <span *ngIf="!header.filterable">
                    {{ header.label }}
                </span>
                <app-entity-filter
                    *ngIf="header.filterable === 'SELECT'"
                    [placeholder]="header.label"
                    [header]="header"
                    [filterOptions]="filterOptions[header.name]"
                    (selectedChange)="onSelectFilterChange($event, header.name)"
                    [light]="true"
                    [resetRangeChecked]="false"
                    [disableNew]="maxFilters > 0 && filterCount >= maxFilters"
                ></app-entity-filter>
                <app-date-range-filter
                    *ngIf="header.filterable === 'DATE_RANGE'"
                    [placeholder]="header.label"
                    [header]="header"
                    (selectedChange)="onDateRangeFilterChange($event, header.name)"
                    [light]="true"
                    [disableNew]="maxFilters > 0 && filterCount >= maxFilters"
                ></app-date-range-filter>
                <ng-container *ngIf="header.sortable !== false">
                    <div
                        class="sort-label"
                        *ngVar="getSortState(header) as sortState"
                        (click)="toggleSortState(header)"
                        [title]="'comp.table.sort.' + (sortState ?? 'none') | translate : { column: header.label }"
                    >
                        <i
                            class="material-icons"
                            [@invert]="sortState === 'ascending'"
                            [ngClass]="sortState === 'ascending' ? 'asc' : sortState === 'descending' ? 'desc' : null"
                        >
                            sort
                        </i>
                    </div>
                </ng-container>
            </div>
        </div>
        <div
            class="table-row"
            [class.highlighted]="highlightRow === row.value.id"
            *ngFor="let row of tableData; let index = index; trackBy: trackBy"
            (click)="toggleSelection(row)"
        >
            <div class="cell cell-checkbox" *ngIf="selectable">
                <app-checkbox
                    [checked]="selectedIds.includes(row.value.id)"
                    (checkedChange)="onRowCheckChange(row.value.id, $event)"
                ></app-checkbox>
            </div>
            <div
                class="cell"
                *ngFor="let header of enabledHeading"
                [ngClass]="header.name + (header.valueCssClass ? ' ' + header.valueCssClass(row.value) : '')"
            >
                <ng-container *ngVar="header.button as button">
                    <button
                        *ngIf="button"
                        (click)="button.click && button.click($event, row.value)"
                        [title]="button.title | translate"
                        type="button"
                        class="btn btn-outline btn-small color-secondary"
                    >
                        <span class="archive-button material-icons-outlined">{{ button.icon }}</span>
                    </button>
                </ng-container>
                <ng-container *ngIf="!header.button">
                    <span *ngIf="!header.translatable">{{ row.value[header.name] }}</span>
                    <ng-container *ngIf="header.translatable">
                        <span
                            *ngIf="isFunction(header.translatable)"
                            [innerHTML]="header.translatable(row.value[header.name], row.value) | async"
                        ></span>
                        <ng-container *ngIf="!isFunction(header.translatable)">
                            <ng-container *ngVar="translateValue(row, header) as translated">
                                <span
                                    *ngIf="translated !== row.value[header.name] || !header.hideUntranslated"
                                    [title]="translated"
                                >
                                    <span>{{ translated }}</span>
                                </span>
                                <span
                                    class="translating-placeholder"
                                    *ngIf="
                                        translated && translated === row.value[header.name] && header.hideUntranslated
                                    "
                                >
                                    <span>{{ 'comp.table.translating' | translate }}</span>
                                </span>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="table-no-rows" *ngIf="!tableData?.length">
            <div class="cell">{{ 'comp.table.noElements' | translate }}</div>
        </div>
        <div class="table-filler-row"></div>
    </div>
    <div class="table-footer">
        <div>
            <div class="cell">
                <button
                    class="btn btn-small btn-no-outline color-primary"
                    (click)="previous()"
                    [disabled]="!hasPrevious()"
                >
                    <i class="material-icons">chevron_left</i>
                    <span>{{ 'comp.table.pagination.previous' | translate }}</span>
                </button>
            </div>
            <div class="cell">
                <span>
                    {{
                        (data?.length === 1
                            ? 'comp.table.pagination.elements.singular'
                            : 'comp.table.pagination.elements.plural'
                        )
                            | translate
                                : {
                                      start: pagination.start + 1,
                                      end: math.min(pagination.start + pagination.step, pagination.total ?? 0),
                                      total: pagination.total ?? 0
                                  }
                    }}
                </span>
            </div>
            <div class="cell">
                <button
                    class="btn btn-small btn-no-outline color-primary btn-page-size"
                    (click)="onPageSizeClick($event)"
                >
                    {{ 'comp.table.pagination.pageSize' | translate : { pageSize: pagination.step } }}
                    <i class="material-icons">arrow_drop_down</i>
                </button>
            </div>
            <div class="cell">
                <button class="btn btn-small btn-no-outline color-primary" (click)="next()" [disabled]="!hasNext()">
                    <span>{{ 'comp.table.pagination.next' | translate }}</span>
                    <i class="material-icons">chevron_right</i>
                </button>
            </div>
        </div>
    </div>
</div>
