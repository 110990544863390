<ng-container *ngIf="entry">
    <div (click)="onHeaderClick()" [class.has-drawer]="hasDrawer" class="header">
        <i [ngClass]="iconClass" class="material-icons log-icon">{{ icon }}</i>
        <div class="text-col">
            <div [innerHTML]="title | translate : titleTokens | marked" class="title"></div>
            <div class="timestamp">{{ timestamp | amFromUnix | amCalendar | ucfirst }}</div>
        </div>
        <i *ngIf="hasDrawer" @hshrink [class.collapsed]="drawerCollapsed" class="material-icons caret-icon">
            keyboard_arrow_up
        </i>
    </div>
    <div *ngIf="!drawerCollapsed" @vshrink class="drawer">
        <ng-container *ngIf="assetScore">
            <div class="title">{{ 'comp.audit-log-entry.newScoreHeader' | translate }}</div>
            <div class="content">{{ assetScore }}</div>
        </ng-container>
        <ng-container *ngIf="entry?.type === 'TaskBlocked'">
            <ng-container *ngVar="asTaskBlockedLogEntry(entry) as blockedEntry">
                <ng-container *ngIf="blockedEntry.blockReasonId">
                    <div class="title">{{ 'comp.audit-log-entry.blockReasonHeader' | translate }}</div>
                    <div class="content">{{ 'blockReason.' + blockedEntry.blockReasonId | translate }}</div>
                </ng-container>
                <ng-container *ngIf="blockedEntry.blockReasonNote">
                    <div class="title">{{ 'comp.audit-log-entry.blockDescriptionHeader' | translate }}</div>
                    <div class="content">{{ blockedEntry.blockReasonNote }}</div>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="entry?.type === 'TaskPatched' || entry?.type === 'IssuePatched'">
            <ng-container *ngVar="asPatchedLogEntry(entry) as patchedEntry">
                <div *ngFor="let patch of patchedEntry.patched | keyvalue" class="patch-entry">
                    <div class="patch-key">
                        {{ 'comp.audit-log-entry.patchFields.' + patch.key | translate }}
                    </div>
                    <div class="patch-value">
                        <span *ngIf="patch.value.previous" class="patch-value-display">
                            {{ getPatchDisplayValue(patch.key, patch.value.previous) | async }}
                        </span>
                        <span *ngIf="!patch.value.previous" class="patch-value-display empty">
                            {{ 'errors.entities.none' | translate }}
                        </span>
                        <i class="material-icons">arrow_forward</i>
                        <span *ngIf="patch.value.current" class="patch-value-display">
                            {{ getPatchDisplayValue(patch.key, patch.value.current) | async }}
                        </span>
                        <span *ngIf="!patch.value.current" class="patch-value-display empty">
                            {{ 'errors.entities.none' | translate }}
                        </span>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="entry?.type === 'IssueImageAdded'"></ng-container>
    </div>
</ng-container>
