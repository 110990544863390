<div class="entity-header" [title]="devMode ? entity.id : title">
    <div class="entity-header-left">
        <div class="app-marker-container">
            <ng-content select="app-marker"></ng-content>
            <ng-content select=".entity-icon"></ng-content>
        </div>
        <div class="entity-header-text">
            <span class="entity-title">{{ title }}</span>
            <span class="entity-subtitle">{{ subtitle }}</span>
        </div>
    </div>
    <div class="entity-header-right">
        <ng-content select=".entity-action"></ng-content>
    </div>
</div>
