import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { fadeDown } from 'shared';

@Component({
    selector: 'app-date-selector',
    templateUrl: './date-selector.component.html',
    styleUrls: ['./date-selector.component.scss'],
    animations: [fadeDown()],
})
export class DateSelectorComponent implements OnInit {
    @Input() date: number;
    @Output() dateChange: EventEmitter<number> = new EventEmitter<number>();
    showDropDown = false;

    ngOnInit(): void {
        // setTimeout to send a default without triggering ExpressionChanged error
        if (!this.date) setTimeout(() => this.dateChange.emit(this.date), 0);
        this.date = this.date || moment().startOf('day').unix();
    }

    onDateNext() {
        this.date = moment.unix(this.date).add(1, 'days').startOf('day').unix();
        this.dateChange.emit(this.date);
    }

    onDatePrevious() {
        this.date = moment.unix(this.date).subtract(1, 'days').startOf('day').unix();
        this.dateChange.emit(this.date);
    }

    onSelectedChange(date: Date) {
        this.date = moment(date).unix();
        this.dateChange.emit(this.date);
    }

    protected unixTimeAsDate(unixTime: number): Date {
        return new Date(unixTime * 1000);
    }
}
