export class TimeoutUtils {
    private static debounceMap: Record<string, number> = {};
    private static throttleMap: Record<string, number> = {};

    /**
     * Debounce a function call.
     * @param key The key to identify the function call.
     * @param callback The function to call.
     * @param ms The time to wait before calling the function.
     * @param args The arguments to pass to the function.
     */
    public static debounce(key: string, callback: (...args: never[]) => void, ms?: number, ...args: never[]): number {
        this.cancelDebounce(key);

        const timeout = window.setTimeout(callback, ms, ...args);
        this.debounceMap[key] = timeout;

        return timeout;
    }

    /**
     * Remove a debounce timeout.
     * @param key The key to identify the function call.
     */
    public static cancelDebounce(key: string): void {
        this.cancelTimeout(key, this.debounceMap);
    }

    /**
     * Throttle a function call.
     * @param key The key to identify the function call.
     * @param callback The function to call.
     * @param ms The time to wait before calling the function.
     * @param args The arguments to pass to the function.
     */
    public static throttle(key: string, callback: (...args: never[]) => void, ms?: number, ...args: never[]): number {
        if (this.throttleMap[key]) return this.throttleMap[key];

        const throttle = window.setTimeout(() => this.cancelThrottle(key), ms);

        callback(...args);
        this.throttleMap[key] = throttle;
        return throttle;
    }

    /**
     * Remove a Throttle timeout.
     * @param key The key to identify the function call.
     */
    public static cancelThrottle(key: string): void {
        this.cancelTimeout(key, this.throttleMap);
    }

    /**
     * Remove a timeout.
     * @param key The key to identify the function call.
     * @param map The map to remove the timeout from.
     * @private
     */
    private static cancelTimeout(key: string, map: Record<string, number>): void {
        if (map[key]) {
            clearTimeout(map[key]);
            delete map[key];
        }
    }
}
