import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { IIssue } from 'shared';

import { IssueService } from '../issue.service';
import { SharingService } from '../sharing.service';
import { SocketService } from '../socket.service';
import { BaseSocketEventHandler } from './base-socket-event-handler';

interface IssueArchivedSocketEvent {
    eventDtoType: 'IssueArchived';
    issue: IIssue;
}

@Injectable({ providedIn: 'root' })
export class IssueArchivedSocketEventHandler extends BaseSocketEventHandler<IssueArchivedSocketEvent> {
    protected constructor(
        private issueService: IssueService,
        socketService: SocketService,
        private sharingService: SharingService
    ) {
        super(socketService);
    }

    eventDtoType(): string {
        return 'IssueArchived';
    }

    handleEvent = async (event: IssueArchivedSocketEvent) => {
        // Remove issue locally
        this.issueService.localRemove(event.issue.id);
        // Get shared issue
        const sharedEntity = await firstValueFrom(this.sharingService.sharedEntity);
        // Update shared issue
        if (sharedEntity && sharedEntity.type === 'SHARED_ISSUE' && sharedEntity.issue.id === event.issue.id) {
            sharedEntity.issue.archived = true;
            this.sharingService.updateSharedEntity(sharedEntity);
        }
    };
}
