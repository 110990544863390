import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'baseflow-auth';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { TenantService } from '../services/tenant.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
    public constructor(
        private readonly auth: AuthService,
        private readonly tenantService: TenantService,
        private readonly router: Router
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((e) => {
                const errorResponse = e as HttpErrorResponse;
                if (errorResponse.status === 401) {
                    this.auth.logout();
                    this.tenantService.selectTenant(null, null);
                    this.router.navigate(['/']);
                }
                return throwError(e);
            })
        );
    }
}
