import { Injectable } from '@angular/core';

import { SocketService } from '../socket.service';
import { TaskService } from '../task.service';
import { TenantService } from '../tenant.service';
import { BaseSocketEventHandler } from './base-socket-event-handler';

interface TaskTypesUpdatedSocketEvent {
    eventDtoType: 'TaskTypesUpdated';
}

@Injectable({ providedIn: 'root' })
export class TaskTypesUpdatedSocketEventHandler extends BaseSocketEventHandler<TaskTypesUpdatedSocketEvent> {
    protected constructor(
        socketService: SocketService,
        private tenantService: TenantService,
        private taskService: TaskService
    ) {
        super(socketService);
    }

    protected eventDtoType(): string {
        return 'TaskTypesUpdated';
    }

    protected handleEvent = async (_: TaskTypesUpdatedSocketEvent): Promise<void> => {
        await this.tenantService.refreshConfig();
        await this.taskService.refreshTaskTypes();
    };
}
