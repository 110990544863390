import { Component } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { fadeUp } from 'shared';

import { BaseFilterInputComponent } from '../base-filter-input/base-filter-input.component';

@Component({
    selector: 'app-date-range-filter',
    templateUrl: './date-range-filter.component.html',
    styleUrls: ['./date-range-filter.component.scss'],
    animations: [fadeUp()],
})
export class DateRangeFilterComponent extends BaseFilterInputComponent<DateRange<Date>> {
    dateRange: DateRange<Date> = new DateRange<Date>(null, null);

    public constructor(private toastr: ToastrService, private translate: TranslateService) {
        super();
    }

    public resetInput(): void {
        this.dateRange = new DateRange<Date>(null, null);
        this.selectedChange.emit(this.dateRange);
    }

    public setDateRange(start: Date, end: Date): void {
        if ((start !== null || end !== null) && this.disabled) return;
        this.dateRange = new DateRange(start, end);
        this.selectedChange.emit(this.dateRange);
    }

    protected get disabled() {
        return this.disableNew && this.dateRange.start === null && this.dateRange.end === null;
    }

    protected onSelectedChange(date: Date): void {
        if (this.disabled) {
            this.resetInput();
            return;
        }
        if (!this.dateRange.start || this.dateRange.end) {
            this.dateRange = new DateRange<Date>(date, null);
            this.selectedChange.emit(new DateRange<Date>(null, null));
        } else {
            if (date.getTime() < this.dateRange.start.getTime()) {
                this.dateRange = new DateRange<Date>(
                    date,
                    new Date(this.dateRange.start.getTime() + 3600 * 24 * 1000 - 1)
                );
            } else {
                this.dateRange = new DateRange<Date>(
                    this.dateRange.start,
                    new Date(date.getTime() + 3600 * 24 * 1000 - 1)
                );
            }
            this.selectedChange.emit(this.dateRange);
        }
    }

    protected onCalendarClick(): void {
        if (this.disabled) {
            this.toastr.error(
                this.translate.instant('history.toasts.maxFiltersReached.message'),
                this.translate.instant('history.toasts.maxFiltersReached.title')
            );
        }
    }
}
