import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { IIssue } from 'shared';

import { IssueService } from '../issue.service';
import { SocketService } from '../socket.service';
import { BaseSocketEventHandler } from './base-socket-event-handler';

interface IssueCreatedSocketEvent {
    eventDtoType: 'IssueCreated';
    issue: IIssue;
}

@Injectable({ providedIn: 'root' })
export class IssueCreatedSocketEventHandler extends BaseSocketEventHandler<IssueCreatedSocketEvent> {
    protected constructor(private issueService: IssueService, socketService: SocketService) {
        super(socketService);
    }

    eventDtoType(): string {
        return 'IssueCreated';
    }

    handleEvent = async (event: IssueCreatedSocketEvent) => {
        const issues = await firstValueFrom(this.issueService.issues);
        if (issues.find((t) => t.id === event.issue.id)) await this.issueService.localUpdate(event.issue);
        else await this.issueService.localAdd(event.issue);
    };
}
