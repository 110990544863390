import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-circular-progress-indicator',
    templateUrl: './circular-progress-indicator.component.html',
    styleUrls: ['./circular-progress-indicator.component.scss'],
})
export class CircularProgressIndicatorComponent {
    @Input() strokeWidth = 3;
    @Input() size = 24;

    get width(): string {
        return this.size + 'px';
    }

    get height(): string {
        return this.size + 'px';
    }

    get viewBox(): string {
        return `0 0 ${this.size} ${this.size}`;
    }

    get cx(): string {
        return Math.round(this.size / 2).toFixed(0);
    }

    get cy(): string {
        return Math.round(this.size / 2).toFixed(0);
    }

    get r(): string {
        return (Math.round(this.size / 2) - Math.round(this.strokeWidth / 2)).toFixed(0);
    }
}
