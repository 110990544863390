export interface IOrdinal {
    name: string;
    id: number;
    imdfId?: string;
}

export const DEFAULT_ORDINAL: IOrdinal = {
    name: '',
    id: 0,
    imdfId: 'GROUND_FLOOR',
};
