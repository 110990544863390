import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ISubLocation } from 'shared';

import { ordinal as i18n_ordinal } from '../../utils/localisation-utils';

@Pipe({
    name: 'subLocationName',
    pure: true,
})
export class SubLocationNamePipe implements PipeTransform {
    public constructor(private translate: TranslateService) {}

    public transform(subLocation?: ISubLocation): Observable<string> {
        switch (subLocation?.type) {
            case 'PositionFromSide':
                const translateKey = `pipes.subLocationName.${subLocation.type}.${subLocation.side}`;
                return this.translate.onLangChange.pipe(
                    startWith(true),
                    map(() =>
                        this.translate.instant(translateKey, {
                            ordinal: i18n_ordinal(this.translate.currentLang, subLocation.position + 1),
                        })
                    )
                );
            case null:
            case undefined:
                return of('');
            default:
                return this.translate.stream('pipes.subLocationName.unknown');
        }
    }
}
