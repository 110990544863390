import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, DoCheck, Input } from '@angular/core';

import { MapService } from '../../../services/map.service';

@Component({
    selector: 'app-map-layout',
    templateUrl: './map-layout.component.html',
    styleUrls: ['./map-layout.component.scss'],
    animations: [
        trigger('controlArea', [
            state(
                'closed',
                style({
                    transform: 'translateX(-100%)',
                    opacity: 0,
                })
            ),
            transition('closed => open', [animate('.35s cubic-bezier(0.76, 0, 0.24, 1)')]),
            transition('open => closed', [animate('.35s cubic-bezier(0.76, 0, 0.24, 1)')]),
        ]),
    ],
})
export class MapLayoutComponent implements DoCheck {
    _lastShowControlArea = true;
    @Input() showControlArea = true;
    @Input() showMapControls = true;

    public constructor(private mapService: MapService) {}

    ngDoCheck() {
        if (this.showControlArea !== this._lastShowControlArea) {
            this._lastShowControlArea = this.showControlArea;
        }
    }
}
