/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EmbeddedViewRef,
    Injectable,
    Injector,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { ErrorPopupComponent } from '../components/modals/error-popup/error-popup.component';

export interface HTMLRenderer {
    renderAsHtml(): SafeHtml;
}

@Injectable({ providedIn: 'any' })
export class DomService {
    private constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
        private sanitizer: DomSanitizer
    ) {}

    public renderComponentToHtml<C>(component: new (...a: any) => C, fields: { [field: string]: any } = {}): SafeHtml {
        const componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);
        for (const key of Object.keys(fields)) componentRef.instance[key as keyof C] = fields[key];
        // If the component implements HTMLRenderer, we let it render itself.
        if ((componentRef.instance as any)['renderAsHtml']) {
            return (componentRef.instance as HTMLRenderer).renderAsHtml();
        }
        // If not, we render the component as HTML ourselves. (Note that no logic inside the lifecycle hooks is executed).
        this.appRef.attachView(componentRef.hostView);
        const childDomElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        const html = this.sanitizer.bypassSecurityTrustHtml(childDomElem.outerHTML);
        this.appRef.detachView(componentRef.hostView);
        return html;
    }

    public appendComponentTo<C>(
        parentId: string,
        child: new (...a: any) => C,
        fields: { [field: string]: any } = {}
    ): DomComponent<C> {
        // Create a component reference from the component
        const componentRef = this.componentFactoryResolver.resolveComponentFactory(child).create(this.injector);
        // Create dom component wrapper for component
        const domComponent = new DomComponent<C>(componentRef, this.appRef);
        // Attach fields to the component
        fields = { ...fields, __DOM_COMPONENT: domComponent };
        for (const key of Object.keys(fields)) componentRef.instance[key as keyof C] = fields[key];
        // Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(componentRef.hostView);
        // Get DOM element from component
        const childDomElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        // Append DOM element to the body
        document.getElementById(parentId).appendChild(childDomElem);
        // Return as DomComponent
        return domComponent;
    }

    showError(namespace: string, code: string): DomComponent<ErrorPopupComponent> {
        return this.appendComponentTo<ErrorPopupComponent>('overlay-container', ErrorPopupComponent, {
            error: {
                title: `errors.${namespace}.${code}.title`,
                message: `errors.${namespace}.${code}.message`,
                errorCode: code,
            },
        });
    }
}

export class DomComponent<C> {
    private onRemove: () => void;

    public get isRemoved(): boolean {
        return !this.componentRef;
    }

    public constructor(private componentRef: ComponentRef<C>, private appRef: ApplicationRef) {}

    get component(): C {
        return this.componentRef.instance;
    }

    get elementRef(): ElementRef {
        return this.componentRef.location;
    }

    remove() {
        if (this.componentRef) {
            this.appRef.detachView(this.componentRef.hostView);
            this.componentRef.destroy();
            this.componentRef = null;
            if (this.onRemove) this.onRemove();
            this.onRemove = null;
        }
    }

    on(event: 'remove', cb: () => void) {
        this.onRemove = cb;
    }
}
