<div class="modal modal-md">
    <div class="modal-header">
        <div class="modal-title">{{ error?.title || 'No.' | translate }}</div>
        <button class="modal-close" (click)="close()">
            <i class="material-icons">clear</i>
        </button>
    </div>
    <div class="modal-content">
        <span class="error-message">
            {{ error?.message || 'Could not because no.' | translate }}
            <br />
            <br />
            <span class="error-code">Error code: {{ error?.errorCode }}</span>
        </span>
        <div class="error-icon-container">
            <i class="material-icons error-icon">error</i>
        </div>
    </div>
    <div class="modal-btn-row">
        <button class="btn color-secondary btn-large" (click)="close()">
            <span>{{ 'comp.error-popup.close' | translate }}</span>
        </button>
    </div>
</div>
