import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { ITaskType } from 'shared';

import { TaskService } from '../services/task.service';

@Pipe({
    name: 'taskType',
    pure: true,
})
export class TaskTypePipe implements PipeTransform {
    public constructor(private taskService: TaskService) {}

    transform(taskTypeId: string): Observable<ITaskType> {
        return this.taskService.getTaskTypeForId(taskTypeId);
    }
}
