import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITenant } from 'baseflow-auth';
import { GeoJSON } from 'leaflet';
import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import {
    IOrdinal,
    ITable,
    IThirdPartyService,
    IThirdPartyServicePOST,
    IThirdPartyServicePUT,
    IThirdPartyToken,
    IThirdPartyTokenPOST,
    IThirdPartyTokenPOSTResult,
} from '../../models';
import { BaseRepositoryService } from './base-repository.service';

export interface ITenantConfig {
    centerCoordinates: {
        latitude: number;
        longitude: number;
    };
    id: string;
    imdf?: {
        mapId: string;
    };
    mapBox?: {
        token: string;
        style: string;
    };
    rotation: number;
    zoom: number;
    zoomBoundaries: {
        min: number;
        max: number;
    };
    ordinals: Array<IOrdinal>;
    tables: Array<ITable<unknown>>;
}

export interface ITenantConfigPUT extends Omit<ITenantConfig, 'mapBox' | 'id' | 'ordinals' | 'tables'> {
    id?: string;
    token: string;
    style: string;
}

export type IMapIMDFData = {
    levels: {
        type: 'FeatureCollection';
        features: Array<IMapIMDFFeature>;
    };
    units: {
        type: 'FeatureCollection';
        features: Array<IMapIMDFFeature>;
    };
    fixtures: {
        type: 'FeatureCollection';
        features: Array<IMapIMDFFeature>;
    };
    buildings: {
        type: 'FeatureCollection';
        features: Array<IMapIMDFFeature>;
    };
};

export type IMapIMDFFeature = {
    type: 'Feature';
    geometry: GeoJSON.Geometry;
    properties: {
        ORDINAL: number;
        NAME: string;
        LEVEL_ID: string;
    };
};

export type IMapIMDFStyles = any;

@Injectable({ providedIn: 'root' })
export class TenantApiService extends BaseRepositoryService {
    constructor() {
        super('tenantApi');
    }

    public createTenant(name: string): Observable<ITenant> {
        return this.getApiUrl('/tenants', 'v2').pipe(mergeMap((url) => this.http.post<ITenant>(url, { name })));
    }

    public getTenant(tenantId: string): Observable<ITenant> {
        return this.getApiUrl(`/tenants/${tenantId}`, 'v1').pipe(mergeMap((url) => this.http.get<ITenant>(url)));
    }

    public updateTenant(name: string, tenantId: string, tenantTemplate?: string): Observable<ITenant> {
        return this.getApiUrl('/tenants', 'v1').pipe(
            mergeMap((url) => this.http.put<ITenant>(url, { name, tenantId, tenantTemplate }))
        );
    }

    public getConfig(tenantId: string): Observable<ITenantConfig> {
        return this.getApiUrl('/config').pipe(
            mergeMap((url) =>
                this.http.get<ITenantConfig>(url, { params: new HttpParams().append('tenantId', tenantId) }).pipe(
                    tap((config) => {
                        if (!config.ordinals?.length) console.warn('No ordinals found in tenant config');
                    })
                )
            )
        );
    }

    public updateConfig(config: ITenantConfigPUT, tenantId: string): Observable<ITenantConfig> {
        return this.getApiUrl('/config').pipe(
            mergeMap((url) => {
                return this.http.put<ITenantConfig>(url, config, {
                    params: new HttpParams().append('tenantId', tenantId),
                });
            })
        );
    }

    public getIMDFData(mapId: string, tenantId: string): Observable<IMapIMDFData> {
        return this.getApiUrl(`imdf/${mapId}`).pipe(
            mergeMap((url) => {
                return this.http.get<IMapIMDFData>(url, {
                    params: new HttpParams().append('tenantId', tenantId),
                });
            })
        );
    }

    public getIMDFStyles(mapId: string, tenantId: string): Observable<IMapIMDFData> {
        return this.getApiUrl(`imdf/${mapId}/styles`).pipe(
            mergeMap((url) => {
                return this.http.get<IMapIMDFData>(url, {
                    params: new HttpParams().append('tenantId', tenantId),
                });
            })
        );
    }

    public getThirdPartyServices(): Observable<Array<IThirdPartyService>> {
        return this.getApiUrl('/services').pipe(mergeMap((url) => this.http.get<Array<IThirdPartyService>>(url)));
    }

    public getThirdPartyService(serviceId: string): Observable<IThirdPartyService> {
        return this.getApiUrl(`/services/${serviceId}`).pipe(mergeMap((url) => this.http.get<IThirdPartyService>(url)));
    }

    public createThirdPartyService(service: IThirdPartyServicePOST): Observable<IThirdPartyService> {
        return this.getApiUrl('/services').pipe(mergeMap((url) => this.http.post<IThirdPartyService>(url, service)));
    }

    public putThirdPartyService(serviceId: string, service: IThirdPartyServicePUT): Observable<IThirdPartyService> {
        return this.getApiUrl(`/services/${serviceId}`).pipe(
            mergeMap((url) => this.http.put<IThirdPartyService>(url, service))
        );
    }

    public deleteThirdPartyService(id: string): Observable<IThirdPartyService> {
        return this.getApiUrl(`/services/${id}`).pipe(mergeMap((url) => this.http.delete<IThirdPartyService>(url)));
    }

    public getThirdPartyToken(id: string): Observable<IThirdPartyToken> {
        return this.getApiUrl(`/tokens/${id}`).pipe(mergeMap((url) => this.http.get<IThirdPartyToken>(url)));
    }

    public createThirdPartyToken(
        serviceId: string,
        token: IThirdPartyTokenPOST
    ): Observable<IThirdPartyTokenPOSTResult> {
        return this.getApiUrl(`/services/${serviceId}/token`).pipe(
            mergeMap((url) => this.http.post<IThirdPartyTokenPOSTResult>(url, token))
        );
    }

    public deleteThirdPartyToken(serviceId: string, id: string): Observable<IThirdPartyToken> {
        return this.getApiUrl(`/services/${serviceId}/token/${id}`).pipe(
            mergeMap((url) => this.http.delete<IThirdPartyToken>(url))
        );
    }
}
