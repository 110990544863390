<div *ngIf="primaryAction || secondaryAction" @vshrink class="main-row">
    <button
        (click)="clickSecondary($event)"
        *ngIf="secondaryAction || moreActions.length"
        @hshrink
        [disabled]="(secondaryAction && secondaryAction.disabled) || (socketService.disableUI | async)"
        class="btn btn-large"
    >
        <app-icon
            *ngIf="secondaryAction?.icon"
            @hshrink
            [key]="secondaryAction.icon"
            [style]="secondaryAction.iconStyle"
            class="icon"
        ></app-icon>
        <span>{{ (secondaryAction ? secondaryAction.label : 'mapView.detail.moreActions') | translate }}</span>
    </button>
    <button
        (click)="primaryAction.onClick($event)"
        *ngIf="primaryAction"
        @hshrink
        [disabled]="primaryAction.disabled || (socketService.disableUI | async)"
        class="btn btn-large color-secondary"
    >
        <app-icon
            *ngIf="primaryAction?.icon"
            @hshrink
            [key]="primaryAction.icon"
            [style]="primaryAction.iconStyle"
            class="icon"
        ></app-icon>
        <span>{{ primaryAction.label | translate }}</span>
    </button>
</div>
