import {
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    HostBinding,
    HostListener,
    OnInit,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { sleep } from '@cds/core/internal';
import { interval } from 'rxjs';
import { tap } from 'rxjs/operators';
import { fade } from 'shared';

import { DomComponent } from '../../../services/dom.service';

@Component({
    selector: 'app-base-popup',
    templateUrl: './base-popup.component.html',
    styleUrls: ['./base-popup.component.scss'],
    animations: [fade('popup')],
})
export class BasePopupComponent<T> implements OnInit {
    protected readonly destroyRef = inject(DestroyRef);
    protected readonly cdr = inject(ChangeDetectorRef);

    @HostBinding('style.position')
    protected readonly stylePosition: string = 'absolute';
    @HostBinding('style.left')
    protected styleLeft = '0px';
    @HostBinding('style.top')
    protected styleTop = '0px';
    @HostBinding('style.pointer-events')
    protected stylePointerEvents: string = 'all';

    public set origin(value: [number, number]) {
        requestAnimationFrame(() => {
            const width = this.elementRef.nativeElement.clientWidth;
            this._position[0] = value[0] - width / 2;
            this._position[1] = value[1] + (this.popupOptions.hasArrow ? 10 : 0);
            this.updateStyles();
            this.ensureSafePosition();
        });
    }

    private _position: [number, number] = [0, 0];
    // Internals
    public readonly __DOM_COMPONENT: DomComponent<T>;
    protected closable = false;
    private childPopups: Array<DomComponent<BasePopupComponent<unknown>>> = [];
    // Options
    public popupOptions = {
        padding: 10,
        hasArrow: false,
        closeByEsc: true,
        closeByClickOutside: true,
        pointerEvents: 'all',
    };

    @HostBinding('@popup') protected popupAnimation = true;

    public constructor(protected elementRef: ElementRef) {
        this.cdr = inject<ChangeDetectorRef>(ChangeDetectorRef);
    }

    @HostListener('document:click', ['$event'])
    public clickOutside($event: MouseEvent): void {
        const clickWithinComponent = this.elementRef.nativeElement.contains($event.target);
        let clickWithinChildPopup = this.childPopups
            .filter((child) => !child.isRemoved)
            .some((child) => child.elementRef.nativeElement.contains($event.target));
        if (this.popupOptions.closeByClickOutside && this.closable && !clickWithinComponent && !clickWithinChildPopup) {
            $event.stopImmediatePropagation();
            this.__DOM_COMPONENT.remove();
        }
    }

    @HostListener('document:keydown.escape')
    public onEscapeDown(): void {
        if (this.popupOptions.closeByEsc) this.__DOM_COMPONENT.remove();
    }

    public ngOnInit(): void {
        // Allow popup to close itself after a bit, to prevent it closing itself upon opening
        setTimeout(() => (this.closable = true), 100);
        // Have popup move itself to a safer position if needed continuously
        interval(100)
            .pipe(takeUntilDestroyed(this.destroyRef), tap(this.ensureSafePosition.bind(this)))
            .subscribe();
    }

    public registerChildPopup(child: DomComponent<BasePopupComponent<unknown>>): void {
        if (!this.childPopups.includes(child)) this.childPopups.push(child);
    }

    private async ensureSafePosition(): Promise<void> {
        await sleep(10);
        // Parameters
        let left = this._position[0];
        let top = this._position[1];
        const width = this.elementRef.nativeElement.clientWidth;
        const height = this.elementRef.nativeElement.clientHeight;
        // Calculate new position if needed
        if (top < this.popupOptions.padding) top = this.popupOptions.padding;
        if (left < this.popupOptions.padding) left = this.popupOptions.padding;
        if (top + height > window.innerHeight - this.popupOptions.padding)
            top = window.innerHeight - this.popupOptions.padding - this.elementRef.nativeElement.clientHeight;
        if (left + width > window.innerWidth - this.popupOptions.padding)
            left = window.innerWidth - this.popupOptions.padding - this.elementRef.nativeElement.clientWidth;
        // Change position
        this._position[0] = left;
        this._position[1] = top;
        this.updateStyles();
    }

    private updateStyles(): void {
        this.styleTop = `${this._position[1]}px`;
        this.styleLeft = `${this._position[0]}px`;
        this.stylePointerEvents = this.popupOptions.pointerEvents;
        this.cdr.markForCheck();
    }
}
