import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { UserService } from '../services/user.service';

@Pipe({
    name: 'hasPermissions',
    pure: false,
})
export class UserPermissionPipe implements PipeTransform {
    public constructor(private userService: UserService) {}

    transform(permissions: string | Array<string>, all = true): Observable<boolean> {
        return this.userService.hasPermissions(typeof permissions === 'string' ? [permissions] : permissions, all);
    }
}
