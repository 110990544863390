import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { IHttpConnectionOptions } from '@microsoft/signalr/src/IHttpConnectionOptions';
import { AuthService, ITenant } from 'baseflow-auth';
import { Observable, Subject, combineLatest, firstValueFrom, switchMap } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { ISocketEvent } from '../../models/socket-event.model';
import { AppSettingsService } from './app-settings.service';
import { CustomHeaderHttpClient } from './http-client.service';
import { AppStateService } from './state/app-state.service';

export type ISocketStatus =
    | { status: 'DISCONNECTED' | 'CONNECTING' | 'DROPPED' | 'CONNECTED' }
    | { status: 'RECONNECTING'; attempt: number };

@Injectable({ providedIn: 'root' })
export class SocketService {
    private connection: HubConnection;
    private readonly _socketEvents = new Subject<ISocketEvent>();
    public readonly socketEvents = this._socketEvents.asObservable();
    public readonly disableUI: Observable<boolean>;

    private constructor(
        private readonly appSettingsService: AppSettingsService,
        private readonly authService: AuthService,
        private readonly appState: AppStateService
    ) {
        // Init socket on login
        this.appState.loggedInWithTenant.stream
            .pipe(switchMap((loggedIn) => (loggedIn ? this.connect() : this.disconnect())))
            .subscribe();

        this.socketEvents.subscribe((e) => console.log('SocketEvent', 'IN', e));

        this.disableUI = this.appState.socketStatus.stream.pipe(map((s) => s.status !== 'CONNECTED'));
    }

    public async disconnect(): Promise<void> {
        this.appState.socketStatus.value = { status: 'DISCONNECTED' };
        if (this.connection) {
            await this.connection.stop().catch();
            this.connection = null;
        }
    }

    public connect = async (): Promise<void> => {
        await this.disconnect();
        this.connection = await this.buildConnection();
        this.connection.onclose(this.handleSocketClose);
        this.connection.onreconnecting(this.handleSocketReconnecting);
        this.connection.onreconnected(this.handleSocketReconnected);
        this.connection.on('HandleEvent', (e) => this._socketEvents.next(e));
        this.appState.socketStatus.value = { status: 'CONNECTING' };
        for (let i = 0; i < 3; i++) {
            if (this.appState.socketStatus.value.status !== 'CONNECTING') return;
            try {
                await this.connection.start();
                this.appState.socketStatus.value = { status: 'CONNECTED' };
                return;
            } catch (e) {
                console.warn('Could not connect signalR socket. Retrying in 2s...');
                await new Promise((res) => setTimeout(() => res(undefined), 2000));
            }
        }
        this.appState.socketStatus.value = { status: 'DROPPED' };
    };

    private handleSocketReconnected = async (_connectionId?: string): Promise<void> => {
        this.appState.socketStatus.value = { status: 'CONNECTED' };
    };

    private handleSocketReconnecting = async (_error: unknown): Promise<void> => {
        this.appState.socketStatus.value = { status: 'RECONNECTING', attempt: 0 };
    };

    private handleSocketClose = async (_error: unknown): Promise<void> => {
        if (this.connection.state === HubConnectionState.Disconnected) {
            if (this.appState.socketStatus.value.status === 'RECONNECTING')
                this.appState.socketStatus.value = { status: 'DROPPED' };
            else await this.disconnect();
        }
    };

    private async buildConnection(): Promise<HubConnection> {
        return firstValueFrom(
            combineLatest([this.appState.activeTenant.stream.pipe(take(1)), this.appSettingsService.appSettings]).pipe(
                map(([tenant, appSettings]) => [tenant, appSettings.url.api.notifications] as [ITenant, string]),
                map(([tenant, baseUrl]) =>
                    new HubConnectionBuilder()
                        .withUrl(`${baseUrl}/v1/hub?onego-tenant=${tenant.id}`, {
                            accessTokenFactory: () => this.authService.getTokenSet().accessToken,
                            httpClient: new CustomHeaderHttpClient({
                                'OneGo-Tenant': tenant.id,
                            }),
                        } as IHttpConnectionOptions)
                        .withAutomaticReconnect({
                            nextRetryDelayInMilliseconds: (retryContext) => {
                                const retryDelays = [
                                    0, 1000, 2000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000,
                                ];
                                const retryDelay =
                                    retryContext.previousRetryCount < retryDelays.length
                                        ? retryDelays[retryContext.previousRetryCount]
                                        : null;
                                if (retryDelay !== null) {
                                    this.appState.socketStatus.value = {
                                        status: 'RECONNECTING',
                                        attempt: retryContext.previousRetryCount,
                                    };
                                } else {
                                    this.appState.socketStatus.value = {
                                        status: 'DISCONNECTED',
                                    };
                                }
                                return retryDelay;
                            },
                        })
                        .build()
                ),
                tap((con) => {
                    con.keepAliveIntervalInMilliseconds = 2500;
                    con.serverTimeoutInMilliseconds = 5000;
                })
            )
        );
    }
}
