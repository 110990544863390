import { Component, Input } from '@angular/core';
import { vshrink } from 'shared';

import { ControlBarService } from '../../../../services/control-bar.service';

@Component({
    selector: 'app-ml-side-control-header',
    templateUrl: './m-l-side-control-header.component.html',
    styleUrls: ['./m-l-side-control-header.component.scss'],
    animations: [vshrink()],
})
export class MLSideControlHeaderComponent {
    @Input() showTabs = true;
    @Input() title = '';
    @Input() showMenuButton = true;

    public constructor(private controlBarService: ControlBarService) {}

    toggleControlMenu() {
        this.controlBarService.toggleMenu();
    }
}
