import { HttpHeaders, HttpParams } from '@angular/common/http';
import { DateRange } from '@angular/material/datepicker';
import { OperatorFunction } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { IPaginatedTableResponse, ISortingModel, Pagination, TViewPortPolygon } from '../../models';
import { BaseRepositoryService } from './base-repository.service';

export interface SelectEntityFilter {
    type: 'SELECT';
    values: Array<string>;
}

export interface DateRangeEntityFilter {
    type: 'DATE_RANGE';
    range: DateRange<Date>;
}

export interface EntityFilters {
    [key: string]: SelectEntityFilter | DateRangeEntityFilter;
}

export abstract class PaginatedApiBaseService extends BaseRepositoryService {
    protected paginatedHttpGet<Entity, TableModel>(
        pagination: Pagination,
        filters: EntityFilters,
        sorting: Array<ISortingModel<TableModel>>,
        filterPreset?: string,
        viewport?: TViewPortPolygon,
        params?: HttpParams
    ): OperatorFunction<string, IPaginatedTableResponse<Entity>> {
        return mergeMap((url) => {
            const headers: HttpHeaders | { [header: string]: string | Array<string> } = {};
            let queryParams = params ?? new HttpParams();
            if (pagination) {
                headers['X-Pagination-Start'] = pagination.start.toString();
                headers['X-Pagination-Step'] = pagination.step.toString();
            }

            if (sorting && sorting.length)
                url +=
                    '?' +
                    sorting
                        .map(
                            (sort) =>
                                `sort=${encodeURIComponent(sort.property?.toString())}&dir=${
                                    sort.direction === 'ascending' ? 'asc' : 'desc'
                                }`
                        )
                        .join('&');

            if (viewport?.length) queryParams = queryParams.append('viewport', JSON.stringify(viewport));
            if (filterPreset) queryParams = queryParams.append('filterPreset', filterPreset);

            if (filters)
                Object.entries(filters).forEach(([property, filter]) => {
                    switch (filter.type) {
                        case 'SELECT':
                            filter.values.forEach((value) => {
                                queryParams = queryParams.append(property, value);
                            });
                            break;
                        case 'DATE_RANGE':
                            // Date range
                            queryParams = queryParams.append(
                                `${property}.from`,
                                Math.floor(filter.range.start.getTime() / 1000)
                            );
                            queryParams = queryParams.append(
                                `${property}.till`,
                                Math.floor(filter.range.end.getTime() / 1000)
                            );
                            break;
                    }
                });

            return this.http
                .get<Array<Entity>>(url, {
                    headers: new HttpHeaders(headers),
                    observe: 'response',
                    params: queryParams,
                })
                .pipe(
                    map((response) => ({
                        totalCount: parseInt(response.headers.get('X-Pagination-Total'), 10),
                        data: response.body,
                    }))
                );
        });
    }
}
