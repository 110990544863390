<div class="notification" *ngIf="notification$ | async as notification" @notification>
    <div class="icon-container">
        <i class="material-icons" *ngIf="notification.icon !== 'spinner'" @fade>{{ notification.icon }}</i>
        <ogs-spinner *ngIf="notification.icon === 'spinner'" @fade></ogs-spinner>
    </div>
    <div class="text-container">
        <span class="title" @vshrink *ngIf="notification.title | async as title">{{ title }}</span>
        <span class="subtitle" @vshrink *ngIf="notification.subtitle | async as subtitle">{{ subtitle }}</span>
    </div>
    <div class="action-container">
        <button class="btn-reconnect" *ngIf="notification.showReconnectButton" (click)="onReconnectPressed()" @hshrink>
            {{ 'comp.socket-overlay.reconnectButton' | translate }}
        </button>
    </div>
</div>
