<app-context-list>
    <div *ngFor="let action of actions" @listItem>
        <app-context-list-item
            [disabled]="action.disabled || (socketService.disableUI | async)"
            (click)="onClickAction(action, $event)"
            (keydown.enter)="onClickAction(action, $event)"
            appFocusable
            appFocusOnHover
        >
            <app-icon
                *ngIf="action"
                @hshrink
                [key]="action.icon ?? 'icon-clear'"
                [style]="action.iconStyle"
                class="icon"
            ></app-icon>
            {{ action.label | translate }}
        </app-context-list-item>
    </div>
</app-context-list>
