<div class="secondary-menu">
    <ng-container *ngFor="let smRoot of menu">
        <app-collapsible
            *ngIf="!getPermissions(smRoot)?.length || (getPermissions(smRoot) | hasPermissions : false | async)"
            [icon]="smRoot.icon"
            [title]="smRoot.title | translate"
            class="menu-item"
            [collapsed]="false"
        >
            <ng-container *ngFor="let smChild of smRoot.children">
                <div
                    class="sub-menu-item"
                    *ngIf="!smChild.permission?.length || (smChild.permission | hasPermissions : false | async)"
                    [routerLink]="getLink([smRoot.path, smChild.path])"
                >
                    <span>{{ smChild.title | translate }}</span>
                </div>
            </ng-container>
        </app-collapsible>
    </ng-container>
</div>
