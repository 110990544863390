import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Component({
    selector: 'app-tab',
    templateUrl: './tab.component.html',
    styleUrls: ['./tab.component.scss'],
})
export class TabComponent {
    @HostBinding('class.active')
    active = false;

    @Output()
    activate: EventEmitter<void> = new EventEmitter<void>();

    @Input() value: string;

    @HostListener('click')
    onClick(): void {
        if (!this.active) this.activate.emit();
    }
}
