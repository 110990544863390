import { Component, HostBinding, Input } from '@angular/core';
import { IssueStatus, TaskSerieStatus, TaskStatus, shrink } from 'shared';

import { EntityType } from '../../../../models/entity.model';

type TStatusMap = 'ISSUE_STATUS_MAP' | 'TASK_STATUS_MAP' | 'TASK_SERIES_STATUS_MAP';

@Component({
    selector: 'app-entity-status',
    templateUrl: './entity-status.component.html',
    styleUrls: ['./entity-status.component.scss'],
    animations: [shrink('text')],
})
export class EntityStatusComponent {
    @Input() status: IssueStatus | TaskStatus | TaskSerieStatus;
    @Input() entityType: EntityType;

    ISSUE_STATUS_MAP: { [status: string]: { name: string; color: string } } = {
        TODO: { name: 'comp.entity-status.issue.TODO', color: '#e5f4ff' },
        IN_PROGRESS: { name: 'comp.entity-status.issue.IN_PROGRESS', color: '#FFD38A' },
        READY: { name: 'comp.entity-status.issue.READY', color: '#bef7a3' },
        BLOCKED: { name: 'comp.entity-status.issue.BLOCKED', color: '#f5a9a2' },
        CLOSED: { name: 'comp.entity-status.issue.CLOSED', color: '#bef7a3' },
    };

    TASK_STATUS_MAP: { [status: string]: { name: string; color: string } } = {
        TODO: { name: 'comp.entity-status.task.TODO', color: '#e5f4ff' },
        IN_PROGRESS: { name: 'comp.entity-status.task.IN_PROGRESS', color: '#FFD38A' },
        DONE: { name: 'comp.entity-status.task.DONE', color: '#bef7a3' },
        BLOCKED: { name: 'comp.entity-status.task.BLOCKED', color: '#f5a9a2' },
        CANCELLED: { name: 'comp.entity-status.task.CANCELLED', color: '#f5a9a2' },
        INSPECTION_APPROVED: { name: 'comp.entity-status.task.INSPECTION_APPROVED', color: '#bef7a3' },
        INSPECTION_REJECTED: { name: 'comp.entity-status.task.INSPECTION_REJECTED', color: '#f5a9a2' },
        TO_BE_INSPECTED: { name: 'comp.entity-status.task.TO_BE_INSPECTED', color: '#FFD38A' },
    };

    TASK_SERIES_STATUS_MAP: { [status: string]: { name: string; color: string } } = {
        ACTIVE: { name: 'comp.entity-status.taskSeries.ACTIVE', color: '#bef7a3' },
        INACTIVE: { name: 'comp.entity-status.taskSeries.INACTIVE', color: '#FFD38A' },
    };

    @HostBinding('style.background-color') get statusColor() {
        const key = (this.entityType + '_STATUS_MAP') as TStatusMap;
        return this[key][this.status]?.color || '#fff';
    }

    get statusString() {
        const key = (this.entityType + '_STATUS_MAP') as TStatusMap;
        return this[key][this.status]?.name || '';
    }
}
