import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ITask } from 'shared';

import { SocketService } from '../socket.service';
import { TaskService } from '../task.service';
import { BaseSocketEventHandler } from './base-socket-event-handler';

interface TaskCreatedSocketEvent {
    eventDtoType: 'TaskCreated';
    task: ITask;
}

@Injectable({ providedIn: 'root' })
export class TaskCreatedSocketEventHandler extends BaseSocketEventHandler<TaskCreatedSocketEvent> {
    protected constructor(private taskService: TaskService, socketService: SocketService) {
        super(socketService);
    }

    protected eventDtoType(): string {
        return 'TaskCreated';
    }

    protected handleEvent = async (event: TaskCreatedSocketEvent): Promise<void> => {
        const tasks = await firstValueFrom(this.taskService.tasks);
        if (tasks.find((t) => t.id === event.task.id)) await this.taskService.localUpdate(event.task);
        else await this.taskService.localAdd(event.task);
    };
}
