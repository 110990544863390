import { Component, ElementRef, Input } from '@angular/core';
import { fadeUp, vshrink } from 'shared';

import { BasePopupComponent } from '../../../modals/base-popup/base-popup.component';
import { Action } from '../advanced-entity-actions.component';

@Component({
    selector: 'app-advanced-entity-actions-overflow-popup',
    templateUrl: './advanced-entity-actions-overflow-popup.component.html',
    styleUrls: ['./advanced-entity-actions-overflow-popup.component.scss'],
    animations: [fadeUp('popup'), vshrink('listItem'), vshrink('listHeader')],
})
export class AdvancedEntityActionsOverflowPopupComponent extends BasePopupComponent<AdvancedEntityActionsOverflowPopupComponent> {
    @Input() actions: Array<Action> = [];

    public constructor(elementRef: ElementRef) {
        super(elementRef);
        this.popupOptions.hasArrow = true;
    }

    onActionClick(action: Action, $event: MouseEvent) {
        action.onClick($event);
        this.__DOM_COMPONENT.remove();
    }
}
