import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appFocusOnHover]',
})
export class FocusOnHoverDirective {
    public constructor(private elementRef: ElementRef<HTMLElement>) {}

    get disabled(): boolean {
        return (
            (this.elementRef.nativeElement as any).disabled ||
            this.elementRef.nativeElement.classList.contains('disabled')
        );
    }

    @HostListener('mouseenter') onMouseEnter() {
        if (!this.disabled) this.elementRef.nativeElement.focus();
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.elementRef.nativeElement.blur();
    }

    @HostListener('focus') onFocus() {
        if (this.disabled) this.elementRef.nativeElement.blur();
    }
}
