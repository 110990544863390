import { AfterViewInit, Component, DestroyRef, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getDistance as geolib_getDistance } from 'geolib';
import { isEqual } from 'lodash';
import mapboxgl, { AnimationOptions, MapboxEvent, MapboxOptions } from 'mapbox-gl';
import { BehaviorSubject, Observable, Subject, combineLatest, fromEvent } from 'rxjs';
import { debounceTime, filter, pairwise, startWith, switchMap, take, tap } from 'rxjs/operators';
import { DEFAULT_ORDINAL, ILocation, IMapViewPort, IOrdinal, ITenantConfig } from 'shared';
import { v4 as uuid } from 'uuid';

import { environment } from '../../../../../environments/environment';
import { DerivedStateValue, StateValue } from '../../../../utils/state-value';
import { MapService } from '../../../services/map.service';
import { MapLayerController } from './layer-controllers/base-controllers/map-layer-controller';

interface MapComponentState {
    readonly ordinal: StateValue<IOrdinal>;
    readonly ordinals: DerivedStateValue<Array<IOrdinal>>;
    readonly map: DerivedStateValue<mapboxgl.Map | null>;
    readonly mapConfig: DerivedStateValue<ITenantConfig | null>;
    readonly layerControllers: DerivedStateValue<Array<MapLayerController>>;
    readonly ready: StateValue<boolean>;
}

export class MapMarkerClickEvent {
    public readonly marker: mapboxgl.Marker;
    public readonly controller: MapLayerController;

    public constructor(marker: mapboxgl.Marker, controller: MapLayerController) {
        this.marker = marker;
        this.controller = controller;
    }
}

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent implements AfterViewInit, OnDestroy {
    //
    // Internal state
    //

    private _markerClicks = new Subject<MapMarkerClickEvent>();
    private _map: BehaviorSubject<mapboxgl.Map | null> = new BehaviorSubject<mapboxgl.Map | null>(null);
    private _mapConfig: BehaviorSubject<ITenantConfig | null> = new BehaviorSubject<ITenantConfig | null>(null);
    private _layerControllers: BehaviorSubject<Array<MapLayerController>> = new BehaviorSubject<
        Array<MapLayerController>
    >([]);

    @ViewChild('mapContainer') private mapContainer?: ElementRef;

    //
    // Inputs, Getters and Setters
    //

    @Input()
    public set ordinal(ordinal: IOrdinal | null) {
        if (!ordinal || !this.state.ordinals.value.some((o) => o.id === ordinal.id)) return;
        this.state.ordinal.value = ordinal;
    }

    public get ordinal(): IOrdinal | null {
        return this.state.ordinal.value;
    }

    @Input()
    public set mapConfig(config: ITenantConfig | null) {
        this._mapConfig.next(config);
    }

    public get mapConfig(): ITenantConfig | null {
        return this._mapConfig.value;
    }

    @Input()
    public set layerControllers(controllers: Array<MapLayerController>) {
        this._layerControllers.next(controllers);
    }

    public get layerControllers(): Array<MapLayerController> {
        return this._layerControllers.value;
    }

    public get ready(): boolean {
        return this.state.ready.value;
    }

    public get markerClicks(): Observable<MapMarkerClickEvent> {
        return this._markerClicks.asObservable();
    }

    //
    // Public state
    //

    public readonly state: Readonly<MapComponentState>;

    //
    // Construction
    //

    public constructor(private destroyRef: DestroyRef, mapService: MapService) {
        this.state = {
            map: new DerivedStateValue(this._map.asObservable()),
            mapConfig: new DerivedStateValue(this._mapConfig.asObservable()),
            layerControllers: new DerivedStateValue(this._layerControllers.asObservable()),
            ready: new StateValue(false),
            ordinal: new StateValue<IOrdinal>(DEFAULT_ORDINAL),
            ordinals: mapService.ordinals,
        };

        // Ensure the set ordinal is always within the set of known ordinals
        combineLatest([this.state.ordinal.stream, this.state.ordinals.stream])
            .pipe(
                debounceTime(0),
                takeUntilDestroyed(this.destroyRef),
                tap(([ordinal, ordinals]) => {
                    if (!ordinals.find((o) => isEqual(o, ordinal))) {
                        this.state.ordinal.value = ordinals[0];
                    }
                })
            )
            .subscribe();
    }

    //
    // Public utilities
    //

    public async flyTo(
        location: ILocation,
        zoomLevel?: number,
        options?: { pan?: AnimationOptions; zoom?: AnimationOptions }
    ): Promise<void> {
        // Wait for the map to become available, and stop if it does not become available within 2 seconds
        if (!(await this.state.map.nextNonNullValue({ timeout: 2000 }).catch(() => null))) return;
        const zoomOptions = { animate: true, duration: 250, ...((options ? options.zoom : null) || {}) };
        const panOptions = { animate: true, duration: 500, ...((options ? options.pan : null) || {}) };
        const floorChange = this.state.ordinal.value?.id !== location.floor;
        const panning = geolib_getDistance(location, this.getCenterLocation(), 0.01) > 0.5;
        const zooming = zoomLevel && this.zoomLevel !== zoomLevel;
        if (floorChange) this.setOrdinalById(location.floor);
        if (panning) {
            const controller = this.state.map.value;
            if (controller) controller.panTo([location.longitude, location.latitude], panOptions);
        }
        if (zooming) {
            setTimeout(
                () => this.state.map.value?.zoomTo(zoomLevel - 1, zoomOptions),
                panning ? panOptions.duration : 0
            );
        }
    }

    public getCenterLocation(): ILocation {
        return {
            latitude: this.state.map.value?.getCenter().lat || 0,
            longitude: this.state.map.value?.getCenter().lng || 0,
            floor: this.state.ordinal.value?.id || 0,
        };
    }

    public get zoomLevel(): number {
        return this.state.map.value?.getZoom() || 0;
    }

    public event<T extends MapboxEvent<unknown>>(event: string): Observable<T> {
        return this.state.map.stream.pipe(
            filter(Boolean),
            switchMap((mapRef) => {
                return new Observable<T>((observer) => {
                    const onEvent = (eventData: T): void => observer.next(eventData);
                    mapRef.on(event, onEvent);
                    return {
                        unsubscribe: (): void => {
                            if (mapRef) mapRef.off(event, onEvent);
                        },
                    };
                });
            })
        );
    }

    public setOrdinalById(ordinalId: number | string): boolean {
        if (typeof ordinalId === 'string') ordinalId = parseInt(ordinalId, 10);
        const ordinals = this.state.ordinals.value;
        const ordinal = ordinals.find((o) => o.id === ordinalId);
        if (ordinal) this.state.ordinal.value = ordinal;
        return !!ordinal;
    }

    public zoomIn(): void {
        this.state.map.value?.zoomIn();
    }

    public zoomOut(): void {
        this.state.map.value?.zoomOut();
    }

    public getViewPort(): IMapViewPort {
        if (!this.state.map.value) throw new Error('Attempted to call `getViewPort` before the map became available.');
        const bounds = this.state.map.value.getBounds();
        return {
            a: [bounds.getNorthWest().lat, bounds.getNorthWest().lng],
            b: [bounds.getSouthEast().lat, bounds.getSouthEast().lng],
        };
    }

    public isInView(location: ILocation): boolean {
        if (!this.state.map.value) return false;
        return (
            location.floor === this.state.ordinal.value?.id &&
            this.state.map.value.getBounds().contains([location.longitude, location.latitude])
        );
    }

    public getLayerControllerByType<T extends MapLayerController>(controllerType: new () => T): T | undefined {
        return this.layerControllers.find((c) => c instanceof controllerType) as T | undefined;
    }

    public getLayerControllersByType<T extends MapLayerController>(controllerType: new () => T): T[] {
        return this.layerControllers.filter((c) => c instanceof controllerType) as T[];
    }

    public clickMarker(MapMarkerClickEvent: MapMarkerClickEvent): void {
        this._markerClicks.next(MapMarkerClickEvent);
    }

    //
    // Lifecycle Hooks
    //

    public ngAfterViewInit(): void {
        // Process layer controller changes when the map changes
        this._map
            .pipe(filter(Boolean), startWith(null), pairwise(), takeUntilDestroyed(this.destroyRef))
            .subscribe(([previousMap, currentMap]) => {
                if (previousMap) {
                    this._layerControllers.value.forEach((controller) => controller.removeController());
                }
                if (currentMap) {
                    this._layerControllers.value.forEach((controller) => controller.addController(this, currentMap));
                }
            });
        // Process layer controller changes when the layer controllers change
        this._layerControllers
            .pipe(takeUntilDestroyed(this.destroyRef), pairwise())
            .subscribe(([previousLayerControllers, currentLayerControllers]) => {
                const removedLayerControllers = previousLayerControllers.filter((previousLayerController) => {
                    return !currentLayerControllers.some(
                        (currentLayerController) => currentLayerController === previousLayerController
                    );
                });
                const addedLayerControllers = currentLayerControllers.filter((currentLayerController) => {
                    return !previousLayerControllers.some(
                        (previousController) => currentLayerController === previousController
                    );
                });
                if (this._map.value) {
                    removedLayerControllers.forEach((layerController) => layerController.removeController());
                    addedLayerControllers.forEach((layerController) =>
                        layerController.addController(this, this._map.value)
                    );
                }
            });

        // Initialize map
        this.initializeMap();
    }

    public ngOnDestroy(): void {
        this._layerControllers.value.forEach((controller) => controller.removeController());
    }

    //
    // Internal Logic
    //

    private initializeMap(): void {
        const containerId = 'map-container-' + uuid();
        this.mapContainer.nativeElement.id = containerId;
        this._mapConfig
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                filter(Boolean),
                tap((config) => {
                    const mapboxOptions: MapboxOptions = {
                        container: containerId,
                        center: [config.centerCoordinates.longitude, config.centerCoordinates.latitude],
                        zoom: config.zoom,
                        minZoom: config.zoomBoundaries.min,
                        maxZoom: config.zoomBoundaries.max,
                        style: { version: 8, sources: {}, layers: [] },
                    };
                    if (config.mapBox?.token) {
                        mapboxgl.accessToken = config.mapBox.token;
                        mapboxOptions.style = config.mapBox.style ?? environment.mapBoxDefaults.style;
                    }
                    const map = new mapboxgl.Map(mapboxOptions);
                    fromEvent(map, 'load')
                        .pipe(
                            takeUntilDestroyed(this.destroyRef),
                            take(1),
                            tap(() => this._map.next(map)),
                            tap(() => map.resize())
                        )
                        .subscribe();
                })
            )
            .subscribe();
    }
}
