<div class="entity-filter" [ngClass]="{ light: isLight }" (clickOutside)="showDropDown = false">
    <button class="entity-filter-toggle" (click)="showDropDown = !showDropDown">
        <div class="toggle-label title" [class.selected]="checkedList.length">
            <span>{{ placeholder }}</span>
        </div>
        <div class="toggle-label counter">
            <span class="circle-border">{{ checkedList.length }}</span>
        </div>
    </button>
    <div class="entity-filter-dropdown" *ngIf="showDropDown" @fadeUp>
        <div class="entity-list-filter">
            <input
                type="text"
                [(ngModel)]="dropDownFilter"
                [placeholder]="'comp.entity-filter.searchPlaceholder' | translate"
            />
        </div>
        <div class="entity-list">
            <label
                *ngFor="
                    let item of dropDownList | fuzzySearch : ['label'] : dropDownFilter : { translate: true };
                    trackBy: trackByOption
                "
                class="option"
                [class.disabled]="disableNew && !item.checked"
                (click)="onOptionClick(disableNew && !item.checked)"
            >
                <app-checkbox
                    [disabled]="disableNew && !item.checked"
                    [checked]="item.checked"
                    (checkedChange)="onChange($event, item)"
                ></app-checkbox>
                <span>{{ item.label | translate }}</span>
            </label>
            <span class="option" *ngIf="dropDownList.length === 0">
                {{ 'comp.entity-filter.emptyList' | translate }}
            </span>
        </div>
        <button class="btn btn-flat btn-small btn-clear-filter" [disabled]="!checkedList.length" (click)="resetInput()">
            {{ 'comp.entity-filter.clearFilter' | translate }}
        </button>
    </div>
</div>
