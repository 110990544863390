import { Component, DestroyRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { IComment, triggerChildren, vshrink } from 'shared';

import { Entity, EntityType } from '../../../../models/entity.model';
import { CommentService } from '../../../services/comment.service';
import { TenantService } from '../../../services/tenant.service';
import { ISelectedImage, ImageSelectorComponent } from '../image-selector/image-selector.component';

@Component({
    selector: 'app-comment-pane',
    templateUrl: './comment-pane.component.html',
    styleUrls: ['./comment-pane.component.scss'],
    animations: [vshrink(), triggerChildren()],
})
export class CommentPaneComponent implements OnChanges {
    @ViewChild('imageUpload')
    private imageSelectorComponent: ImageSelectorComponent;

    @Input()
    entity: Entity;

    @Input()
    entityType: EntityType;

    comments: Array<IComment> = [];
    textInput: string;
    images: Array<ISelectedImage>;
    totalRequestSize: number;
    maxRequestSize: number;
    progress: number = null;

    public constructor(
        private readonly commentService: CommentService,
        private readonly tenantService: TenantService,
        private readonly destroyRef: DestroyRef
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.entity?.currentValue && this.entityType === 'TASK') {
            this.listComments().subscribe();
            this.getMaximumBodySize();
        }
    }

    public addComment(): void {
        this.progress = 0;
        this.commentService
            .createComment(this.entityType, this.entity.id, this.textInput, this.images)
            .pipe(
                tap((res) => {
                    if (res.status === 'progress') {
                        this.progress = res.message;
                    }
                }),
                filter((res) => res.status === 'completed'),
                switchMap(() => this.listComments()),
                tap(() => {
                    this.textInput = null;
                    this.images = null;
                    this.progress = null;
                    this.imageSelectorComponent.reset();
                }),
                catchError(() => {
                    this.progress = null;
                    return of(null);
                }),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe();
    }

    public setImages(images: Array<ISelectedImage>): void {
        this.images = images;
        this.totalRequestSize = images.map((i) => i.file.size).reduce((prev, curr) => prev + curr, 0) + 4096;
    }

    public listComments(): Observable<Array<IComment>> {
        return this.commentService
            .listComments(this.entityType, this.entity.id)
            .pipe(tap((comments) => (this.comments = comments)));
    }

    public trackCommentBy(index: number, comment: IComment) {
        return comment.id;
    }

    private getMaximumBodySize(): void {
        this.tenantService.config
            .pipe(
                filter((config) => !!config),
                take(1),
                map((config) => config.taskApi.maxFileSizeMb * 1024 * 1024)
            )
            .subscribe((i) => (this.maxRequestSize = i));
    }
}
