<div class="filter-set-title">
    <h1 class="title-filter">{{ 'comp.filter-select-popup.title' | translate }}</h1>
</div>
<app-context-list>
    <input
        #searchInputEl
        (keydown.enter)="onSearchEnterDown()"
        [placeholder]="'comp.filter-select-popup.search' | translate"
        appFocusable
        class="no-margin"
        tabindex="1"
        type="text"
    />
    <div *ngFor="let value of searchResultEntities; trackBy: trackById" @listItem>
        <app-context-list-item
            (click)="popupResult.emit(value)"
            (keydown.enter)="popupResult.emit(value)"
            [selected]="selectedId === value.id"
            appFocusOnHover
            appFocusable
            class="list-item"
        >
            {{ value.displayName }}
            <div class="dropdown">
                <button (click)="toggleDropdown(value.id, $event)" class="list-item-button">
                    <app-icon class="icon" icon="more_horiz" [style]="'outlined'"></app-icon>
                </button>
            </div>
        </app-context-list-item>
    </div>
    <div *ngIf="!searchResultEntities?.length" @listItem>
        <app-context-list-item class="disabled no-results">
            {{ 'comp.filter-select-popup.noResults' | translate }}
        </app-context-list-item>
    </div>
</app-context-list>
<div class="add-preset-container" *ngIf="'Tasks.CreateFilterPresets' | hasPermissions | async">
    <app-context-list-item
        [disabled]="noFilters"
        (click)="addNewFilterPreset($event)"
        appFocusOnHover
        class="add-preset-button"
    >
        <app-icon class="icon" icon="add" [style]="'outlined'"></app-icon>
        {{ 'comp.filter-select-popup.addNewFilterSet' | translate }}
    </app-context-list-item>
</div>
