import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AccessTokenInterceptor } from './services/interceptors/access-token-interceptor';

@NgModule({ declarations: [],
    exports: [], imports: [], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AccessTokenInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class BaseflowAuthModule {}
