import { Component, DestroyRef, DoCheck, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ITask, ITaskType, fade, hshrink, vshrink } from 'shared';

import { EntityAction } from '../../../../models/entity-action.model';
import { Feature } from '../../../models/feature.model';
import { ControlBarService } from '../../../services/control-bar.service';
import { DomService } from '../../../services/dom.service';
import { LocationService } from '../../../services/location.service';
import { SocketService } from '../../../services/socket.service';
import { TaskService } from '../../../services/task.service';
import { UserService } from '../../../services/user.service';

// eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
@Component({
    selector: 'app-task-list-item',
    templateUrl: './task-list-item.component.html',
    styleUrls: ['./task-list-item.component.scss'],
    animations: [fade(), vshrink(), hshrink()],
})
export class TaskListItemComponent implements OnInit, DoCheck, OnChanges {
    @Input() protected task: ITask;
    @Input() protected taskType: ITaskType;
    @Input() protected selected = false;
    @Input() protected expanded = false;
    @Input() protected clickable = true;
    @Input() protected showActions = true;
    @Input() protected showOverflowActions = true;
    @Input() private customActions: Array<EntityAction>;

    @Input()
    private set showDetails(value: boolean) {
        this.showDetails$.next(value);
    }

    @Input() protected title?: string;
    @Input() protected subtitle?: string;
    @Input() protected hideSummary = false;
    private showDetails$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    protected showDetailsComputed$: Observable<boolean>;
    private assigneeId?: BehaviorSubject<string> = new BehaviorSubject(null);
    protected assigneeName$: Observable<string>;
    protected advancedEntityActionsCollapseAfter$: Observable<number>;
    protected actions: Array<EntityAction> = [];
    public ready = false;
    @Input() protected disableOnSocketDisconnect: boolean;
    protected simpleView = false;

    protected get descriptionSummary(): string {
        const description = this.task?.description;
        if (!description) return null;
        let summary = this.task?.description?.slice(0, 15);
        if (summary.length !== description.length) summary += '...';
        return summary;
    }

    public constructor(
        private readonly taskService: TaskService,
        private readonly socketService: SocketService,
        private readonly locationService: LocationService,
        private readonly controlBarService: ControlBarService,
        private readonly domService: DomService,
        private readonly elementRef: ElementRef,
        private readonly userService: UserService,
        private readonly translate: TranslateService,
        private readonly destroyRef: DestroyRef
    ) {}

    public ngOnInit(): void {
        this.advancedEntityActionsCollapseAfter$ = this.controlBarService.controlRoute.pipe(
            map((route) => (route && route.mode === 'LIST' ? 2 : 0))
        );
        this.showDetailsComputed$ = combineLatest([
            this.showDetails$,
            this.controlBarService.controlRoute.pipe(map((route) => route && route.mode !== 'DETAIL')),
        ]).pipe(map((values) => values.findIndex((v) => !v) === -1));
        this.assigneeName$ = this.assigneeId.pipe(
            switchMap((id) => this.userService.getUser(id)),
            map((user) => (!user ? null : this.userService.getUserShortName(user)))
        );
        this.taskService.tasks
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(
                async () => (this.actions = this.customActions || (await this.taskService.buildActions(this.task)))
            );
        this.userService
            .hasFeature(Feature.SimpleListItem)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((simpleView) => (this.simpleView = simpleView));
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.task && changes.task.previousValue !== changes.task.currentValue) {
            this.actions = this.customActions || (await this.taskService.buildActions(this.task));
            setTimeout(() => (this.ready = true), 0);
        }
    }

    public async ngDoCheck(): Promise<void> {
        // Assignee changes
        const newAssigneeId = this.task && this.task.assigneeId;
        const currentAssigneeId = this.assigneeId.value;
        if (currentAssigneeId !== newAssigneeId) this.assigneeId.next(newAssigneeId || null);
    }

    protected goToDetails($event: MouseEvent): void {
        $event.stopImmediatePropagation();
        this.controlBarService.pushRoute({ mode: 'DETAIL', entityType: 'TASK', entityId: this.task.id });
    }
}
