<div class="inline-text-field">
    <input
        type="text"
        readOnly
        (focus)="toggle()"
        [placeholder]="_placeholder ?? ''"
        [disabled]="isDisabled"
        *ngIf="!isEditable && !this.isAnimating"
        [ngModel]="value"
        [title]="value ?? _placeholder ?? ''"
    />
    <textarea
        #textInput
        class="textarea"
        [ngModel]="value"
        (input)="this.onChange(textInput.value)"
        [placeholder]="_placeholder ?? ''"
        [readOnly]="isAnimating"
        (blur)="onBlur()"
        [disabled]="isDisabled"
        [@grow]="{
            value: isEditable ? 'show' : 'hide',
            params: { shownHeight: getShownHeight() }
        }"
        (@grow.start)="startAnimation()"
        (@grow.done)="doneAnimation()"
        *ngIf="isEditable"
        [maxlength]="maxLength"
        >{{ value }}</textarea
    >
</div>
