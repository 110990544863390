import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CheckboxComponent,
            multi: true,
        },
    ],
})
export class CheckboxComponent implements ControlValueAccessor {
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (value: any) => void = noop;
    @Input() highlighted = false;
    @Input() disabled = false;
    @Input() checked = false;
    @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    onCheckChange(value: boolean) {
        const change = value !== this.checked;
        this.checked = value;
        if (change) {
            this.checkedChange.emit(value);
            this.onChangeCallback(value);
        }
    }

    onLabelClick($event: MouseEvent) {
        if (!this.disabled) {
            $event.stopImmediatePropagation();
        }
    }

    onBlur() {
        this.onTouchedCallback();
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: boolean): void {
        this.checked = value;
    }
}
