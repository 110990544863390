import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'app-context-list-item',
    templateUrl: './context-list-item.component.html',
    styleUrls: ['./context-list-item.component.scss'],
})
export class ContextListItemComponent {
    @HostBinding('class.disabled') @Input() disabled = false;
    @HostBinding('class.selected') @Input() selected = false;
}
