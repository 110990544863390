import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { fadeDown } from 'shared';

import { GeolocatorService } from '../../../services/geolocator.service';
import { LocationService } from '../../../services/location.service';
import {
    LocationSelectListComponent,
    LocationSelectResult,
} from './location-select-list/location-select-list.component';

@Component({
    selector: 'app-location-select',
    templateUrl: './location-select.component.html',
    styleUrls: ['./location-select.component.scss'],
    animations: [fadeDown('context')],
})
export class LocationSelectComponent implements OnInit {
    protected open = false;
    @ViewChild('selectEl') private selectEl: ElementRef;
    @ViewChild('dropdownEl') private dropdownEl: ElementRef;
    @ViewChild('contextEl') private contextEl: LocationSelectListComponent;
    @Output() private focusResult: EventEmitter<LocationSelectResult> = new EventEmitter<LocationSelectResult>();

    @Input() public result: LocationSelectResult;
    @Output() private resultChange: EventEmitter<LocationSelectResult> = new EventEmitter<LocationSelectResult>();

    @HostBinding('attr.tabindex')
    private get tabindex(): number {
        return this.disabled ? -1 : 0;
    }

    private get disabled(): boolean {
        return (
            (this.hostEl.nativeElement as HTMLInputElement).disabled ||
            this.hostEl.nativeElement.classList.contains('disabled')
        );
    }

    public constructor(
        private readonly hostEl: ElementRef,
        private readonly locationService: LocationService,
        private readonly translate: TranslateService,
        private readonly geolocatorService: GeolocatorService
    ) {}

    public async ngOnInit(): Promise<void> {
        // If current location is known, make it the default
        const location = this.geolocatorService.lastKnownLocation;
        if (location) {
            this.onSelectResult({
                type: 'CURRENT_LOCATION',
                location,
            });
        }
    }

    protected onFocusResult(result: LocationSelectResult): void {
        this.focusResult.emit(result);
    }

    protected onSelectResult(result: LocationSelectResult): void {
        this.result = result;
        this.resultChange.emit(result);
        this.open = false;
    }

    protected get locationDescription(): Observable<string> {
        if (!this.result || !this.result.location) return of('comp.location-select.placeholder');
        switch (this.result.type) {
            case 'POI':
                return of(this.result.poi.description);
            case 'EXACT':
            case 'CURRENT_LOCATION':
                return this.locationService.getLocationDescription(this.result.location);
        }
    }

    @HostListener('document:click', ['$event.target'])
    private onClick(targetElement?: HTMLElement): void {
        let newState = false;
        if (!targetElement || this.selectEl.nativeElement.contains(targetElement)) newState = !this.open;
        else if (this.dropdownEl.nativeElement.contains(targetElement)) return;
        // Autofocus search
        if (newState && !this.open) {
            setTimeout(() => {
                if (!this.contextEl) return;
                this.contextEl.searchInputEl.nativeElement.focus();
            }, 0);
        }
        // Set state
        this.open = newState;
    }

    @HostListener('blur')
    private onBlur(): void {
        setTimeout(() => {
            if (!this.hostEl.nativeElement.contains(document.activeElement)) this.open = false;
        }, 0);
    }

    @HostListener('document:keydown.enter')
    private onEnterDown(): void {
        if (this.hostEl.nativeElement === document.activeElement) {
            this.onClick();
        }
    }

    @HostListener('document:keydown.escape')
    private onEscapeDown(): void {
        this.open = false;
    }
}
