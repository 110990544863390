<div class="bf-input with-icon">
    <div class="pseudo-select" #selectEl>
        <div #contentRef class="select-content"><ng-content></ng-content></div>
        <div class="select-content" *ngIf="!contentRef.childNodes.length">
            <span class="select-content-text">{{ locationDescription | async | translate }}</span>
            <span class="select-content-supertext" *ngIf="result?.type === 'CURRENT_LOCATION'">
                {{ 'comp.location-select.currentLocation' | translate }}
            </span>
        </div>
    </div>
    <i class="caret"></i>
    <i class="material-icons">
        {{ result?.type === 'CURRENT_LOCATION' ? 'my_location' : 'map' }}
    </i>
</div>
<div class="dropdown-content" #dropdownEl>
    <div *ngIf="open" @context>
        <app-location-select-list
            #contextEl
            (focusResult)="onFocusResult($event)"
            (selectResult)="onSelectResult($event)"
        ></app-location-select-list>
    </div>
</div>
