import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ITableComponentColumn } from '../../util/table/interfaces/table-component-column.interface';

@Component({ template: '' })
export abstract class BaseFilterInputComponent<TModel> {
    @Input()
    placeholder: string;

    @Input('light')
    isLight: boolean;

    @Input()
    disableNew = false;

    @Input()
    set header(header: ITableComponentColumn<any>) {
        header.filterInput = this;
    }

    @Output()
    selectedChange: EventEmitter<TModel> = new EventEmitter<TModel>();

    showDropDown = false;

    abstract resetInput(): void;
}
