/* eslint-disable max-len */
export const SVG_MARKER_PIN = `
<?xml version="1.0" encoding="UTF-8"?>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 27 37">
   <defs>
      <path id="a" d="m13.5 37c9-9.1685 13.5-16.51 13.5-22.024 0-8.2711-6.0442-14.976-13.5-14.976-7.4558 0-13.5 6.7051-13.5 14.976 0 5.5141 4.5 12.855 13.5 22.024z" />
      <filter id="b" x="-18.5%" y="-16.2%" width="151.9%" height="137.8%">
         <feOffset dx="2" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
         <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
         <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.068864729 0" />
      </filter>
   </defs>
   <g fill="none" fill-rule="evenodd">
      <use fill="black" filter="url(#b)" xlink:href="#a" />
      <use fill="#24D593" class="mtp-marker-icon-fill" fill-rule="evenodd" xlink:href="#a" />
   </g>
</svg>
`;

export const SVG_MARKER_POI_DOT = `
<?xml version="1.0" encoding="UTF-8"?>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 7 7">
  <circle cx="3.5" cy="3.5" r="3.5" fill="#24D593" class="mtp-marker-icon-fill" />
</svg>
`;

export const SVG_MARKER_POI = `
<?xml version="1.0" encoding="UTF-8"?>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 32 32">
  <circle cx="16" cy="16" r="16" fill="#24D593" class="mtp-marker-icon-fill" />
</svg>
`;
