<div class="popup-arrow"></div>
<app-context-list>
    <input
        tabindex="1"
        type="text"
        class="no-margin"
        placeholder="Zoeken..."
        #searchInputEl
        appFocusable
        (keydown.enter)="onSearchEnterDown()"
    />
    <div @listItem>
        <app-context-list-item
            class="with-divider"
            (click)="selectUser(null)"
            (keydown.enter)="selectUser(null)"
            appFocusable
            appFocusOnHover
        >
            <span class="material-icons">remove_circle_outline</span>
            {{ 'bulkPlanning.taskTable.row.unassign' | translate }}
        </app-context-list-item>
    </div>
    <ng-container *ngIf="searchResultEntities?.length">
        <div *ngFor="let user of searchResultEntities; trackBy: trackById" @listItem>
            <app-context-list-item
                (click)="selectUser(user)"
                (keydown.enter)="selectUser(user)"
                [disabled]="(userFilter(user) | async) === false"
                appFocusable
                appFocusOnHover
            >
                {{ user.fullName }}
            </app-context-list-item>
        </div>
    </ng-container>
    <div @listItem *ngIf="searchInputEl.value && !searchResultEntities?.length">
        <app-context-list-item class="disabled no-results">No Results</app-context-list-item>
    </div>
</app-context-list>
