import mapboxgl from 'mapbox-gl';
import { Subject } from 'rxjs';

import { MapComponent } from '../../map.component';

const developerHint = `This can occur for various reasons. Please check the following:
1. You are not calling MapLayerController.addController or MapLayerController.removeController directly. This should only ever be done by the map component itself.
2. You are not using the same MapLayerController instance in multiple map components. Each map component should have its own instance of a MapLayerController.
3. You are not using the same MapLayerController instance multiple times in a single map component. Each MapLayerController instance should only be used once in a map component.
`;

export abstract class MapLayerController {
    protected component?: MapComponent;
    protected map?: mapboxgl.Map;

    protected readonly cleanUp$ = new Subject<void>();

    public addController(component: MapComponent, map: mapboxgl.Map): void {
        if (this.component || this.map) {
            throw new Error(
                'MapLayerController.addController called before MapLayerController.removeController.\n\n' +
                    developerHint
            );
        }

        // Store references to the component and map for easier access
        this.component = component;
        this.map = map;
        this.onAddController();
    }

    public removeController(): void {
        this.onRemoveController();

        this.cleanUp$.next();

        // Clean up references to the component and map
        this.component = undefined;
        this.map = undefined;
    }

    /**
     * Gets the layer ID of the bottom-most MapBoxGL layer provided by this controller, or the next controller in line.
     * Should be overridden by the controller implementation if it creates MapBoxGL layers.
     */
    public getBottomLayerId(): string | undefined {
        return this.getBottomLayerIdForNextController();
    }

    /**
     * Gets the layer ID of the bottom-most MapBoxGL layer belonging to the next defined controller on the current map component, if:
     *
     * 1. There is another controller defined after this one in the layerControllers array of the current map component.
     * 2. That controller reports the bottom-most layer ID by implementing `getBottomLayerId`.
     * Otherwise, it returns undefined.
     */
    public getBottomLayerIdForNextController(): string | undefined {
        if (!this.component) return undefined;
        const allControllers = this.component.state.layerControllers.value ?? [];
        const index = allControllers.indexOf(this);
        if (index === -1 || index >= allControllers.length - 1) return undefined;
        const nextController = allControllers[index + 1];
        return nextController.getBottomLayerId();
    }

    /**
     * Called when the controller is added to a map.
     * It is guaranteed that onRemoveController will be called before onAddController is called again.
     */
    protected abstract onAddController(): void;

    /**
     * Called when the controller is removed from a map.
     * It is guaranteed that onRemoveController will be called before onAddController is called again.
     */
    protected abstract onRemoveController(): void;
}
