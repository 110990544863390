<div class="wrapper" *ngIf="config && config.enabled && stateValues" [class.collapsed]="!config.expanded">
    <h2 class="title" (click)="toggleMonitor()">
        AppState monitor
        <i class="material-icons" [class.up]="!config.expanded">keyboard_arrow_down</i>
    </h2>
    <div class="values">
        <div *ngFor="let item of stateValues">
            <h3 class="row" (click)="toggleStateValue(item)">
                {{ item.key }}
                <i class="material-icons" [class.up]="!item.expanded">keyboard_arrow_down</i>
            </h3>
            <code [class.collapsed]="!item.expanded">{{ item.value.value | json }}</code>
        </div>
    </div>
</div>
