import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SecondaryMenu, SecondaryMenuRoot } from './secondary-menu.interface';

@Component({
    selector: 'app-secondary-menu',
    templateUrl: './secondary-menu.component.html',
    styleUrls: ['./secondary-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecondaryMenuComponent {
    @Input()
    menu: SecondaryMenu;

    getLink(paths: Array<string>) {
        return paths.filter((path) => !!path).map((path) => path.trim());
    }

    getPermissions(item: SecondaryMenuRoot): Array<string> {
        // Return an array containing the parent and all child permissions.
        return [item.permission, ...(item.children ?? []).map((c) => c.permission)].filter(Boolean);
    }
}
