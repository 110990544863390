import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { ITenant, TENANT_KEY } from 'baseflow-auth';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { filter, map, shareReplay, skip, switchMap } from 'rxjs/operators';
import {
    INamedSubjectType,
    ISubjectType,
    ITaskApiConfig,
    ITenantConfig,
    ITenantConfigPUT,
    IUser,
    IUserApiConfig,
    TaskApiService,
    TenantApiService,
    UserApiService,
} from 'shared';

import { LazyTranslateLoader } from '../../utils/lazy-translate-loader';
import { AppStateService } from './state/app-state.service';

export interface ITenantApiConfig {
    taskApi: ITaskApiConfig;
    userApi: IUserApiConfig;
}

@Injectable({ providedIn: 'root' })
export class TenantService {
    private readonly _config: BehaviorSubject<ITenantApiConfig> = new BehaviorSubject<ITenantApiConfig>(null);

    private readonly _subjectTypes: BehaviorSubject<Array<INamedSubjectType>> = new BehaviorSubject<
        Array<INamedSubjectType>
    >([]);

    public readonly tenantConfig: Observable<ITenantConfig>;

    public readonly config = this._config.pipe(filter((config) => !!config));
    public readonly subjectTypes: Observable<Array<INamedSubjectType>> = this._subjectTypes.asObservable();

    public constructor(
        private readonly translate: TranslateService,
        private readonly taskApi: TaskApiService,
        private readonly userApi: UserApiService,
        private readonly storage: StorageMap,
        private readonly appState: AppStateService,
        private readonly tenantApi: TenantApiService
    ) {
        this.appState.loggedInWithTenant.stream.subscribe((loggedIn) => {
            if (loggedIn) this.refreshConfig();
            else this._config.next(null);
        });

        // Refresh everything on socket connect
        this.appState.socketStatus.stream
            .pipe(filter((s) => s.status === 'CONNECTED'))
            .subscribe((_) => this.refreshSubjectTypes());

        // Save selected tenant on change
        this.appState.activeTenant.stream
            .pipe(
                skip(1),
                switchMap((tenant) => this.storage.set(TENANT_KEY, tenant?.id))
            )
            .subscribe();

        this.tenantConfig = this.appState.activeTenant.stream.pipe(
            switchMap((tenant) => this.tenantApi.getConfig(tenant.id)),
            shareReplay()
        );
    }

    public async refreshConfig(): Promise<void> {
        const [taskApiConfig, userApiConfig] = await Promise.all([
            firstValueFrom(this.taskApi.getConfig()),
            firstValueFrom(this.userApi.getConfig()),
        ]);
        this._config.next({
            taskApi: taskApiConfig,
            userApi: userApiConfig,
        });
    }

    public async updateSubjectTypes(newTypes: Array<ISubjectType>): Promise<void> {
        const newNamedTypes = newTypes.map((type) => {
            this.applyTranslationsForSubjectType(type);
            return TenantService.mapSubjectTypeToNamedType(type);
        });
        const newIds = newTypes.map((type) => type.id);
        this._subjectTypes.next([
            ...this._subjectTypes.value.filter((type) => !newIds.includes(type.id)),
            ...newNamedTypes,
        ]);
    }

    public async refreshSubjectTypes(): Promise<Array<ISubjectType>> {
        // Get subject types
        const config: ITenantApiConfig = await firstValueFrom(this.config);
        const subjectTypes = config.taskApi.subjectTypes;
        // Wait for base translations to have loaded
        await firstValueFrom((this.translate.currentLoader as LazyTranslateLoader).translationsLoaded$);
        // Extract translations and append them
        subjectTypes.forEach((type) => this.applyTranslationsForSubjectType(type));
        // Map to named type
        const namedSubjectTypes = subjectTypes.map(TenantService.mapSubjectTypeToNamedType);
        // Publish subject types and return them
        this._subjectTypes.next(namedSubjectTypes);
        return subjectTypes;
    }

    public getSubjectTypeForId(id: string): Observable<INamedSubjectType> {
        return this.subjectTypes.pipe(map((its) => its.find((it) => it.id === id)));
    }

    public getSubjectTypeForIdSync(id: string): INamedSubjectType {
        return this._subjectTypes.value.find((it) => it.id === id);
    }

    public selectTenant(user: IUser, tenant: ITenant): ITenant {
        if (
            // Allow setting null tenant for anyone
            !tenant ||
            // Allow setting tenant for users that are part of that tenant
            (user && user.tenants.find((t) => t.id === tenant.id))
        ) {
            this.appState.activeTenantId.value = tenant?.id ?? null;
        }

        return tenant;
    }

    public updateConfig(config: ITenantConfigPUT): Observable<ITenantConfig> {
        const tenantId = this.appState.activeTenant.value?.id;
        if (!tenantId) throw new Error('Attempted updating a tenant config without an active tenant.');
        return this.tenantApi.updateConfig(config, tenantId);
    }

    private applyTranslationsForSubjectType(type: ISubjectType): void {
        if (type.name && typeof type.name !== 'object') return;
        Object.entries(type.name).forEach(([lang, name]) => {
            this.translate.setTranslation(lang, { subjectType: { [type.id]: name } }, true);
        });
    }

    private static mapSubjectTypeToNamedType(type: ISubjectType): INamedSubjectType {
        return {
            id: type.id,
            name: typeof type.name === 'object' ? `subjectType.${type.id}` : type.name,
            iconId: type.iconId,
            archived: type.archived,
        };
    }
}
