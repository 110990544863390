import { Component, Input, ViewEncapsulation } from '@angular/core';
import { orderBy } from 'lodash';
import { AuditLogEntry } from 'shared';

@Component({
    selector: 'app-audit-log',
    templateUrl: './audit-log.component.html',
    styleUrls: ['./audit-log.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AuditLogComponent {
    private _log: Array<AuditLogEntry> = [];

    @Input()
    protected set log(value: Array<AuditLogEntry> | null) {
        value ??= [];
        value = [...value].reverse();
        this._log = orderBy(value || [], ['timestamp'], ['desc']);
    }

    protected get log(): Array<AuditLogEntry> {
        return this._log;
    }

    protected trackLogEntryBy(index: number, entry: AuditLogEntry): string {
        return entry.id;
    }
}
