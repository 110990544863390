<div class="popup-arrow"></div>
<app-context-list>
    <app-context-list-header *ngIf="header">{{ header | translate }}</app-context-list-header>
    <button
        class="action action-primary"
        *ngFor="let action of actions"
        [disabled]="isDisabled(action) | async"
        (click)="action.onClick($event, __DOM_COMPONENT)"
    >
        <app-icon class="icon" [key]="action.icon" [style]="'outlined'"></app-icon>
        <span>{{ action.label | translate : { count: selectionCount } }}</span>
    </button>
</app-context-list>
