<app-location-select #selector (resultChange)="onSelect($event)">
    <div class="textcol" *ngIf="getLocation(selector.result) as location; else noLocation">
        <span class="poi-name" *ngIf="(location | locationDescription | async) || location?.floor">
            {{ location | locationDescription | async }}
            <b>&nbsp;·&nbsp;L{{ location?.floor }}</b>
        </span>
    </div>
    <ng-template #noLocation>
        <div class="textcol">
            <div class="no-location">{{ 'mapView.searchBar.noLocation' | translate }}</div>
        </div>
    </ng-template>
</app-location-select>
