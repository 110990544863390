import { Injectable } from '@angular/core';
import { getDistance as geolib_getDistance } from 'geolib';
import { sortBy } from 'lodash';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ILocation, IPOI, IPOICategory, IPOIPATCH, IPOIPOST, POIFilter, TaskApiService } from 'shared';

import { EntityAction } from '../../models/entity-action.model';
import { AppStateService } from './state/app-state.service';
import { TenantService } from './tenant.service';

@Injectable({ providedIn: 'root' })
export class POIService {
    private readonly _pois: BehaviorSubject<Array<IPOI>> = new BehaviorSubject<Array<IPOI>>([]);

    private readonly _poiCategories: BehaviorSubject<Array<IPOICategory>> = new BehaviorSubject<Array<IPOICategory>>(
        []
    );

    public readonly pois: Observable<Array<IPOI>> = this._pois.asObservable();
    public readonly poiCategories: Observable<Array<IPOICategory>> = this._poiCategories.asObservable();

    public constructor(
        private readonly tasksApi: TaskApiService,
        private readonly tenantService: TenantService,
        private readonly appState: AppStateService
    ) {
        this.appState.loggedInWithTenant.stream
            .pipe(
                switchMap(async (loggedInWithTenant) => {
                    if (loggedInWithTenant) await Promise.all([this.refreshPOICategories(), this.refreshPOIs()]);
                    else {
                        this._pois.next([]);
                        this._poiCategories.next([]);
                    }
                })
            )
            .subscribe();
    }

    public async refreshPOIs(): Promise<void> {
        const pois = await firstValueFrom(this.tasksApi.getPOIs());
        this._pois.next(pois);
    }

    public searchPOIs(filter: POIFilter): Observable<Array<IPOI>> {
        return this.pois.pipe(
            map((pois) => {
                pois = pois.filter(
                    (poi) => (this.getCategoryForPOISync(poi) || {}).appearInSearch || filter.includeNonSearchables
                );
                pois = pois.filter((poi) => poi.description || filter.includeNoDescription);
                if (filter.floor !== undefined) pois = pois.filter((poi) => poi.location.floor === filter.floor);
                if (filter.latitude !== undefined && filter.longitude !== undefined)
                    pois = sortBy(pois, [
                        // For future reference: can use getPreciseDistance or specify accuracy. performance tradeoff.
                        (poi): number =>
                            geolib_getDistance(poi.location, {
                                longitude: filter.longitude,
                                latitude: filter.latitude,
                            }),
                    ]);
                if (filter.maxResults !== undefined) pois = pois.slice(0, filter.maxResults);
                return pois;
            })
        );
    }

    public getClosestPOI(location: ILocation, includeNoDescription = false): Observable<IPOI> {
        return this.searchPOIs({ ...location, includeNonSearchables: true, includeNoDescription }).pipe(
            map((results) => (results.length ? results[0] : null))
        );
    }

    public createPOI(poi: IPOIPOST): Observable<IPOI> {
        console.log('CREATING POI', poi);
        return (
            this.tasksApi
                .postPOI(poi)
                // Add poi locally
                .pipe(tap((p) => this.localAdd(p)))
        );
    }

    public updatePOI(poi: IPOIPATCH): Observable<IPOI> {
        return (
            this.tasksApi
                .patchPOI(poi)
                // Add poi locally
                .pipe(tap((p) => this.localUpdate(p)))
        );
    }

    public archivePOI(poi: IPOI): Observable<IPOI> {
        return (
            this.tasksApi
                .archivePOI(poi.id)
                // Remove poi locally
                .pipe(tap(() => this.localRemove(poi)))
        );
    }

    public deletePOI(poi: IPOI): Observable<void> {
        return (
            this.tasksApi
                .deletePOI(poi)
                // Remove poi locally
                .pipe(tap(() => this.localRemove(poi)))
        );
    }

    public getPOIForId(id: string): Observable<IPOI> {
        return this.pois.pipe(map((pois) => pois.find((poi) => poi.id === id)));
    }

    public getPOIForIds(ids: Array<string>): Observable<Array<IPOI>> {
        return this.pois.pipe(map((pois) => pois.filter((poi) => ids.includes(poi.id))));
    }

    public getPOIForIdSync(id: string): IPOI {
        return this._pois.value.find((poi) => poi.id === id);
    }

    public getCategoryForId(categoryId: string): Observable<IPOICategory> {
        return this.poiCategories.pipe(map((categories) => categories.find((c) => c.id === categoryId)));
    }

    public getCategoryForPOISync(poi: IPOI): IPOICategory {
        return this.getCategoryForIdSync(poi.categoryId);
    }

    public getCategoryForIdSync(categoryId: string): IPOICategory {
        return this._poiCategories.value.find((c) => c.id === categoryId);
    }

    public async updatePOICategories(newCategories: Array<IPOICategory>): Promise<void> {
        const newIds = newCategories.map((newCategory) => newCategory.id);
        this._poiCategories.next([
            ...this._poiCategories.value.filter((category) => !newIds.includes(category.id)),
            ...newCategories,
        ]);
    }

    public localUpdate = async (poi: IPOI): Promise<IPOI> => {
        const pois = (await firstValueFrom(this._pois)).map((p) => (p.id === poi.id ? poi : p));
        this._pois.next(pois);
        return poi;
    };

    public localAdd = async (poi: IPOI): Promise<void> => {
        const pois = await firstValueFrom(this._pois);
        if (pois.find((p) => p.id === poi.id)) return null;
        pois.push(poi);
        this._pois.next(pois);
    };

    public localRemove = async (poi: IPOI): Promise<void> => {
        const pois = await firstValueFrom(this._pois);
        const index = pois.findIndex((p) => p.id === poi.id);
        if (index >= 0) pois.splice(index, 1);
        this._pois.next(pois);
    };

    public buildActions = async (poi: IPOI): Promise<Array<EntityAction>> => {
        const actions: Array<EntityAction> = [];
        if (!poi) return actions;

        // Placeholder for future POI actions

        return actions;
    };

    private async refreshPOICategories(): Promise<Array<IPOICategory>> {
        const config = await firstValueFrom(this.tenantService.config);
        const poiCategories = config.taskApi.poiCategories;
        // Publish task types and return them
        this._poiCategories.next(poiCategories);
        return poiCategories;
    }
}
