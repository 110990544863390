<app-entity-list-item
    [entity]="poi"
    [title]="title || (poi | entityName : 'POI' | async)"
    [subtitle]="subtitle || getDefaultSubtitle()"
    [expanded]="expanded"
    [clickable]="clickable"
    [selected]="selected"
    *ngIf="poi"
    #listItem
>
    <ng-container *ngVar="simpleView$ | async as simpleView">
        <app-marker type="POI_CATEGORY" [data]="poiCategory"></app-marker>

        <!--    SIMPLE VIEW-->
        <div class="expanded-content" *ngIf="listItem.expanded && simpleView && showActions" @fade>
            <app-simple-entity-actions
                [actions]="actions"
                [disableOnSocketDisconnect]="disableOnSocketDisconnect"
            ></app-simple-entity-actions>
        </div>
        <button
            class="btn btn-small btn-round btn-flat btn-details-simple entity-action"
            *ngIf="simpleView && (showDetails$ | async)"
            (click)="goToDetails($event)"
        >
            <span>i</span>
        </button>
        <!--    ADVANCED VIEW-->
        <div class="expanded-content" *ngIf="listItem.expanded && !simpleView && showActions" @fade>
            <app-advanced-entity-actions
                [actions]="actions"
                [disableOnSocketDisconnect]="disableOnSocketDisconnect"
                [collapseAfter]="advancedEntityActionsCollapseAfter$ | async"
                [showOverflow]="showOverflowActions"
            ></app-advanced-entity-actions>
        </div>
        <div class="btn-row expanded-content" *ngIf="!simpleView">
            <button class="btn btn-small" (click)="goToDetails($event)" *ngIf="showDetails$ | async">
                <span>{{ 'comp.task-list-item.action.details' | translate }}</span>
                <i class="material-icons">keyboard_arrow_right</i>
            </button>
        </div>
        <div class="expanded-content">
            <ng-content></ng-content>
        </div>
    </ng-container>
</app-entity-list-item>
