import { Component, Input } from '@angular/core';
import { IImage, fade } from 'shared';

@Component({
    selector: 'app-image-thumb',
    templateUrl: './image-thumb.component.html',
    styleUrls: ['./image-thumb.component.scss'],
    animations: [fade()],
})
export class ImageThumbComponent {
    @Input() image: IImage;
}
