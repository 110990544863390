import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ErrorAction, MTPError } from '../../models/errors.model';
import { ErrorPopupComponent } from '../components/modals/error-popup/error-popup.component';
import { DomService } from '../services/dom.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    public constructor(
        private toastr: ToastrService,
        private router: Router,
        private translate: TranslateService,
        private domService: DomService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Ignore requests coming from the dev portal
        if (this.router.url.startsWith('/dev')) return next.handle(req);
        // Otherwise handle normally
        return next.handle(req).pipe(
            catchError((e) => {
                const resp = e as HttpErrorResponse;
                this.getErrorAction(resp.status, e.error)
                    .then((action) => (action ? action : this.getStatusAction(resp.status)))
                    .then((action) => this.executeAction(action));
                return throwError(e);
            })
        );
    }

    async executeAction(action: ErrorAction) {
        if (!action) return;
        switch (action.type) {
            case 'DIALOG':
                this.domService.appendComponentTo<ErrorPopupComponent>('overlay-container', ErrorPopupComponent, {
                    error: action,
                });
                break;
            case 'TOAST':
                this.toastr.error(this.translate.instant(action.message), this.translate.instant(action.title));
                break;
            case 'NONE':
                break;
        }
    }

    async getErrorAction(statusCode: number, error: MTPError): Promise<ErrorAction> {
        if (
            !error ||
            !error.errorCode ||
            typeof error.errorCode !== 'string' ||
            statusCode === 401 ||
            statusCode === 403
        )
            return null;
        switch (error.errorCode) {
            case 'UsernameTaken':
            case 'TooManyUrgentTasks':
                return {
                    type: 'DIALOG',
                    title: 'errors.code.' + error.errorCode + '.title',
                    message: 'errors.code.' + error.errorCode + '.message',
                    errorCode: error.errorCode,
                };
            case 'ShareLinkExpired':
                return { type: 'NONE' };
            case 'InsufficientWorkerTimeCapacity':
                return { type: 'NONE' };
            case 'RoleStillInUse':
                return { type: 'NONE' };
            default:
                return {
                    type: 'DIALOG',
                    title: 'errors.code.UNKNOWN.title',
                    message: this.translate.instant('errors.code.UNKNOWN.message', {
                        description: error.errorDescription,
                    }),
                    errorCode: error.errorCode,
                };
        }
    }

    async getStatusAction(statusCode: number): Promise<ErrorAction> {
        if (statusCode >= 500 && statusCode < 600) {
            switch (statusCode) {
                case 500:
                    return {
                        type: 'DIALOG',
                        title: 'errors.http.500.title',
                        message: 'errors.http.500.message',
                        errorCode: 'HTTP_' + statusCode,
                    };
                case 502:
                case 503:
                case 504:
                    return {
                        type: 'DIALOG',
                        title: 'errors.http.502-504.title',
                        message: 'errors.http.502-504.message',
                        errorCode: 'HTTP_' + statusCode,
                    };
                default:
                    return {
                        type: 'DIALOG',
                        title: 'errors.http.5xx.title',
                        message: 'errors.http.5xx.message',
                        errorCode: 'HTTP_' + statusCode,
                    };
            }
        } else if (statusCode >= 400 && statusCode < 500) {
            switch (statusCode) {
                case 400:
                    return {
                        type: 'DIALOG',
                        title: 'errors.http.400.title',
                        message: 'errors.http.400.message',
                        errorCode: 'HTTP_' + statusCode,
                    };
                case 401:
                case 403:
                    // Leave authorization errors for another interceptor
                    break;
                default:
                    return {
                        type: 'DIALOG',
                        title: 'errors.http.4xx.title',
                        message: 'errors.http.4xx.message',
                        errorCode: 'HTTP_' + statusCode,
                    };
            }
        }
        return null;
    }
}
