import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { Feature } from '../models/feature.model';
import { UserService } from '../services/user.service';

@Pipe({
    name: 'hasFeatures',
    pure: false,
})
export class UserFeaturePipe implements PipeTransform {
    public constructor(private userService: UserService) {}

    public transform(features: Feature | Array<Feature>, all = true): Observable<boolean> {
        return this.userService.hasFeatures(features instanceof Array ? features : [features], all);
    }
}
