import { PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IAsset, IIssue, INamedSubjectType, IPOI, ISharedIssue, ISharedTask, ITask } from 'shared';

import { EntityNamedSubType } from './entity.model';

export abstract class BaseEntityNamePipe implements PipeTransform {
    public constructor(protected translate: TranslateService) {}

    abstract transform(value: any, ...args: Array<any>): any;

    abstract getTaskEntityName(task: ITask | ISharedTask): Observable<string>;

    abstract getIssueEntityName(issue: IIssue | ISharedIssue): Observable<string>;

    abstract getAssetEntityName(asset: IAsset): Observable<string>;

    abstract getPoiEntityName(poi: IPOI): Observable<string>;

    combineTypeAndSubjectType(type: EntityNamedSubType, subjectType: INamedSubjectType): Observable<string> {
        return combineLatest([
            type ? this.translate.stream(type.name) : of(null),
            subjectType ? this.translate.stream(subjectType.name) : of(null),
        ]).pipe(
            map(([subTypeName, subjectTypeName]) => {
                return subjectTypeName && subTypeName
                    ? `${subjectTypeName}: ${subTypeName}`
                    : subjectTypeName || subTypeName || null;
            }),
            filter((title) => !!title)
        );
    }
}
