import { Pipe, PipeTransform } from '@angular/core';
import { Observable, from } from 'rxjs';
import { IAsset, IAssetType } from 'shared';

import { Entity } from '../../models/entity.model';
import { AssetService } from '../services/asset.service';

@Pipe({
    name: 'assetType',
    pure: true,
})
export class AssetTypePipe implements PipeTransform {
    public constructor(private assetService: AssetService) {}

    transform(asset: Entity, all = true): Observable<IAssetType> {
        return from(this.assetService.getAssetType((asset as IAsset).typeId));
    }
}
