<div class="button-list">
    <button (click)="editFilterName($event)" class="dropdown-content button">
        <app-icon class="icon" icon="edit" [style]="'outlined'"></app-icon>
        <span>{{ 'comp.filter-select-popup.dropdown.editName' | translate }}</span>
    </button>
    <button (click)="editFilter($event)" class="dropdown-content button">
        <app-icon class="icon" icon="edit" [style]="'outlined'"></app-icon>
        <span>{{ 'comp.filter-select-popup.dropdown.editFilters' | translate }}</span>
    </button>
    <button (click)="deleteFilter($event)" class="dropdown-content button">
        <app-icon class="icon" icon="delete_outline" [style]="'outlined'"></app-icon>
        <span>{{ 'comp.filter-select-popup.dropdown.delete' | translate }}</span>
    </button>
</div>
