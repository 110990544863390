import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ITask } from 'shared';

import { ISocketEvent } from '../../../models/socket-event.model';
import { SocketService } from '../socket.service';
import { AppStateService } from '../state/app-state.service';
import { TaskService } from '../task.service';
import { UserService } from '../user.service';
import { BaseSocketEventHandler } from './base-socket-event-handler';

@Injectable({ providedIn: 'root' })
export abstract class TaskAssignmentBaseSocketEventHandler<T extends ISocketEvent> extends BaseSocketEventHandler<T> {
    protected constructor(
        private readonly taskService: TaskService,
        private readonly userService: UserService,
        protected readonly appState: AppStateService,
        socketService: SocketService
    ) {
        super(socketService);
    }

    protected async ensureLocalState(event: T): Promise<boolean> {
        // Get task from local service
        const localTask = await firstValueFrom(
            this.taskService.tasks.pipe(map((tasks) => tasks.find((t) => t.id === event.task.id)))
        );
        // Check permissions if we're allowed to keep the task
        const hasPermission = await firstValueFrom(this.canSeeTask(event.task));
        // Remove task locally if no permission for new task
        if (!hasPermission && localTask) {
            await this.taskService.localRemove(event.task.id);
        }
        // Update task locally if needed
        if (hasPermission && localTask) {
            localTask.assigneeId = event.task.assigneeId;
            await this.taskService.localUpdate(localTask);
        }
        // Add task locally if needed
        if (hasPermission && !localTask) {
            await this.taskService.localAdd(event.task);
        }

        return hasPermission;
    }

    protected canSeeTask(task: ITask): Observable<boolean> {
        return task.assigneeId
            ? this.appState.user.stream.pipe(
                  switchMap((user) =>
                      task.assigneeId === user.id
                          ? this.userService.hasPermissions(['Tasks.ReadTasks.All', 'Tasks.ReadTasks.Self'], false)
                          : this.userService.hasPermission('Tasks.ReadTasks.All')
                  )
              )
            : this.userService.hasPermissions(['Tasks.ReadTasks.All', 'Tasks.ReadTasks.Unassigned'], false);
    }
}
