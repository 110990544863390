import { AnimationBuilder, animate, style } from '@angular/animations';
import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { TimeoutUtils } from '../utils';


@Directive({
    selector: '[ogsTooltip]',
    standalone: true,
})
export class TooltipDirective implements AfterViewInit, OnDestroy {
    @Input() private ogsTooltip?: string;
    private initialized = false;
    private tooltipElement?: HTMLElement;

    public constructor(
        private elementRef: ElementRef<HTMLElement>,
        private readonly translateService: TranslateService,
        private readonly animationBuilder: AnimationBuilder
    ) {}

    public ngAfterViewInit(): void {
        this.initialized = true;
    }

    public ngOnDestroy(): void {
        this.onMouseLeave();
    }

    @HostListener('mouseenter', ['$event'])
    protected onMouseEnter(): void {
        if (!this.initialized || this.tooltipElement || !this.ogsTooltip) return;
        TimeoutUtils.debounce('debounceFilterTooltip', () => this.createElement(), 400);
    }

    @HostListener('mouseleave', ['$event'])
    protected onMouseLeave(): void {
        TimeoutUtils.cancelDebounce('debounceFilterTooltip');
        if (!this.initialized || !this.tooltipElement) return;
        const tooltip = this.tooltipElement;

        const removeAnimation = this.animationBuilder
            .build([animate('0.3s', style({ opacity: 0, transform: 'scale(0.9)' }))])
            .create(tooltip);
        removeAnimation.play();

        removeAnimation.onDone(() => {
            tooltip.remove();
            this.tooltipElement = undefined;
        });
    }

    private createElement(): void {
        if (!this.initialized || this.tooltipElement || !this.ogsTooltip) return;

        this.tooltipElement = document.createElement('div');
        this.tooltipElement.classList.add('ovr-tooltip', 'shown');

        const parentElement = this.elementRef.nativeElement;
        parentElement.style.position = 'relative';
        parentElement.insertAdjacentElement('afterbegin', this.tooltipElement);

        const container = document.createElement('div');
        container.classList.add('ovr-tooltip-container');
        container.innerText = this.translateService.instant(this.ogsTooltip);
        this.tooltipElement.appendChild(container);

        const tooltipWidth = this.tooltipElement.firstElementChild.clientWidth;
        const parentRect = parentElement.getBoundingClientRect();
        const tooltipX = parentRect.left + parentRect.width / 2 - tooltipWidth / 2;

        this.tooltipElement.style.left = `${tooltipX}px`;
        this.tooltipElement.style.top = `${parentRect.bottom + 5}px`;

        this.animationBuilder
            .build([
                style({ opacity: 0, transform: 'scale(0.9)' }),
                animate('0.3s', style({ opacity: 1, transform: 'scale(1)' })),
            ])
            .create(this.tooltipElement)
            .play();
    }
}
