import { AnimationTriggerMetadata, animate, state, style, transition, trigger } from '@angular/animations';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'lodash';

@Component({
    selector: 'app-inline-text-field',
    templateUrl: './inline-text-field.component.html',
    styleUrls: ['./inline-text-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [grow()],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InlineTextFieldComponent),
            multi: true,
        },
    ],
})
export class InlineTextFieldComponent implements OnChanges, ControlValueAccessor {
    @Input()
    private placeholder: string;

    @Input()
    private disabled: boolean;

    @Input()
    private shownLineCount = 2;

    @Input()
    protected maxLength: number;

    @ViewChild('textInput')
    textAreaElement: ElementRef;

    value: string;
    isEditable: boolean;
    onChange: (value: string) => void;
    onTouched: () => void;
    _placeholder: string;
    isDisabled: boolean;
    isAnimating: boolean;

    public constructor(private readonly ref: ChangeDetectorRef) {
        this.onChange = noop;
        this.onTouched = noop;
        this.isAnimating = false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { placeholder, disabled } = changes;
        if (placeholder?.currentValue) {
            this._placeholder = placeholder.currentValue;
        }
        if (disabled?.currentValue) {
            this.isDisabled = disabled.currentValue;
        }
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = (value: string): void => {
            this.value = value;
            fn(value);
        };
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
        this.ref.markForCheck();
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        this.ref.markForCheck();
    }

    writeValue<TValue>(obj: TValue): void {
        if (!(obj === undefined || obj === null)) {
            if (typeof obj === 'string') {
                this.value = obj;
            } else {
                this.value = JSON.stringify(obj);
            }
        }
        this.ref.markForCheck();
    }

    getShownHeight(): string {
        return `${this.shownLineCount * 1.5}em`;
    }

    onBlur(): void {
        this.isEditable = false;
        this.onTouched();
        this.textAreaElement.nativeElement.scrollTop = 0;
    }

    toggle(): void {
        if (this.isDisabled) return;

        this.isEditable = true;
        this.ref.detectChanges();
        this.textAreaElement.nativeElement.focus();
    }

    startAnimation(): void {
        this.isAnimating = true;
        this.ref.markForCheck();
    }

    doneAnimation(): void {
        this.isAnimating = false;
        this.ref.markForCheck();
    }
}

function grow(): AnimationTriggerMetadata {
    return trigger('grow', [
        state('hide', style({ width: '8em', height: '1.5em' })),
        state('show', style({ width: '18em', height: '{{shownHeight}}' }), { params: { shownHeight: '6em' } }),
        transition('* => *', [animate('150ms ease')]),
    ]);
}
