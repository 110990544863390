import { Injectable } from '@angular/core';
import { EMPTY, Observable, firstValueFrom } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { IRole, IRolePUT, UserApiService } from 'shared';

import { AppStateService } from '../../../mtp-common/services/state/app-state.service';

@Injectable({
    providedIn: 'root',
})
export class RoleManagementService {
    private cachedRoles$: Observable<Array<IRole>> | undefined;

    private constructor(private readonly userApi: UserApiService, private readonly appState: AppStateService) {
        this.appState.activeTenant.stream.subscribe(() => (this.cachedRoles$ = null));
    }

    public listRoles(): Promise<Array<IRole>> {
        if (!this.cachedRoles$) {
            this.cachedRoles$ = this.userApi.getRoles().pipe(
                shareReplay(1),
                catchError(() => {
                    delete this.cachedRoles$;
                    return EMPTY;
                })
            );
        }

        return firstValueFrom(this.cachedRoles$);
    }

    public getRole(roleId: string): Promise<IRole> {
        return this.listRoles().then((roles) => roles.find((role) => role.id === roleId));
    }

    public createRole(roleName: string, permissionIds: Array<string> = []): Observable<IRole> {
        return this.userApi.createRole({ name: roleName, featureIds: [], permissionIds }).pipe(
            tap((response) => {
                if (response) delete this.cachedRoles$;
            })
        );
    }

    public removeRole(roleId: string): Observable<IRole> {
        return this.userApi.deleteRole(roleId).pipe(tap(() => delete this.cachedRoles$));
    }

    public updateRole(roleId: string, request: IRolePUT): Observable<IRole> {
        return this.userApi.putRole(roleId, request).pipe(tap(() => delete this.cachedRoles$));
    }
}
