<app-context-list>
    <input
        tabindex="1"
        type="text"
        [placeholder]="'comp.location-select-list.searchPlaceholder' | translate"
        #searchInputEl
        appFocusable
        (keydown.enter)="onSearchEnterDown()"
    />
    <div @listItem>
        <app-context-list-item
            class="large"
            [disabled]="!geolocatorService.lastKnownLocation"
            (focusin)="focusCurrentLocation()"
            (click)="selectCurrentLocation()"
            (keydown.enter)="selectCurrentLocation()"
            appFocusable
            appFocusOnHover
        >
            <i class="material-icons small">my_location</i>
            {{ 'comp.location-select-list.currentLocation' | translate }}
        </app-context-list-item>
    </div>
    <div *ngFor="let poi of results | async; trackBy: trackPOIBy" @listItem>
        <app-context-list-item
            (focusin)="focusPOI(poi)"
            (click)="selectPOI(poi)"
            (keydown.enter)="selectPOI(poi)"
            appFocusable
            appFocusOnHover
        >
            {{ poi.description }}
            <b>&nbsp;·&nbsp;L{{ poi.location.floor }}</b>
        </app-context-list-item>
    </div>
    <div *ngIf="searchInputEl.value && !(results | async)?.length" @listItem>
        <app-context-list-item class="disabled no-results">
            {{ 'comp.location-select-list.noResults' | translate }}
        </app-context-list-item>
    </div>
</app-context-list>
