<div class="collapsible">
    <div (click)="toggle()" [ngClass]="{ collapsed: isCollapsed }" class="collapsible-header" part="collapsible-header">
        <i *ngIf="icon" class="material-icons icon">{{ icon }}</i>
        <span class="collapsible-title" part="collapsible-title">{{ title }}</span>
        <i [class.collapsed]="isCollapsed" *ngIf="canExpand" class="material-icons control">expand_less</i>
    </div>
    <div
        @triggerChildren
        [@collapse]="isCollapsed ? 'collapsed' : 'expanded'"
        class="collapsible-content"
        part="collapsible-content"
    >
        <ng-content></ng-content>
    </div>
</div>
