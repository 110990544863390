import { Injectable } from '@angular/core';
import { AuthService } from 'baseflow-auth';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, tap } from 'rxjs/operators';
import { ILocation, IOrdinal } from 'shared';

declare global {
    interface GeolocationCoordinates {
        floorLevel: number | null;
    }
}

@Injectable({ providedIn: 'root' })
export class GeolocatorService {
    private watchId: number;
    private readonly _position: Subject<GeolocationPosition> = new BehaviorSubject(null);
    private readonly _positionError: Subject<GeolocationPositionError> = new ReplaySubject(1);
    private lastKnownFloor: { floor: number; timestamp: number };
    public readonly position: Observable<GeolocationPosition> = this._position.asObservable();
    public readonly positionError: Observable<GeolocationPositionError> = this._positionError.asObservable();
    private _lastKnownLocation: ILocation | null = null;
    public get lastKnownLocation(): ILocation | null {
        return this._lastKnownLocation;
    }

    private constructor(private auth: AuthService) {
        this.auth.loggedIn.pipe(distinctUntilChanged(), debounceTime(300)).subscribe((loggedIn) => {
            if (loggedIn) this.startTracking();
            else this.stopTracking();
        });
        // Reset position to null after not receiving a location for a while
        this.position
            .pipe(
                debounceTime(30000),
                filter((pos) => pos !== null)
            )
            .subscribe(() => this._position.next(null));
        // Determine location from position
    }

    public getLocation(viewOrdinal: Observable<IOrdinal | null>): Observable<ILocation | null> {
        return combineLatest([viewOrdinal.pipe(startWith(null)), this._position]).pipe(
            tap(([, pos]) => {
                if (pos && pos.coords && pos.coords.floorLevel) {
                    this.lastKnownFloor = { floor: pos.coords.floorLevel, timestamp: Date.now() };
                }
            }),
            map(([viewOrdinal, pos]) => this.positionToLocation(pos, viewOrdinal)),
            distinctUntilChanged(),
            tap((location) => (this._lastKnownLocation = location))
        );
    }

    startTracking(): void {
        this.stopTracking();
        try {
            this.watchId = navigator.geolocation.watchPosition(
                (p) => this._position.next(p),
                (pe) => this._positionError.next(pe),
                { enableHighAccuracy: true }
            );
        } catch (e) {
            console.warn(e);
            this.watchId = null;
        }
    }

    stopTracking(): void {
        if (this.watchId !== null && this.watchId !== undefined) {
            navigator.geolocation.clearWatch(this.watchId);
            this.watchId = null;
        }
    }

    private floorCacheValid(): boolean {
        return this.lastKnownFloor && Date.now() - this.lastKnownFloor.timestamp < 60000 * 5;
    }

    private positionToLocation(pos: GeolocationPosition, viewOrdinal: IOrdinal | null): ILocation | null {
        // No location if there's no coordinates
        if (!pos || !pos.coords) return null;
        let floor;
        // If there is a floor level, set that
        if (typeof pos.coords.floorLevel === 'number') floor = pos.coords.floorLevel;
        // Otherwise, fall back to the floor cache, if it is valid
        else if (this.floorCacheValid()) floor = this.lastKnownFloor.floor;
        // Otherwise, fall back to the floor currently being viewed
        else if (viewOrdinal) floor = viewOrdinal.id;
        // If none is being viewed, we cannot determine a location
        else return null;
        return {
            longitude: pos.coords.longitude,
            latitude: pos.coords.latitude,
            heading: pos.coords.heading,
            floor,
        };
    }
}
