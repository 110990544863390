<div class="entity-header" [title]="devMode ? entity.id : title">
    <div class="entity-header-left">
        <div class="app-marker-container">
            <ng-content select="app-marker"></ng-content>
        </div>
        <div class="entity-header-text">
            <span class="entity-title">{{ title }}</span>
            <span class="entity-subtitle">{{ subtitle }}</span>
            <span class="entity-summary" *ngIf="summary">{{ summary }}</span>
            <ng-content select=".entity-sub-summary"></ng-content>
        </div>
    </div>
    <div class="entity-header-right">
        <ng-content select=".entity-action"></ng-content>
    </div>
</div>

<div class="entity-collapsible" @vshrink *ngIf="expanded">
    <ng-content select=".expanded-content"></ng-content>
</div>
