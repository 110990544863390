<button class="btn-date-prev" (click)="onDatePrevious()"><i class="material-icons">keyboard_arrow_left</i></button>
<div class="date-picker-current" (clickOutside)="showDropDown = false">
    <button (click)="showDropDown = !showDropDown">
        {{ date | amFromUnix | amDateFormat : 'DD MMMM YYYY' }}
    </button>
    <mat-calendar
        *ngIf="showDropDown"
        @fadeDown
        [selected]="unixTimeAsDate(date)"
        (selectedChange)="onSelectedChange($event)"
    ></mat-calendar>
</div>
<button class="btn-date-next" (click)="onDateNext()"><i class="material-icons">keyboard_arrow_right</i></button>
