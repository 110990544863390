import { DefaultHttpClient, HttpRequest, HttpResponse, ILogger } from '@microsoft/signalr';

import { ConsoleLogger } from './logging.service';

export class CustomHeaderHttpClient extends DefaultHttpClient {
    public constructor(private readonly customHeaders: Record<string, string>, logger: ILogger = null) {
        super(logger ?? new ConsoleLogger());
    }

    public send(request: HttpRequest): Promise<HttpResponse> {
        request.headers = { ...request.headers, ...this.customHeaders };
        return super.send(request);
    }
}
