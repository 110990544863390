import { Injectable } from '@angular/core';
import { Howl } from 'howler';
import { Subject } from 'rxjs';
import { skipUntil, throttleTime } from 'rxjs/operators';

const SOUNDS = ['external_assign.mp3'];

interface Sound {
    file: string;
    onPlay?: () => void;
}

@Injectable({ providedIn: 'root' })
export class SoundService {
    private readonly _soundSink: Subject<Sound> = new Subject();
    private readonly _soundsLoaded: Subject<void> = new Subject();
    private readonly _howls: { [file: string]: Howl } = {};

    private constructor() {
        // Load sounds
        Promise.all(
            SOUNDS.map((s) => new Promise((resolve) => this.getHowl(s).once('load', () => resolve(undefined))))
        ).then((_) => this._soundsLoaded.next());
        // Playing of sounds, throttled
        this._soundSink.pipe(skipUntil(this._soundsLoaded), throttleTime(3000)).subscribe((sound) => {
            if (sound.onPlay) sound.onPlay();
            // this.getHowl(sound.file).play();
        });
    }

    public playSound(file: string, onPlay?: () => void) {
        const sound: Sound = { file, onPlay };
        this._soundSink.next(sound);
        return sound;
    }

    private getHowl = (file: string) =>
        this._howls[file] || (this._howls[file] = new Howl({ src: ['/assets/sound/' + file] }));
}
