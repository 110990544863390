import { Injectable } from '@angular/core';
import { ITask } from 'shared';

import { SocketService } from '../socket.service';
import { AppStateService } from '../state/app-state.service';
import { TaskService } from '../task.service';
import { UserService } from '../user.service';
import { TaskAssignmentBaseSocketEventHandler } from './task-assignment-base.se-handler';

interface TaskUnassignedSocketEvent {
    eventDtoType: 'TaskUnassigned';
    task: ITask;
}

@Injectable({ providedIn: 'root' })
export class TaskUnassignedSocketEventHandler extends TaskAssignmentBaseSocketEventHandler<TaskUnassignedSocketEvent> {
    protected constructor(
        taskService: TaskService,
        socketService: SocketService,
        userService: UserService,
        appState: AppStateService
    ) {
        super(taskService, userService, appState, socketService);
    }

    protected eventDtoType(): string {
        return 'TaskUnassigned';
    }

    protected async handleEvent(event: TaskUnassignedSocketEvent): Promise<void> {
        await this.ensureLocalState(event);
    }
}
