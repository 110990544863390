import { Component, ElementRef, Input } from '@angular/core';

import { DomComponent, DomService } from '../../../services/dom.service';
import { MapComponent } from '../map/map.component';
import { OrdinalSelectPopupComponent } from './ordinal-select-popup/ordinal-select-popup.component';

@Component({
    selector: 'app-map-ordinal-control',
    templateUrl: './map-ordinal-control.component.html',
    styleUrls: ['./map-ordinal-control.component.scss'],
})
export class MapOrdinalControlComponent {
    @Input() public map?: MapComponent;
    private ordinalPopup: DomComponent<OrdinalSelectPopupComponent>;

    public constructor(private elementRef: ElementRef, private domService: DomService) {}

    protected onButtonClick($event: MouseEvent): void {
        if (this.ordinalPopup) {
            this.ordinalPopup.remove();
        } else {
            const ordinals = this.map?.state.ordinals.value;
            if (!ordinals) return;
            this.ordinalPopup = this.domService.appendComponentTo<OrdinalSelectPopupComponent>(
                'overlay-container',
                OrdinalSelectPopupComponent,
                {
                    padding: 20,
                    origin: [$event.clientX, $event.clientY],
                    ordinals,
                }
            );
            this.ordinalPopup.component.ordinalSelect.subscribe(this.onSelect);
            this.ordinalPopup.on('remove', () => (this.ordinalPopup = null));
        }
    }

    private onSelect = async (ordinalId: number): Promise<void> => {
        if (this.ordinalPopup) this.ordinalPopup.remove();
        await this.map?.flyTo({ ...this.map?.getCenterLocation(), floor: ordinalId });
    };
}
