<div class="bar">
    <div class="bar-progress">
        <div class="bar-progress-fill" [style.width]="_progress * 100 + '%'"></div>
    </div>
    <div class="bar-content">
        <div class="bar-text">
            <span>{{ text }}</span>
        </div>
        <div class="bar-actions">
            <button *ngFor="let action of actions" [class.primary]="action.primary" (click)="action.onClick()">
                {{ action.label }}
            </button>
        </div>
    </div>
</div>
