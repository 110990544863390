<svg
    class="spinner"
    [attr.width]="width"
    [attr.height]="height"
    [attr.viewBox]="viewBox"
    xmlns="http://www.w3.org/2000/svg"
>
    <circle
        class="path"
        fill="none"
        [attr.stroke-width]="strokeWidth"
        stroke-linecap="round"
        [attr.cx]="cx"
        [attr.cy]="cy"
        [attr.r]="r"
    ></circle>
</svg>
