import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, ITenant } from 'baseflow-auth';
import { locale as moment_locale } from 'moment';
import { Observable, combineLatest, firstValueFrom, interval, lastValueFrom, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { IPermissionCategory, IRole, IUser, IUserTenantInvitation, UserApiService } from 'shared';

import { UserFilter } from '../../models/user-filter.model';
import { allowAll } from '../../utils/allow-all.filter';
import { StateValue } from '../../utils/state-value';
import { Feature } from '../models/feature.model';
import { ISocketStatus } from './socket.service';
import { AppStateService } from './state/app-state.service';
import { TenantService } from './tenant.service';

type TAsyncUser = Observable<IUser> | StateValue<IUser>;

interface UserQuery {
    limit?: number;
    filter?: UserFilter;
}

@Injectable({ providedIn: 'root' })
export class UserService {
    private socketStatus: ISocketStatus['status'];

    private _hasPermission = {
        get: (user: IUser, permission: string): boolean =>
            user ? this.containsNode(permission, user.permissions ?? []) : false,
        stream: (user: TAsyncUser, permission: string): Observable<boolean> =>
            this.observable(user).pipe(map((u) => this._hasPermission.get(u, permission))),
    };

    private _hasPermissions = {
        get: (user: IUser, permissions: Array<string>, all = true): boolean => {
            const _permissions = permissions.map((p) => this._hasPermission.get(user, p));
            return all ? _permissions.every(Boolean) : _permissions.some(Boolean);
        },
        stream: (user: TAsyncUser, permissions: Array<string>, all = true): Observable<boolean> =>
            combineLatest(permissions.map((p) => this._hasPermission.stream(user, p))).pipe(
                map((_permissions) => (all ? _permissions.every(Boolean) : _permissions.some(Boolean)))
            ),
    };

    private _hasFeature = {
        get: (user: IUser, feature: Feature): boolean => (user ? this.containsNode(feature, user.features) : false),
        stream: (user: TAsyncUser, feature: Feature): Observable<boolean> =>
            this.observable(user).pipe(map((u) => this._hasFeature.get(u, feature))),
    };

    private _hasFeatures = {
        get: (user: IUser, features: Array<Feature>, all = true): boolean => {
            const _features = features.map((p) => this._hasFeature.get(user, p));
            return all ? _features.every(Boolean) : _features.some(Boolean);
        },
        stream: (user: TAsyncUser, features: Array<Feature>, all = true): Observable<boolean> =>
            combineLatest(features.map((p) => this._hasFeature.stream(user, p))).pipe(
                map((_features) => (all ? _features.every(Boolean) : _features.some(Boolean)))
            ),
    };

    public constructor(
        private readonly authService: AuthService,
        private readonly userApi: UserApiService,
        private readonly translate: TranslateService,
        private readonly storage: StorageMap,
        private readonly tenantService: TenantService,
        private readonly appState: AppStateService
    ) {
        // Refresh user on login state change
        this.authService.loggedIn
            .pipe(
                distinctUntilChanged(),
                tap(() => this.refreshCurrentUser())
            )
            .subscribe();
        combineLatest([
            this.appState.activeTenantId.stream.pipe(distinctUntilChanged()),
            this.appState.user.stream.pipe(distinctUntilChanged((a, b) => a?.id === b?.id)),
        ])
            .pipe(
                filter(([tenantId, user]) => tenantId && user && user.permissions == null),
                tap(() => this.refreshCurrentUser())
            )
            .subscribe();

        // Clear data on logout
        this.appState.user.stream
            .pipe(
                filter((user) => !user),
                tap(() => {
                    this.appState.users.value = [];
                    this.tenantService.selectTenant(null, null);
                })
            )
            .subscribe();

        // Refresh every 5 minutes
        interval(5 * 60 * 1000)
            .pipe(
                filter(() => this.appState.loggedInWithTenant.value),
                tap(() => this.refreshAllUsers())
            )
            .subscribe();

        // Refresh users on socket connect
        this.appState.socketStatus.stream
            .pipe(
                tap((s) => {
                    // Don't log the same status twice.
                    if (this.socketStatus !== s.status) {
                        this.socketStatus = s.status;
                        console.log('SocketStatus', s.status);
                    }
                }),
                filter((s) => s.status === 'CONNECTED'),
                mergeMap(() => this.refreshAllUsers())
            )
            .subscribe();
    }

    public hasPermissionSync = (permission: string): boolean =>
        this._hasPermission.get(this.appState.user.value, permission);

    public hasPermission = (permission: string): Observable<boolean> =>
        this._hasPermission.stream(this.appState.user, permission);

    public hasPermissionsSync = (permissions: Array<string>, all = true): boolean =>
        this._hasPermissions.get(this.appState.user.value, permissions, all);

    public hasPermissions = (permissions: Array<string>, all = true): Observable<boolean> =>
        this._hasPermissions.stream(this.appState.user, permissions, all);

    public hasFeature = (feature: Feature): Observable<boolean> => this._hasFeature.stream(this.appState.user, feature);

    public hasFeatures = (features: Array<Feature>, all = true): Observable<boolean> =>
        this._hasFeatures.stream(this.appState.user, features, all);

    public useLanguage(language: string): Observable<unknown> {
        // Change locale in moment library
        moment_locale(language);
        // Set translation locale
        return this.translate.use(language);
    }

    public saveLanguage(language: string, saveToProfile = true): void {
        language ??= 'en';
        this.storage.set('USER_LANGUAGE', language).pipe(take(1)).subscribe();
        this.useLanguage(language);
        if (!saveToProfile) return;
        // Save preference to profile
        const user = this.appState.user.value;
        if (!user) return;
        this.userApi.putProfile(user.id, { language }).subscribe();
    }

    public getLanguage(): Observable<string> {
        return this.storage.get<string>('USER_LANGUAGE', { type: 'string' });
    }

    public checkPermission(permission: string): Promise<boolean> {
        return firstValueFrom(this._hasPermission.stream(this.appState.user, permission));
    }

    public filterUsers(filter: UserFilter): Observable<Array<IUser>> {
        return this.appState.users.stream.pipe(
            switchMap((users) =>
                combineLatest(
                    users.map((user) => (filter || allowAll)(user).pipe(map((enabled) => ({ user, enabled }))))
                )
            ),
            map((userResults) =>
                userResults.filter((userResult) => userResult.enabled).map((userResult) => userResult.user)
            )
        );
    }

    public getUser(id: string): Observable<IUser> {
        return this.appState.users.stream.pipe(map((users) => users.find((u) => u.id === id)));
    }

    public getTokenActor(id: string): Observable<IUser> {
        return this.appState.serviceTokenUsers.stream.pipe(map((tokens) => tokens.find((u) => u.id === id)));
    }

    // Used for checking permissions or features

    public getUsers(ids: Array<string>): Observable<Array<IUser>> {
        return this.appState.users.stream.pipe(map((users) => users.filter((u) => ids.includes(u.id))));
    }

    public getUserShortName(user?: IUser): string {
        if (!user) return null;
        const names = user.fullName.split(' ');
        return names.map((n, i) => (i === names.length - 1 ? n : n.charAt(0).toUpperCase() + '.')).join(' ');
    }

    public getPermissionCategories(): Observable<Array<IPermissionCategory>> {
        return this.userApi.getPermissionCategories();
    }

    public refreshAllUsers = async (): Promise<Array<IUser>> => {
        // Fetch users
        const users = await firstValueFrom(this.userApi.getUsers());
        // Emit new tasks
        this.appState.users.value = users;

        // Fetch service token users
        const serviceTokenUsers = await firstValueFrom(this.userApi.getServiceTokenUsers());
        // Emit new tasks
        this.appState.serviceTokenUsers.value = serviceTokenUsers;

        // Return tasks
        return users;
    };

    public createUserTenantInvitation(roles: Array<IRole>): Observable<IUserTenantInvitation> {
        return this.userApi.postInvitation(roles.map((r) => r.id));
    }

    private refreshCurrentUser = async (): Promise<IUser> => {
        const userObservable = combineLatest([
            this.authService.loggedIn,
            this.appState.activeTenantId.stream.pipe(distinctUntilChanged()),
        ]).pipe(
            switchMap(([loggedIn, tenantId]) => this.loadUser(loggedIn, tenantId)),
            map(({ user, tenantId, permissionsLoaded }) => this.selectTenant(user, tenantId, permissionsLoaded)),
            switchMap(({ user, tenant, permissionsLoaded }) => this.loadPermissions(user, tenant, permissionsLoaded)),
            take(1),
            tap((user) => (this.appState.user.value = user)),
            tap((user) => {
                if (user) this.saveLanguage(user.profile.language, false);
            })
        );

        return lastValueFrom(userObservable);
    };

    /**
     * If logged in, get the logged-in user. If not, return null.
     * @param loggedIn Whether or not the user is logged in.
     * @param tenantId The tenant the user is logged in on.
     * @returns The logged-in user, tenantID and whether permissions are loaded, or null if not logged in.
     * @private
     */
    private loadUser(
        loggedIn: boolean,
        tenantId: string
    ): Observable<{ user: IUser; tenantId: string; permissionsLoaded: boolean }> {
        let user: Observable<IUser>;
        if (loggedIn) {
            if (tenantId) {
                user = this.userApi.getMeWithPermissions(tenantId);
            } else {
                user = this.userApi.getMe().pipe(
                    map((user) => {
                        user.permissions = null;
                        return user;
                    }),
                    tap((user) => (this.appState.user.value = user))
                );
            }
        } else {
            user = of(null);
        }

        const permissionsLoaded = user.pipe(map((u) => u?.permissions !== null));
        return combineLatest({ user, tenantId: of(tenantId), permissionsLoaded: permissionsLoaded });
    }

    /**
     * If we have an active tenant, select it.
     * @param user The logged-in user
     * @param tenantId The tenant the user is logged in on.
     * @param permissionsLoaded Whether or not the permissions have already been loaded.
     * @returns An array containing:
     *
     * - The logged-in user or null,
     * - The current tenantId if it's selected,
     * - Whether permissions are loaded.
     * @private
     */
    private selectTenant(
        user: IUser,
        tenantId: string,
        permissionsLoaded: boolean
    ): { user: IUser; tenant: ITenant; permissionsLoaded: boolean } {
        if (!user) return { user, tenant: null, permissionsLoaded };

        let tenant: ITenant = null;
        if (tenantId) {
            tenant = user.tenants.find((t) => t.id === tenantId);
        } else if (user.tenants.length === 1) {
            tenant = user.tenants[0];
        }

        if (tenant) {
            this.tenantService.selectTenant(user, tenant);
        }

        return { user: user, tenant: tenant, permissionsLoaded: permissionsLoaded };
    }

    /**
     *  If we have a logged-in user and an active tenant, load authorization.
     * @param user The logged-in user
     * @param tenant The tenant the user is logged in on.
     * @param permissionsLoaded Whether or not the permissions have already been loaded.
     * @returns The logged-in user with loaded permissions or null.
     * @private
     */
    private loadPermissions(user: IUser, tenant: ITenant, permissionsLoaded: boolean): Observable<IUser> {
        return !tenant || permissionsLoaded
            ? of(user)
            : this.userApi
                  .getMeWithPermissions(tenant.id)
                  .pipe(mergeMap(async (permissions) => ({ ...user, ...permissions } as IUser)));
    }

    // Supports wildcards
    private containsNode(node: string | Feature, nodes: Array<string>): boolean {
        node = typeof node === 'string' ? node : Feature[node];

        if (node === '*') return true;
        const ruleStr = `^${node
            .toLowerCase()
            .split('.')
            .map((str) => (str === '*' ? '.+?' : str.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')))
            .join('[.]')}$`;
        const rule = new RegExp(ruleStr);
        return !!nodes.map((_node) => _node.toLowerCase()).find((_node) => rule.test(_node));
    }

    private observable(user: TAsyncUser): Observable<IUser> {
        return user instanceof Observable ? user : user.stream;
    }
}
