import { Component, ElementRef, HostListener } from '@angular/core';
import { fadeUp } from 'shared';

import { ErrorDialogAction } from '../../../../models/errors.model';
import { BasePopupComponent } from '../base-popup/base-popup.component';

@Component({
    selector: 'app-error-popup',
    templateUrl: './error-popup.component.html',
    styleUrls: ['./error-popup.component.scss'],
    animations: [fadeUp('popup')],
})
export class ErrorPopupComponent extends BasePopupComponent<ErrorPopupComponent> {
    error: ErrorDialogAction;

    public constructor(elementRef: ElementRef) {
        super(elementRef);
        this.popupOptions.padding = 0;
    }

    @HostListener('click', ['$event'])
    clickBg($event: MouseEvent): void {
        if (this.closable && this.elementRef.nativeElement === $event.target) {
            $event.stopImmediatePropagation();
            this.__DOM_COMPONENT.remove();
        }
    }

    close(): void {
        this.__DOM_COMPONENT.remove();
    }
}
