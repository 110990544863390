import { IFilterModel } from './filter.model';
import { ISortingModel } from './sorting.model';

export enum FilterEntityType {
    Issue = 0,
    Task = 1,
}

export interface IFilterConfigurationModel<T> {
    /** Id of the filter for reference */
    id: string;

    /** Name for displaying purposes in the app/web */
    displayName: string;

    /** The tenant this filter is available for. If not set, this filter is not available to any tenant ("disabled") */
    tenantId?: string;

    /** Entity type this filter is applicable for */
    entityType: FilterEntityType;

    /** List of filters */
    filters: Array<IFilterModel>;

    /** The sorting configured for this filter */
    sorting: Array<ISortingModel<T>>;
}
