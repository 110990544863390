import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { IIssueType } from 'shared';

import { IssueService } from '../services/issue.service';

@Pipe({
    name: 'issueType',
    pure: true,
})
export class IssueTypePipe implements PipeTransform {
    public constructor(private issueService: IssueService) {}

    transform(issueTypeId: string): Observable<IIssueType> {
        return this.issueService.getIssueTypeForId(issueTypeId);
    }
}
