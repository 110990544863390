import { Injectable } from '@angular/core';
import { IImage } from 'shared';

import { ImageViewerComponent } from '../components/modals/image-viewer/image-viewer.component';
import { DomService } from './dom.service';

@Injectable({
    providedIn: 'root',
})
export class ImageViewerService {
    private constructor(private readonly domService: DomService) {}

    openImages(images: Array<IImage>, index: number) {
        const comp = this.domService.appendComponentTo<ImageViewerComponent>(
            'overlay-container',
            ImageViewerComponent,
            {
                images,
            }
        );
        comp.component.currentImageIndex$.next(index || 0);
    }
}
