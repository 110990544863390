import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Directive, ElementRef, HostBinding, OnChanges, SimpleChanges } from '@angular/core';

export function smoothHeight(name = 'smoothHeight', length = '0.2s ease') {
    return trigger('smoothHeight', [
        transition('void <=> *', [group([])]),
        transition(
            '* <=> *',
            [
                group([query('@*', [animateChild()], { optional: true })]),
                style({ height: '{{startHeight}}px' }),
                animate(length),
            ],
            {
                params: { startHeight: 0 },
            }
        ),
    ]);
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[smoothHeight]',
})
export class SmoothHeightDirective implements OnChanges, AfterViewInit {
    @HostBinding('@smoothHeight')
    get animation() {
        return { value: this.pulse, params: { startHeight: this.startHeight } };
    }

    pulse: boolean;
    startHeight: number;
    initialized = false;

    @HostBinding('style.display') styleDisplay = 'block';
    @HostBinding('style.overflow') styleOverflow = 'hidden';
    @HostBinding('style.position') stylePosition = 'relative';

    public constructor(private element: ElementRef) {}

    ngAfterViewInit() {
        this.initialized = true;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.initialized) {
            const startHeight = this.element.nativeElement.clientHeight;
            requestAnimationFrame(() => {
                this.startHeight = startHeight;
                this.pulse = !this.pulse;
            });
        }
    }
}
