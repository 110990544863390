import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { triggerChildren } from 'shared';

@Component({
    selector: 'app-collapsible',
    templateUrl: './collapsible.component.html',
    styleUrls: ['./collapsible.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    animations: [
        trigger('collapse', [
            state(
                'collapsed',
                style({
                    height: 0,
                    minHeight: 0,
                    opacity: 0,
                    marginTop: 0,
                    marginBottom: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                    pointerEvents: 'none',
                })
            ),
            state('expanded', style({})),
            transition('expanded => collapsed', [animate('.2s ease')]),
            transition('collapsed => expanded', [animate('.2s ease')]),
        ]),
        triggerChildren(),
    ],
})
export class CollapsibleComponent implements OnInit {
    @Input()
    public title: string;

    @Input()
    public icon: string;

    @Input('collapsed')
    public isCollapsed = true;

    @Input()
    public set canExpand(value: boolean) {
        this._canExpand = value;
        if (!value) this.isCollapsed = true;
    }

    public get canExpand(): boolean {
        return this._canExpand;
    }

    private _canExpand = true;

    ngOnInit(): void {
        if (this.isCollapsed === undefined) {
            this.isCollapsed = true;
        }
    }

    public toggle(): void {
        this.isCollapsed = !this.isCollapsed;
    }
}
