import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

type SortOrderType = 'asc' | 'desc';
type SortOptions = {
    sortOrder?: SortOrderType;
    sortOnTranslation?: boolean;
};

@Pipe({
    name: 'sort',
})
export class SortPipe implements PipeTransform {
    public constructor(private translate: TranslateService) {}

    public transform<T>(value: Array<T>, key: keyof T, options?: SortOptions): Array<T>;
    public transform<T>(value: Array<T>, ...args: Array<any>): Array<T> {
        value = value ?? [];
        const [key, options] = args;
        const { sortOrder, sortOnTranslation } = options ?? {};

        if (typeof key !== 'string') {
            console.warn(`Invalid key passed to TranslatedSortPipe: ${key}`);
            return value;
        }

        return this.sort(
            value,
            key as any,
            sortOrder,
            (sortOnTranslation ? this.getValueTranslated : this.getValue).bind(this)
        );
    }

    private sort<T extends { [key: string]: any }>(
        items: Array<T | undefined>,
        key: keyof T,
        sortOrder: SortOrderType = 'asc',
        getValue: (item: T, key: keyof T) => string
    ): Array<T> {
        return items.sort((a, b) => {
            const valueA = getValue(a, key);
            const valueB = getValue(b, key);

            return sortOrder === 'asc' ? valueA?.localeCompare(valueB) : valueB?.localeCompare(valueA);
        });
    }

    private getValue<T extends { [key: string]: any } | undefined>(item: T, key: keyof T): string | null {
        return item[key] !== undefined ? item[key] : null;
    }

    private getValueTranslated<T extends { [key: string]: any }>(item: T, key: keyof T): string | null {
        return item[key] !== undefined ? this.translate.instant(item[key]) : null;
    }
}
