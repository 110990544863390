import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Fuse from 'fuse.js';

export interface FuzzySearchPipeFlags {
    returnAllWithoutQuery: boolean;
    scoreThreshold: number;
    translate: boolean;
    limit: number;
}

const defaultFlags: FuzzySearchPipeFlags = {
    returnAllWithoutQuery: true,
    scoreThreshold: 0.5,
    translate: false,
    limit: -1,
};

@Pipe({
    name: 'fuzzySearch',
})
export class FuzzySearchPipe implements PipeTransform {
    public constructor(private translate: TranslateService) {}

    transform<T>(
        items: Array<T>,
        searchKeys: Array<Fuse.FuseOptionKeyObject<T>> | Array<string>,
        query?: string,
        flags?: Partial<FuzzySearchPipeFlags>
    ): Array<T>;

    transform<T>(
        items: Array<T>,
        searchKeys: Array<any>,
        query?: string,
        flags: Partial<FuzzySearchPipeFlags> = {}
    ): Array<T> {
        items = items ?? [];
        flags = { ...defaultFlags, ...flags };
        const limit = flags.limit >= 0 ? flags.limit : items.length;
        // Check if query has content
        if (!query?.trim()) return flags.returnAllWithoutQuery ? items.slice(0, limit) : [];
        // Translate key values if needed
        if (flags.translate) {
            searchKeys = searchKeys.map((key) => {
                const keyName: string = typeof key === 'object' ? (key as any).name : key;
                const newKeyName = `${keyName}_FuzzySearchPipe_PROP_TL__`;
                items.forEach((item) => {
                    const untranslated = this.getDeepValue(item, keyName);
                    if (untranslated) {
                        const translated = this.translate.instant(untranslated);
                        this.setDeepValue(item, newKeyName, translated);
                    }
                });
                return typeof key === 'object'
                    ? { ...(key as Fuse.FuseOptionKeyObject<T>), name: newKeyName }
                    : newKeyName;
            });
        }
        // Set up fuzzy search
        const fuse = new Fuse(items, {
            keys: searchKeys,
            shouldSort: true,
            threshold: flags.scoreThreshold,
        });
        // Execute fuzzy search
        const fuseResults: Array<Fuse.FuseResult<T>> = fuse.search<T>(query || '', { limit });

        // Map results back
        return fuseResults.map((res) => res.item);
    }

    private getDeepValue(obj: any, key: string): any {
        if (key?.includes('.')) {
            const [first, ...rest] = key.split('.');
            return this.getDeepValue(obj[first], rest.join('.'));
        }

        return obj[key];
    }

    private setDeepValue(obj: any, key: string, value: any): void {
        if (key?.includes('.')) {
            const [first, ...rest] = key.split('.');
            return this.setDeepValue(obj[first], rest.join('.'), value);
        }

        obj[key] = value;
    }
}
