<div class="menu-content">
    <app-control-menu-item
        [title]="(appState.user.stream | async)?.fullName"
        [clickable]="false"
        [header]="true"
        [subTitle]="tenantName | async"
    >
        <div class="value">
            <button class="btn btn-round btn-flat btn-small color-none" (click)="controlBarService.toggleMenu()">
                <i class="material-icons">clear</i>
            </button>
        </div>
    </app-control-menu-item>
    <app-control-menu-item
        [title]="'mapView.menu.language.title' | translate"
        [icon]="'outlined_flag'"
        [clickable]="false"
    >
        <span
            class="value"
            [title]="
                !(['Users.CreateProfiles.Self', 'Users.CreateProfiles.all'] | hasPermissions : false)
                    ? ('mapView.menu.language.permissions' | translate)
                    : ''
            "
        >
            <button
                *ngFor="let lang of languages"
                (click)="setLang(lang.id)"
                class="btn pill-option"
                [class.active]="lang.id === this.currentLang"
                [disabled]="!(['Users.CreateProfiles.Self', 'Users.CreateProfiles.all'] | hasPermissions : false)"
            >
                {{ lang.name }}
            </button>
        </span>
    </app-control-menu-item>
    <app-control-menu-item
        [title]="'mapView.menu.overview' | translate"
        navigation="map"
        icon="map"
    ></app-control-menu-item>
    <app-control-menu-item
        [title]="'mapView.menu.bulkPlanning' | translate"
        navigation="bulkPlanning"
        icon="today"
        *ngIf="'Tasks.TaskPlanning' | hasPermissions | async"
    ></app-control-menu-item>
    <app-control-menu-item
        [title]="'mapView.menu.history' | translate"
        navigation="history"
        icon="history"
    ></app-control-menu-item>
    <app-control-menu-item
        [title]="'mapView.menu.reporting' | translate"
        navigation="reporting"
        icon="insert_chart"
        *ngIf="Feature.ReportingDashboard | hasFeatures | async"
    ></app-control-menu-item>
    <app-control-menu-item
        [title]="'mapView.menu.settings' | translate"
        navigation="settings"
        icon="settings"
        *ngIf="['Users.ReadUsers'] | hasPermissions : false | async"
    ></app-control-menu-item>
    <app-control-menu-item
        [title]="'mapView.menu.devPortal' | translate"
        navigation="dev"
        icon="build"
        *ngIf="Feature.DevPortal | hasFeatures | async"
    ></app-control-menu-item>
    <app-control-menu-item
        [title]="'mapView.menu.logout' | translate"
        icon="exit_to_app"
        [action]="onLogOut"
    ></app-control-menu-item>
    <span class="build-version" (click)="onVersionClick()">MTP v{{ buildVersion }}</span>
</div>
