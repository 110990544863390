import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { vshrink } from 'shared';

import { ControlBarService } from '../../../../../../services/control-bar.service';

@Component({
    selector: 'app-control-menu-item',
    templateUrl: './control-menu-item.component.html',
    styleUrls: ['./control-menu-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [vshrink()],
})
export class ControlMenuItemComponent {
    @Input()
    title: string;

    @Input()
    subTitle: string;

    @Input()
    icon: string;

    @Input()
    navigation: string;

    @Input()
    clickable = true;

    @Input()
    header = false;

    @Input()
    action: () => void = this.defaultAction.bind(this);

    public constructor(private readonly router: Router, private readonly controlBarService: ControlBarService) {}

    private defaultAction(): void {
        if (this.clickable) {
            this.router.navigate([this.navigation]);
            this.controlBarService.showMenu(false);
        }
    }
}
