import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { ISharedEntity, ISharedTask, TaskApiService } from 'shared';

import { LazyTranslateLoader } from '../../utils/lazy-translate-loader';
import { IGeneratedUrl } from '../models/generated-url.interface';
import { AppSettingsService } from './app-settings.service';
import { MapService } from './map.service';

@Injectable({ providedIn: 'root' })
export class SharingService {
    private readonly _sharedEntity: BehaviorSubject<ISharedEntity> = new BehaviorSubject<ISharedEntity>(null);
    public readonly sharedEntity: Observable<ISharedEntity> = this._sharedEntity.asObservable();

    private constructor(
        private mapService: MapService,
        private taskApiService: TaskApiService,
        private appSettingsService: AppSettingsService,
        private translate: TranslateService
    ) {}

    // Public API

    public async generateShareUrl(
        itemId: string,
        itemType: 'TASK' | 'ISSUE',
        expiresAt: number,
        allowFinish: boolean
    ): Promise<IGeneratedUrl> {
        const settings = await firstValueFrom(this.appSettingsService.appSettings);
        const result = await firstValueFrom(
            this.taskApiService.generateShareUrl(itemId, itemType, expiresAt, allowFinish)
        );
        return {
            shareId: result.id,
            shareUrl: `${settings.url.app}${settings.url.app.endsWith('/') ? '' : '/'}shared/${result.tenantId}/${
                result.id
            }`,
        };
    }

    async updateShareUrl(shareId: string, expiresAt: number, allowFinish: boolean): Promise<IGeneratedUrl> {
        const settings = await firstValueFrom(this.appSettingsService.appSettings);
        const result = await firstValueFrom(this.taskApiService.updateShareUrl(shareId, expiresAt, allowFinish));
        return {
            shareId: result.id,
            shareUrl: `${settings.url.app}${settings.url.app.endsWith('/') ? '' : '/'}shared/${result.id}`,
        };
    }

    public async fetchSharedEntity(shareId: string, tenantId: string): Promise<ISharedEntity> {
        const entity = await firstValueFrom(this.taskApiService.getSharedEntity(shareId, tenantId));
        await this.mapService.fetchMapConfig(tenantId);
        await this.updateTranslations(entity);
        this.addNamedEntitySubType(entity);
        this._sharedEntity.next(entity);
        return entity;
    }

    public async disposeSharedEntity() {
        this._sharedEntity.next(null);
    }

    public getLocationForEntity(entity: ISharedEntity) {
        switch (entity.type) {
            case 'SHARED_TASK':
                return entity.task.location;
            case 'SHARED_ISSUE':
                return entity.issue.location;
        }
    }

    public updateSharedEntity(entity: ISharedEntity) {
        if (this._sharedEntity.value.id === entity.id) this._sharedEntity.next(entity);
    }

    public async finishSharedTask(): Promise<ISharedTask> {
        if (!this._sharedEntity.value || this._sharedEntity.value.type !== 'SHARED_TASK') return;
        const entityId = this._sharedEntity.value.id;
        const tenantId = this._sharedEntity.value.tenantId;
        // Store previous status in case of revert
        const previousStatus = (this._sharedEntity.value as ISharedTask).task.status;
        // Set 'DONE' status on current entity
        this._sharedEntity.next(
            Object.assign(this._sharedEntity.value, {
                task: Object.assign(this._sharedEntity.value.task, { status: 'DONE' }),
            })
        );
        // Finish
        let entity;
        try {
            entity = (await firstValueFrom(
                this.taskApiService.shareLinkAction(entityId, 'FinishTask', tenantId)
            )) as ISharedTask;
        } catch (e) {
            // Restore previous status on error
            this._sharedEntity.next(
                Object.assign(this._sharedEntity.value, {
                    task: Object.assign(this._sharedEntity.value.task, { status: previousStatus }),
                })
            );
            throw e;
        }
        await this.updateTranslations(entity);
        this.addNamedEntitySubType(entity);
        this._sharedEntity.next(entity);
        return entity;
    }

    // Private functions
    private addNamedEntitySubType(entity: ISharedEntity) {
        if (entity.subjectType) entity.subjectType.name = `subjectType.${entity.subjectType.id}`;
        switch (entity.type) {
            case 'SHARED_ISSUE':
                entity.issueType.name = `issueType.${entity.issueType.id}`;
                break;
            case 'SHARED_TASK':
                entity.taskType.name = `taskType.${entity.taskType.id}`;
                break;
        }
    }

    private async updateTranslations(entity: ISharedEntity) {
        // Wait for base translations to have loaded
        await firstValueFrom((this.translate.currentLoader as LazyTranslateLoader).translationsLoaded$);
        // Update translations
        if (entity.subjectType && typeof entity.subjectType.name === 'object') {
            Object.entries(entity.subjectType.name).forEach(([lang, name]) => {
                this.translate.setTranslation(lang, { subjectType: { [entity.subjectType.id]: name } }, true);
            });
        }
        switch (entity.type) {
            case 'SHARED_ISSUE':
                if (typeof entity.issueType.name === 'object') {
                    Object.entries(entity.issueType.name).forEach(([lang, name]) => {
                        this.translate.setTranslation(lang, { issueType: { [entity.issueType.id]: name } }, true);
                    });
                }
                break;
            case 'SHARED_TASK':
                if (typeof entity.taskType.name === 'object') {
                    Object.entries(entity.taskType.name).forEach(([lang, name]) => {
                        this.translate.setTranslation(lang, { taskType: { [entity.taskType.id]: name } }, true);
                    });
                }
                break;
        }
    }
}
