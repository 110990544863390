<div class="comment-content">
    <div class="block">
        <div class="comment-form">
            <div class="comment-text-container" @vshrink>
                <textarea
                    class="comment-input"
                    [placeholder]="'mapView.detail.comments.placeholder' | translate"
                    [(ngModel)]="textInput"
                    [disabled]="progress !== null"
                    maxlength="1024"
                ></textarea>
            </div>
            <div class="comment-image-container" @vshrink>
                <app-image-selector
                    #imageUpload
                    [fileLimit]="3"
                    (imagesChange)="setImages($event)"
                    [disabled]="progress !== null"
                ></app-image-selector>
            </div>
        </div>

        <div class="comment-controls" *ngIf="progress === null">
            <button
                class="btn btn-outline color-secondary"
                (click)="addComment()"
                [disabled]="
                    !(textInput?.trim().length > 0 || this.images?.length > 0) || totalRequestSize >= maxRequestSize
                "
                *ngIf="progress === null"
            >
                <span>{{ 'mapView.detail.comments.add' | translate }}</span>
            </button>
            <span class="warning" *ngIf="totalRequestSize >= maxRequestSize">
                {{ 'mapView.detail.comments.maxSize' | translate }}
            </span>
        </div>
        <div class="comment-controls" *ngIf="progress !== null">
            <app-image-upload-progress
                [progress]="progress * 100"
                [label]="'mapView.detail.comments.upload' | translate"
            ></app-image-upload-progress>
        </div>
    </div>
    <div class="block">
        <app-collapsible [title]="'mapView.detail.comments.title' | translate" [collapsed]="false">
            <div
                class="comment-block"
                *ngFor="let comment of comments; trackBy: trackCommentBy"
                @triggerChildren
                @vshrink
            >
                <app-comment-item [comment]="comment"></app-comment-item>
            </div>
        </app-collapsible>
    </div>
</div>
