<div class="popup-arrow"></div>
<app-context-list [style.min-width]="minWidth">
    <app-context-list-header *ngIf="header">{{ header | translate }}</app-context-list-header>
    <div *ngFor="let value of values" @listItem>
        <app-context-list-item
            appFocusable
            appFocusOnHover
            (click)="popupResult.emit(value.id)"
            (keydown.enter)="popupResult.emit(value.id)"
        >
            <i
                *ngIf="value.icon"
                [ngClass]="['material-symbols-outlined', 'item-icon-color-' + (value.iconColor ?? 'secondary')]"
            >
                {{ value.icon }}
            </i>
            <span>{{ value.label | translate }}</span>
        </app-context-list-item>
    </div>
</app-context-list>
