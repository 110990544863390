import { Directive, ElementRef, Input } from '@angular/core';
import { decode as decodeBlurHash } from 'blurhash';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[blurhash]',
})
export class BlurhashDirective {
    private _blurhash: string;
    @Input() set blurhash(value: string) {
        if (value !== this._blurhash) {
            this._blurhash = value;
            this.draw();
        }
    }

    public constructor(private el: ElementRef) {}

    draw() {
        try {
            const canvas = this.el.nativeElement;
            const ctx = this.el.nativeElement.getContext('2d');
            if (!this._blurhash) ctx.clearRect(0, 0, canvas.width, canvas.height);
            else {
                const pixels = decodeBlurHash(this._blurhash, canvas.width, canvas.height);
                const imageData = ctx.createImageData(canvas.width, canvas.height);
                imageData.data.set(pixels);
                ctx.putImageData(imageData, 0, 0);
            }
        } catch (e) {
            console.error('DRAW ERROR', e);
        }
    }
}
