import { Component, Input } from '@angular/core';

import { environment } from '../../../../../environments/environment';
import { Entity } from '../../../../models/entity.model';


@Component({
    selector: 'app-entity-details-list-item',
    templateUrl: './entity-details-list-item.component.html',
    styleUrls: ['./entity-details-list-item.component.scss'],
})
export class EntityDetailsListItemComponent {
    @Input() public title: string;
    @Input() public subtitle: string;
    @Input() public entity: Entity;

    protected devMode = environment.env === 'DEV';
}
