<div class="thumb" *ngIf="image">
    <ng-container *ngVar="image.url | authImage | async as imageUrl">
        <div
            class="image-img"
            *ngIf="imageUrl"
            [style.opacity]="imageUrl ? 1.0 : 0.0"
            [style.background-image]="'url(' + imageUrl + ')' | sanitizeStyle"
        ></div>
        <canvas [blurhash]="image.blurHash" [style.opacity]="imageUrl ? 0.0 : 1.0"></canvas>
        <ogs-spinner *ngIf="!imageUrl" @fade></ogs-spinner>
    </ng-container>
</div>
