import { Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { fadeUp, vshrink } from 'shared';

import { BasePopupComponent } from '../base-popup/base-popup.component';

export interface SimpleSelectPopupOption<TValue> {
    id: TValue;
    label: string;
    icon?: string;
    iconColor?: 'black' | 'secondary' | 'caution';
}

@Component({
    selector: 'app-simple-select-popup',
    templateUrl: './simple-select-popup.component.html',
    styleUrls: ['./simple-select-popup.component.scss'],
    animations: [fadeUp('popup'), vshrink('listItem'), vshrink('listHeader')],
})
export class SimpleSelectPopupComponent<TValue> extends BasePopupComponent<SimpleSelectPopupComponent<TValue>> {
    @Output() public popupResult: EventEmitter<TValue> = new EventEmitter<TValue>();
    public values: Array<SimpleSelectPopupOption<TValue>>;
    public header?: string;
    public minWidth?: string;

    public constructor(elementRef: ElementRef) {
        super(elementRef);
        this.popupOptions.hasArrow = true;
    }
}
