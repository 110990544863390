import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { IOrdinal, fadeUp, vshrink } from 'shared';

import { MapService } from '../../../../services/map.service';
import { BasePopupComponent } from '../../../modals/base-popup/base-popup.component';

@Component({
    selector: 'app-ordinal-select-popup',
    templateUrl: './ordinal-select-popup.component.html',
    styleUrls: ['./ordinal-select-popup.component.scss'],
    animations: [fadeUp('popup'), vshrink('listItem'), vshrink('listHeader')],
})
export class OrdinalSelectPopupComponent extends BasePopupComponent<OrdinalSelectPopupComponent> {
    @Input() public ordinals: IOrdinal[] = [];
    @Output() public ordinalSelect: EventEmitter<number> = new EventEmitter<number>();

    public constructor(elementRef: ElementRef, public mapService: MapService) {
        super(elementRef);
        this.popupOptions.hasArrow = true;
    }
}
