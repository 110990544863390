<app-entity-list-item
    [entity]="task"
    [title]="title || (task | entityName : 'TASK' | async)"
    [subtitle]="subtitle || (task | entityLocationDescription | async)"
    [summary]="hideSummary ? null : descriptionSummary"
    [expanded]="expanded"
    [clickable]="clickable"
    [selected]="selected"
    *ngIf="task"
    #listItem
>
    <app-marker type="TASK_TYPE" [data]="taskType"></app-marker>

    <!-- SIMPLE VIEW -->
    <div class="expanded-content" *ngIf="listItem.expanded && simpleView && showActions" @fade>
        <app-simple-entity-actions
            [actions]="actions"
            [disableOnSocketDisconnect]="disableOnSocketDisconnect"
            [showOverflow]="showOverflowActions"
        ></app-simple-entity-actions>
    </div>
    <button
        class="btn btn-small btn-round btn-flat btn-details-simple entity-action"
        *ngIf="simpleView && (showDetailsComputed$ | async)"
        (click)="goToDetails($event)"
    >
        <span>i</span>
    </button>
    <!-- ADVANCED VIEW -->
    <div class="expanded-content" *ngIf="listItem.expanded && !simpleView && showActions" @fade>
        <app-advanced-entity-actions
            [actions]="actions"
            [disableOnSocketDisconnect]="disableOnSocketDisconnect"
            [collapseAfter]="advancedEntityActionsCollapseAfter$ | async"
            [showOverflow]="showOverflowActions"
        ></app-advanced-entity-actions>
    </div>
    <app-entity-status
        class="entity-action"
        entityType="TASK"
        [status]="task.status"
        *ngIf="!simpleView"
    ></app-entity-status>
    <div class="entity-assignee entity-action" @vshrink *ngIf="!simpleView && assigneeName$ | async as name">
        &#64;{{ name }}
    </div>
    <div class="entity-sub-summary" *ngIf="task.pickupAfterDate || task.finishBeforeDate">
        <span class="task-time-label" [title]="'mapView.report-wizard.miscProps.pickupAfter' | translate">
            <i class="material-icons">schedule</i>
            {{ task.pickupAfterDate ? (task.pickupAfterDate * 1000 | date : 'HH:mm') : '--' }}
        </span>
        <span class="task-time-label" [title]="'mapView.report-wizard.miscProps.finishBefore' | translate">
            <i class="material-icons">sports_score</i>
            {{ task.finishBeforeDate ? (task.finishBeforeDate * 1000 | date : 'HH:mm') : '--' }}
        </span>
    </div>
    <div class="btn-row expanded-content" *ngIf="!simpleView">
        <button class="btn btn-small" (click)="goToDetails($event)" *ngIf="showDetailsComputed$ | async">
            <span>{{ 'comp.task-list-item.action.details' | translate }}</span>
            <i class="material-icons">keyboard_arrow_right</i>
        </button>
    </div>
</app-entity-list-item>
<app-list-item-flags [urgent]="task?.urgent" [comments]="!!task?.numberOfComments" @hshrink></app-list-item-flags>
<ng-content></ng-content>
