import { isJestTest as e } from "./environment.js";
function t(e) {
  e.preventDefault(), e.stopPropagation();
}
const n = (t, n, r) => {
  t.hasAttribute(n) ? r(t.getAttribute(n)) : void 0 !== t[n] && r(t[n]), !t._valueTracker || "checked" !== n && "value" !== n || (t._valueTracker = null);
  const o = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(t), n);
  return o && !e() && Object.defineProperty(t, n, {
    get: o.get,
    set: e => {
      r(e), o.set.call(t, e);
    }
  }), s(t, n, e => r(e));
};
function r(e) {
  return new Promise(t => {
    const n = () => {
      t(null), e.__cdsTouched = !0;
    };
    e.__cdsTouched && t(null), e.addEventListener("mouseover", n, {
      once: !0,
      passive: !0
    }), e.addEventListener("touchstart", n, {
      once: !0,
      passive: !0
    }), e.addEventListener("keydown", n, {
      once: !0,
      passive: !0
    }), e.addEventListener("focus", n, {
      once: !0,
      passive: !0
    });
  });
}
function o(e, t) {
  const n = new MutationObserver(e => {
    for (const n of e) "childList" === n.type && t(n);
  });
  return n.observe(e, {
    childList: !0
  }), n;
}
function s(e, t, n) {
  const r = new MutationObserver(r => {
    r.find(e => e.attributeName === t) && n(e.getAttribute(t));
  });
  return r.observe(e, {
    attributes: !0
  }), r;
}
function i(e, t, n) {
  const r = new MutationObserver(r => {
    const o = r.find(e => t.find(t => e.attributeName === t));
    o && n(e.getAttribute(o.attributeName));
  });
  return r.observe(e, {
    attributes: !0,
    attributeFilter: t,
    subtree: !0
  }), r;
}
export { n as getElementUpdates, s as listenForAttributeChange, i as listenForAttributeListChange, o as onChildListMutation, r as onFirstInteraction, t as stopEvent };
