<div class="button-container">
    <button
        class="segmented-button"
        [class.active]="option.id === this.selectedOption?.id"
        *ngFor="let option of options"
        (click)="this.selectedOption = option; this.selectedOptionChange.emit(option)"
    >
        {{ option.label | translate }}
    </button>
</div>
