export const environment = {
    env: 'STAGING',
    authGuards: true,
    supportedLocales: ['en', 'nl'],
    defaultLocale: 'nl',
    fallbackLocale: 'en',
    mapBoxDefaults: {
        token: 'pk.eyJ1IjoiYmFzZWZsb3ctZGV2IiwiYSI6ImNrZGhnaThscDJmNnoydG1oeGxyMDNobXYifQ.o3ycq_OmAoMlQwDye8eXGw',
        style: 'mapbox://styles/baseflow-dev/ckdhh123q16v51ip4ptcbs4tb',
    },
};
