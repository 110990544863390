<label class="checkbox-container" (click)="onLabelClick($event)" [class.disabled]="disabled">
    <input
        class="entity-list-item-box"
        type="checkbox"
        [ngModel]="checked"
        (ngModelChange)="onCheckChange($event)"
        [disabled]="disabled"
        (blur)="onBlur()"
    />
    <span class="checkmark" [class.highlighted]="highlighted"></span>
</label>
