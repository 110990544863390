<div [class]="'modal modal-lg'">
    <div class="modal-header">
        <div class="modal-title">
            {{ options.title || 'comp.confirm-info-popup.title-edit' | translate }}
        </div>
        <button class="modal-close" (click)="close(false, $event)">
            <i class="material-icons">clear</i>
        </button>
    </div>
    <div class="modal-content">
        <div
            class="message"
            [innerHTML]="options.message | translate : { filterSetName: filterSetName ?? 'undefined' }"
        ></div>
        <div class="filter-changes-container">
            <ul class="changes-list" *ngIf="addedSorting.length > 0 || removedSorting.length > 0">
                <li class="changed-item-list-item" *ngIf="addedSorting.length > 0">
                    <app-icon class="icon icon-added" [icon]="'add'" [style]="'outlined'"></app-icon>
                    <div class="row-show">
                        <span class="filter-type-title">Sorting:</span>
                        <span class="filter-type-item" *ngFor="let item of addedSorting; let last = last">
                            {{ formatItem(item, last) }}
                        </span>
                    </div>
                </li>
                <li class="changed-item-list-item" *ngIf="removedSorting.length > 0">
                    <app-icon class="icon icon-removed" [icon]="'remove'" [style]="'outlined'"></app-icon>
                    <div class="row-show">
                        <span class="filter-type-title">Sorting:</span>
                        <span class="filter-type-item" *ngFor="let item of removedSorting; let last = last">
                            {{ formatItem(item, last) }}
                        </span>
                    </div>
                </li>
            </ul>
            <ul class="changes-list" *ngIf="updatedFilters.length > 0">
                <li class="changed-item-list-item" *ngFor="let item of updatedFilters">
                    <app-icon
                        [class]="item.updated === 'added' ? 'icon icon-added' : 'icon icon-removed'"
                        [icon]="item.updated === 'added' ? 'add' : 'remove'"
                        [style]="'outlined'"
                    ></app-icon>

                    <div class="row-show">
                        <ng-container>
                            <span class="filter-type-title">
                                {{ 'history.table.headers.' + item.property | translate }}:
                            </span>
                        </ng-container>

                        <ng-container>
                            <span
                                class="filter-type-item"
                                *ngFor="
                                    let value of item.updatedValues | slice : 0 : cutoffLength;
                                    let lastValue = last
                                "
                            >
                                {{
                                    (value.toLowerCase() | translate) +
                                        (!lastValue || item.updatedValues.length > cutoffLength ? ', ' : '')
                                }}
                            </span>
                            <span
                                class="filter-type-item"
                                *ngIf="item.updatedValues.length > cutoffLength && !showMore[item.property]"
                            >
                                ...
                                <a class="more-toggle" (click)="toggleMoreValues(item, $event)">
                                    {{ 'comp.confirm-info-popup.showMore' | translate }}
                                </a>
                            </span>
                        </ng-container>
                        <ng-container *ngIf="item.updatedValues.length > cutoffLength && showMore[item.property]">
                            <span
                                class="filter-type-item"
                                *ngFor="let value of item.updatedValues | slice : cutoffLength; let lastValue = last"
                            >
                                {{ (value.toLowerCase() | translate) + (!lastValue ? ', ' : '') }}
                            </span>
                            <span class="filter-type-item">
                                <a class="more-toggle" (click)="toggleMoreValues(item, $event)">
                                    &nbsp;
                                    {{ 'comp.confirm-info-popup.showLess' | translate }}
                                </a>
                            </span>
                        </ng-container>
                    </div>
                </li>
            </ul>

            <h3 *ngIf="checkArrays()">{{ 'comp.confirm-info-popup.errors.noChangedFilters' | translate }}</h3>
        </div>
    </div>
    <div class="modal-btn-row">
        <button class="btn btn-small" (click)="close(true)" [ngClass]="'color-secondary'">
            <span>
                {{ 'comp.confirm-info-popup.edit' | translate }}
            </span>
        </button>

        <button class="btn btn color-primary btn-outline btn-small" (click)="close(false, $event)">
            <span>
                {{ 'comp.confirm-info-popup.close' | translate }}
            </span>
        </button>
    </div>
</div>
