import { Component, ElementRef, Input } from '@angular/core';
import { ILocation, fadeDown } from 'shared';

import { MapService } from '../../../services/map.service';
import { LocationSelectResult } from '../../inputs/location-select/location-select-list/location-select-list.component';
import { MapComponent } from '../map/map.component';

@Component({
    selector: 'app-map-search-bar',
    templateUrl: './map-search-bar.component.html',
    styleUrls: ['./map-search-bar.component.scss'],
    animations: [fadeDown('context')],
})
export class MapSearchBarComponent {
    protected open = false;
    @Input() public map?: MapComponent;

    public constructor(private hostEl: ElementRef, private mapService: MapService) {}

    protected getLocation(result: LocationSelectResult): ILocation {
        if (!result) return null;

        switch (result.type) {
            case 'POI':
                return { ...result.poi.location };
            case 'EXACT':
            case 'CURRENT_LOCATION':
                return result.location;
        }
    }

    protected onSelect(result: LocationSelectResult): void {
        this.map.flyTo(this.getLocation(result), 20);
    }
}
