import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { IUser, fadeUp, vshrink } from 'shared';

import { UserFilter } from '../../../../models/user-filter.model';
import { allowAll } from '../../../../utils/allow-all.filter';
import { FuzzySearchPipe } from '../../../pipes/fuzzy-search.pipe';
import { SortPipe } from '../../../pipes/sort.pipe';
import { UserService } from '../../../services/user.service';
import { BaseSearchablePopupComponent } from '../base-popup/base-searchable-popup.component';

const MAX_RESULTS = 6;

@Component({
    selector: 'app-user-select-popup',
    templateUrl: './user-select-popup.component.html',
    styleUrls: ['./user-select-popup.component.scss'],
    animations: [fadeUp('popup'), vshrink('listItem'), vshrink('listHeader')],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSelectPopupComponent extends BaseSearchablePopupComponent<IUser> {
    // Options
    protected hideFiltered = true;

    protected get userFilter(): UserFilter {
        return this._userFilter ?? allowAll;
    }

    protected set userFilter(value: UserFilter) {
        this._userFilter = value;
    }

    private _userFilter?: UserFilter;

    public constructor(
        elementRef: ElementRef,
        sortPipe: SortPipe,
        fuzzySearchPipe: FuzzySearchPipe,
        protected readonly userService: UserService
    ) {
        super(elementRef, sortPipe, fuzzySearchPipe);

        this.initialize(
            'fullName',
            this.userService.filterUsers(this.hideFiltered ? this.userFilter : null),
            MAX_RESULTS
        );
    }

    protected async selectUser(user?: IUser): Promise<void> {
        if (user && !(await firstValueFrom(this.userFilter(user)))) return;
        this.popupResult.emit(user);
    }
}
