<div *ngIf="images?.length" [class]="showAsGrid ? 'grid' : 'block'">
    <div class="block-title" *ngIf="title">{{ title | translate }}</div>
    <div class="photo-row">
        <app-image-thumb
            [class.large]="largeFirstTile"
            *ngFor="let image of images; let index = index"
            [image]="image"
            (click)="openImages(index)"
        ></app-image-thumb>
        <!-- Moved to story AS-926 -->
        <!-- <button class="btn btn-outline color-secondary photo-add">-->
        <!-- <i class="material-icons">photo_camera</i>-->
        <!-- <span class="photo-add-text">{{'comp.image-selector.addImage' | translate}}</span>-->
        <!-- </button>-->
    </div>
</div>
