import { Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { fadeUp, vshrink } from 'shared';

import { IBulkAction } from '../../util/bulk-action-bar/interfaces/bulk-action.interface';
import { BasePopupComponent } from '../base-popup/base-popup.component';

@Component({
    selector: 'app-actions-popup',
    templateUrl: './actions-popup.component.html',
    styleUrls: ['./actions-popup.component.scss'],
    animations: [fadeUp('popup'), vshrink('listItem'), vshrink('listHeader')],
})
export class ActionsPopupComponent extends BasePopupComponent<ActionsPopupComponent> {
    @Output() popupResult: EventEmitter<void> = new EventEmitter<void>();

    public actions: Array<IBulkAction>;
    public selectionCount: number;
    public isDisabled: (action: IBulkAction) => Observable<boolean>;

    public header?: string;

    public constructor(elementRef: ElementRef) {
        super(elementRef);
        this.popupOptions.hasArrow = true;
    }
}
