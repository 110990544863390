import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Pipe({
    name: 'sanitizeUrl',
})
export class SanitizeUrlPipe implements PipeTransform {
    public constructor(private sanitizer: DomSanitizer) {}

    transform(value: string): SafeStyle {
        return this.sanitizer.bypassSecurityTrustUrl(value);
    }
}
