<div class="popup-arrow"></div>
<app-context-list>
    <app-context-list-header>{{ 'comp.ordinal-select-popup.header' | translate }}</app-context-list-header>
    <div *ngFor="let ordinal of ordinals" @listItem>
        <app-context-list-item
            appFocusable
            appFocusOnHover
            (click)="ordinalSelect.emit(ordinal.id)"
            (keydown.enter)="ordinalSelect.emit(ordinal.id)"
        >
            {{ ordinal.name }}
            <b>&nbsp;·&nbsp;L{{ ordinal.id }}</b>
        </app-context-list-item>
    </div>
</app-context-list>
