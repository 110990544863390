import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
    /***
     * The key of the icon to display. This can be either an icon or a symbol.
     * @param value The key of the icon to display.
     * @example
     * 'icon-add' for an "add" icon
     * 'symbol-add' for an "add" symbol
     * 'add' for an "add" icon, as omitting the prefix defaults to an icon.
     */
    @Input()
    public set key(value: string) {
        if (value.startsWith('icon-')) {
            this._icon = value.replace('icon-', '');
        } else if (value.startsWith('symbol-')) {
            this._symbol = value.replace('symbol-', '');
        } else {
            this._icon = value;
        }

        this.setIconClass();
    }

    /***
     * The icon to display.
     * @param value The key of the icon to display.
     * @example
     * 'add' for an "add" icon.
     */
    @Input()
    public set icon(value: string) {
        this._icon = value;
        this.setIconClass();
    }

    /***
     * The symbol to display.
     * @param value The key of the symbol to display.
     * @example
     * 'add' for an "add" symbol.
     */
    @Input()
    public set symbol(value: string) {
        this._symbol = value;
        this.setIconClass();
    }

    /***
     * The style of the icon or symbol to display.
     * @param value The style name.
     */
    @Input()
    public set style(value: 'outlined' | 'round' | 'sharp' | 'two-tone') {
        this._style = value;
        this.setIconClass();
    }

    protected get value(): string {
        return this._icon ?? this._symbol;
    }

    protected get iconClass(): string {
        return this._iconClass;
    }

    private _icon: string;
    private _symbol: string;
    private _iconClass: string;
    private _style: string;

    private setIconClass(): void {
        this._iconClass = ['material', this._icon ? 'icons' : 'symbols', this._style].filter(Boolean).join('-');
    }
}
