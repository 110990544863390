import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { orderBy } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { fadeUp } from 'shared';

import { BaseFilterInputComponent } from '../base-filter-input/base-filter-input.component';

export interface FilterOption {
    value: string;
    label: string;
}

interface DropDownOption extends FilterOption {
    checked: boolean;
}

@Component({
    selector: 'app-entity-filter',
    templateUrl: './entity-filter.component.html',
    styleUrls: ['./entity-filter.component.scss'],
    animations: [fadeUp()],
})
export class EntityFilterComponent extends BaseFilterInputComponent<Array<string>> implements OnInit, OnChanges {
    @Input()
    filterOptions: Array<FilterOption>;

    // TODO revert behaviour to separate input for checked values, to support both external and internal storage of checked values.
    @Input()
    resetRangeChecked = true;

    dropDownList: Array<DropDownOption>;
    dropDownFilter: string;

    checkedList: Array<DropDownOption> = [];

    public constructor(private translate: TranslateService, private toastr: ToastrService) {
        super();
    }

    ngOnInit(): void {
        this.dropDownList = this.dropDownList || [];
        this.checkedList = this.checkedList || [];
        this.showDropDown = false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filterOptions?.currentValue) {
            const dropDownList = (changes.filterOptions.currentValue as Array<DropDownOption>).filter((o) => !!o.label);
            this.dropDownList = this.resetRangeChecked
                ? dropDownList
                : dropDownList
                      .filter((o) => o.label !== undefined && o.label !== null && o.label !== '')
                      .map((o) => {
                          o.checked = this.checkedList.find((co) => co.value === o.value)?.checked;
                          return o;
                      });
            this.reevaluateSelection();
        }
    }

    onChange(status: boolean, value: DropDownOption): void {
        value.checked = status;
        if (value.checked) {
            this.checkedList.push(value);
        } else {
            this.checkedList.splice(this.checkedList.indexOf(value), 1);
        }
        this.selectedChangeFromChecked();
        this.sortDropDownList();
    }

    reevaluateSelection(): void {
        this.sortDropDownList();
        this.getCheckedListFromDropDownList();
        this.selectedChangeFromChecked();
    }

    selectedChangeFromChecked(): void {
        this.selectedChange.next(this.checkedList.map((o) => o.value));
    }

    sortDropDownList(): void {
        this.dropDownList = orderBy(
            this.dropDownList,
            [
                'checked',
                (item): DropDownOption => typeof item.label === 'string' && this.translate.instant(item.label),
                'value',
            ],
            ['asc', 'asc', 'asc']
        );
    }

    getCheckedListFromDropDownList(): void {
        this.checkedList = this.dropDownList.filter((o) => o.checked);
    }

    resetInput(): void {
        this.checkedList = [];
        this.dropDownList.forEach((dropDownOption) => (dropDownOption.checked = false));
        this.selectedChange.next([]);
    }

    protected trackByOption(index: number, item: DropDownOption): string {
        return item.value;
    }

    protected onOptionClick(disabled: boolean): void {
        if (disabled) {
            this.toastr.error(
                this.translate.instant('history.toasts.maxFiltersReached.message'),
                this.translate.instant('history.toasts.maxFiltersReached.title')
            );
        }
    }
}
