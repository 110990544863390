import 'reflect-metadata';
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';
import { filter, timeout } from 'rxjs/operators';

const AFTER_VIEW_INIT_PROP_NAME = '__SUPPORT_AS_DECORATOR_AFTER_VIEW_INIT';
const AsSubjectKey = Symbol('AsSubjectKey');
const VCAsSubjectKey = Symbol('VCAsSubjectKey');

type TContext = { [AFTER_VIEW_INIT_PROP_NAME]?: BehaviorSubject<boolean> };
type TSubjectContext<TType> = TContext & { [key: string]: TType | undefined };

interface AsSubjectPropertyMetadata {
    subjectField: string;
    targetField: string;
}

export function AsSubjectSupport<T>(constructor: new (...args: Array<never>) => T): void {
    extendOnInit(constructor);
    extendAfterViewInit(constructor);
    extendOnDestroy(constructor);
}

export function AsSubject(targetField?: string): PropertyDecorator {
    return asSubjectInternal(AsSubjectKey, targetField);
}

export function ViewChildAsSubject(targetField?: string): PropertyDecorator {
    return asSubjectInternal(VCAsSubjectKey, targetField);
}

function extendOnInit<T>(constructor: new (...args: Array<never>) => T): void {
    // Handle fields marked with @AsSubject
    const onInit = constructor.prototype.ngOnInit;

    constructor.prototype.ngOnInit = function (...args: Array<unknown>): void {
        const props = getAsSubjectProperties(this);
        const ctx = this as TSubjectContext<BehaviorSubject<unknown>>;
        ctx[AFTER_VIEW_INIT_PROP_NAME] ??= new BehaviorSubject<boolean>(false);
        props.forEach((property) => {
            let value: unknown;
            const subject: BehaviorSubject<unknown> | undefined = ctx[property.subjectField];
            const currentValue = ctx[property.targetField];
            Object.defineProperty(this, property.targetField, {
                get() {
                    return value;
                },
                set(v) {
                    value = v;
                    if (subject && !subject.closed) subject.next(v);
                },
            });
            ctx[property.targetField] = currentValue;
        });
        if (onInit) onInit.apply(this, args);
    };
}

function extendAfterViewInit<T>(constructor: new (...args: Array<never>) => T): void {
    // Handle fields marked with @ViewChildAsSubject
    const afterViewInit = constructor.prototype.ngAfterViewInit;

    constructor.prototype.ngAfterViewInit = function (...args: Array<unknown>): void {
        const ctx = this as TSubjectContext<Subject<unknown>>;
        const afterViewInitPassed: BehaviorSubject<boolean> | undefined = ctx[AFTER_VIEW_INIT_PROP_NAME];
        if (afterViewInitPassed) afterViewInitPassed.next(true);

        const props = getVCAsSubjectProperties(this);
        props.forEach((property) => {
            let value: unknown;
            const propStream = ctx[property.subjectField];
            const currentValue = ctx[property.targetField];
            Object.defineProperty(this, property.targetField, {
                get() {
                    return value;
                },
                set(v) {
                    value = v;
                    firstValueFrom(
                        afterViewInitPassed.pipe(
                            filter(Boolean),
                            timeout(5000),
                            filter(() => propStream && !propStream.closed)
                        )
                    )
                        .catch()
                        .then(() => {
                            propStream.next(v);
                        });
                },
            });
            ctx[property.targetField] = currentValue;
        });
        if (afterViewInit) afterViewInit.apply(this, args);
    };
}

function extendOnDestroy<T>(constructor: new (...args: Array<never>) => T): void {
    // Clean up AfterViewInit subject on destroy
    const onDestroy = constructor.prototype.ngOnDestroy;

    constructor.prototype.ngOnDestroy = function (...args: Array<unknown>): void {
        const ctx = this as TContext;
        const afterViewInitPassed: BehaviorSubject<boolean> | undefined = ctx[AFTER_VIEW_INIT_PROP_NAME];
        if (afterViewInitPassed) {
            afterViewInitPassed.complete();
            delete ctx[AFTER_VIEW_INIT_PROP_NAME];
        }
        if (onDestroy) onDestroy.apply(this, args);
    };
}

function asSubjectInternal(metadataKey: symbol, targetField?: string): PropertyDecorator {
    return (target: unknown, subjectField: string | symbol): void => {
        if (typeof subjectField === 'symbol') {
            console.warn('Subject targeted with @ViewChildAsSubject cannot not be a symbol');
            return;
        }
        const subjectFieldString = subjectField as string;
        // Automatically determine targetField if it is not explicitly set
        if (!targetField) {
            if (subjectFieldString.endsWith('$')) {
                targetField = subjectFieldString.slice(0, subjectFieldString.length - 1);
            } else {
                console.warn(
                    'Subject targeted with @ViewChildAsSubject should either explicitly target another field, or end its name with "$"'
                );
                targetField = '__NON_EXISTING_FIELD__';
            }
        }
        // Register metadata
        let properties: AsSubjectPropertyMetadata[] = Reflect.getMetadata(metadataKey, target);
        if (properties) {
            properties.push({ subjectField: subjectFieldString, targetField });
        } else {
            properties = [{ subjectField: subjectFieldString, targetField }];
            Reflect.defineMetadata(metadataKey, properties, target);
        }
    };
}

function getAsSubjectProperties(origin: object): AsSubjectPropertyMetadata[] {
    return (Reflect.getMetadata(AsSubjectKey, origin) ?? []) as AsSubjectPropertyMetadata[];
}

function getVCAsSubjectProperties(origin: object): AsSubjectPropertyMetadata[] {
    return (Reflect.getMetadata(VCAsSubjectKey, origin) ?? []) as AsSubjectPropertyMetadata[];
}
