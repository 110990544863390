import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUser } from 'shared';

import { AppStateService } from '../services/state/app-state.service';

@Pipe({
    name: 'user',
    pure: true,
})
export class UserPipe implements PipeTransform {
    public constructor(private readonly appState: AppStateService) {}

    transform(userId: string): Observable<IUser> {
        return this.appState.users.stream.pipe(map((users) => (users || []).find((user) => user.id === userId)));
    }
}
