import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getDistance as geolib_getDistance } from 'geolib';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ILocation, IPOI } from 'shared';

import { POIService } from './poi.service';

@Injectable({ providedIn: 'root' })
export class LocationService {
    public constructor(private readonly translate: TranslateService, private readonly poiService: POIService) {}

    /**
     * Returns a description of the location, either the POI description if obtainable or a description of the closest POI based on the location
     * @param location The location to get the description for
     * @param poi The POI to use for the description, if not provided the closest POI will be used, based on the location parameter
     */
    public getLocationDescription(location: ILocation, poi?: string | IPOI): Observable<string> {
        return (typeof poi === 'string' ? this.poiService.getPOIForId(poi) : of(poi)).pipe(
            switchMap((poi) =>
                poi && poi.description
                    ? of(poi.description)
                    : this.poiService.getClosestPOI(location).pipe(
                          switchMap((poi) => {
                              if (!poi || geolib_getDistance(poi.location, location, 0.01) > 30)
                                  return this.translate.stream('misc.farOutLocation');
                              return this.translate.stream('misc.nearbyLocation', { poiName: poi.description });
                          })
                      )
            )
        );
    }
}
