import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { fadeUp, vshrink } from 'shared';

import { BasePopupComponent } from '../../../../mtp-common/components/modals/base-popup/base-popup.component';
import { TaskService } from '../../../../mtp-common/services/task.service';

@Component({
    selector: 'app-block-reason-popup',
    templateUrl: './block-reason-popup.component.html',
    styleUrls: ['./block-reason-popup.component.scss'],
    animations: [fadeUp('popup'), vshrink()],
})
export class BlockReasonPopupComponent extends BasePopupComponent<BlockReasonPopupComponent> implements OnInit {
    taskId: string;
    blockReasonId: string;
    blockReasonNote: string;

    public constructor(elementRef: ElementRef, public taskService: TaskService) {
        super(elementRef);
        this.popupOptions.padding = 0;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    @HostListener('click', ['$event'])
    clickBg($event: MouseEvent): void {
        if (this.closable && this.elementRef.nativeElement === $event.target) {
            $event.stopImmediatePropagation();
            this.__DOM_COMPONENT.remove();
        }
    }

    async onSubmit(): Promise<void> {
        const blockReasonId: string = this.blockReasonId && this.blockReasonId !== 'CUSTOM' ? this.blockReasonId : null;
        const blockReasonNote: string = blockReasonId ? null : this.blockReasonNote;
        await this.taskService.blockTask(this.taskId, blockReasonId, blockReasonNote);
        this.__DOM_COMPONENT.remove();
    }

    close(): void {
        this.__DOM_COMPONENT.remove();
    }
}
