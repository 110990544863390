import * as turf from '@turf/turf';
import { isEqual } from 'lodash';
import mapboxgl, { Anchor } from 'mapbox-gl';
import { BehaviorSubject, Observable, filter, from } from 'rxjs';
import { distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ILocatable, IOrdinal } from 'shared';

import { IIdentifiable } from '../../../../../../models/identifiable.model';
import { MapHelper } from '../../../helpers/map.helper';
import { MapMarkerClickEvent } from '../../map.component';
import { MapLayerController } from './map-layer-controller';

export type QueryHandler<T> = (bounds: GeoJSON.Polygon, floor: IOrdinal) => Observable<Array<T>>;

export class GenericMapMarkerClickEvent<EntityType> extends MapMarkerClickEvent {
    public entity: EntityType;
    public type?: string;

    public constructor(marker: mapboxgl.Marker, controller: MapLayerController, entity: EntityType, type?: string) {
        super(marker, controller);
        this.entity = entity;
        this.type = type;
    }
}

export interface CachedMarker<EntityType> {
    marker?: mapboxgl.Marker;
    entity: EntityType;
    focus: MarkerFocus;
}

export type MarkerFocus = 'none' | 'focused' | 'blurred';

export abstract class GenericMarkerMapLayerController<
    EntityType extends ILocatable & IIdentifiable,
    CachedMarkerType extends CachedMarker<EntityType> = CachedMarker<EntityType>
> extends MapLayerController {
    protected markerCache: Array<CachedMarkerType> = [];
    private whitelist = new BehaviorSubject<Array<IIdentifiable['id']> | null>(null);
    private entityStream = new BehaviorSubject<Observable<Array<EntityType>> | null>(null);
    private entities = this.entityStream.pipe(
        switchMap((stream) => (stream ?? from([])) as Observable<EntityType[]>),
        switchMap((stream) => {
            return this.whitelist.pipe(
                map((whitelist) =>
                    whitelist === null ? stream : stream.filter((entity) => whitelist.includes(entity.id))
                )
            );
        })
    );

    private markerAnchor: Anchor = 'center';
    private focusedEntityId?: unknown;
    private handleRendering = true;
    private queryHandler: QueryHandler<EntityType>;

    public constructor(
        options?: Partial<{
            queryHandler: QueryHandler<EntityType>;
            handleRendering: boolean;
            markerAnchor: Anchor;
        }>
    ) {
        super();
        Object.assign(
            this,
            {
                queryHandler: () => from([]),
            },
            options ?? {}
        );
    }

    public focusEntity(id?: IIdentifiable['id']): void {
        this.focusedEntityId = id;
        this.markerCache.forEach((m) => {
            if (!id) {
                // No entity is focused. Remove marker selection
                m.focus = 'none';
            } else if (m.entity.id === id) {
                // The current marker is the focused entity. Select it.
                m.focus = 'focused';
            } else {
                // The current marker is not the focused entity. Blur it.
                m.focus = 'blurred';
            }
            const classList = m.marker?.getElement()?.firstElementChild?.classList;
            classList.toggle('focused', m.focus === 'focused');
            classList.toggle('blurred', m.focus === 'blurred');
        });
    }

    public setWhitelist(ids?: Array<IIdentifiable['id']>): void {
        this.whitelist.next(ids ?? null);
    }

    public buildElement(marker: CachedMarkerType): Element {
        console.warn(
            "Some GenericMapLayer is using the default buildElement() implementation. You'll likely want to update this."
        );
        const el = document.createElement('div');
        const elMarker = document.createElement('div');
        elMarker.classList.add('mtp-map-marker');
        Object.assign(elMarker.style, {
            backgroundColor: `red`,
            color: `white`,
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
            width: '32px',
            height: '32px',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
        });
        elMarker.innerText = 'P';
        elMarker.classList.toggle('focused', marker.focus === 'focused');
        elMarker.classList.toggle('blurred', marker.focus === 'blurred');
        el.appendChild(elMarker);
        return el;
    }

    protected override onAddController(): void {
        // Refresh entity stream when the viewport or ordinal change
        MapHelper.onViewPortChangeThrottled(this.map)
            .pipe(
                takeUntil(this.cleanUp$),
                switchMap((viewBounds) =>
                    this.component.state.ordinal.stream.pipe(
                        filter(Boolean),
                        distinctUntilChanged(),
                        map(() => viewBounds)
                    )
                ),
                tap((viewBounds) => this.onViewportChange(viewBounds))
            )
            .subscribe();

        // Update markers when the entities change
        this.entities
            .pipe(
                takeUntil(this.cleanUp$),
                tap((entities) => this.updateMarkers(entities))
            )
            .subscribe();
    }

    protected override onRemoveController(): void {
        [...this.markerCache].forEach((m) => {
            this.removeMarker(m);
        });
        this.markerCache = [];
    }

    protected abstract createCachedMarker(entity: EntityType, focus: MarkerFocus): CachedMarkerType;

    protected isMarkerUpToDate(args: {
        cachedMarker: CachedMarkerType;
        oldEntity: EntityType;
        newEntity: EntityType;
        newZoom: number;
    }): boolean {
        // Default behavior:
        // If the entity has changed in any way, we need to update the marker.
        if (!isEqual(args.oldEntity, args.newEntity)) return false;
        // Marker is up-to-date
        return true;
    }

    protected updateMarkers(entities: Array<EntityType>): void {
        // Find the markers that are no longer needed and remove them
        this.markerCache
            .filter((m) => !entities.find((entity) => entity.id === m.entity.id))
            .forEach((m) => this.removeMarker(m));
        // Find the entities that are not yet marked and add them
        entities
            .filter((entity) => !this.markerCache.find((m) => entity.id === m.entity.id))
            .forEach((entity) => this.addMarker(entity));
        // Update the entities that are already marked
        entities
            // Find the cached marker
            .map((entity) => this.markerCache.find((m) => entity.id === m.entity.id))
            // Filter out any markers that are not cached, as we're only updating existing ones
            .filter(Boolean)
            // Get the potentially updated entity for this marker
            .map((cachedMarker) => ({ cachedMarker, entity: entities.find((p) => p.id === cachedMarker.entity.id) }))
            // Filter out any markers that are already up-to-date
            .filter(
                (m) =>
                    !this.isMarkerUpToDate({
                        cachedMarker: m.cachedMarker,
                        oldEntity: m.cachedMarker.entity,
                        newEntity: m.entity,
                        newZoom: this.map.getZoom(),
                    })
            )
            // Update markers that need updating
            .forEach((m) => this.updateMarker(m.cachedMarker, m.entity));
    }

    protected renderMarker(cachedMarker: CachedMarkerType): void {
        const el = this.buildElement(cachedMarker);
        const marker = new mapboxgl.Marker(el as HTMLElement, {
            anchor: this.markerAnchor,
        });
        marker.setLngLat([cachedMarker.entity.location.longitude, cachedMarker.entity.location.latitude]);
        marker.addTo(this.map);
        cachedMarker.marker = marker;
        this.registerClickHandler(cachedMarker);
    }

    protected removeMarker(marker: CachedMarkerType): void {
        marker.marker?.remove();
        this.markerCache.splice(this.markerCache.indexOf(marker), 1);
    }

    protected addMarker(entity: EntityType): CachedMarkerType {
        const cachedMarker = this.createCachedMarker(
            entity,
            this.focusedEntityId ? (entity.id === this.focusedEntityId ? 'focused' : 'blurred') : 'none'
        );
        // Render the marker
        if (this.handleRendering) this.renderMarker(cachedMarker);
        // Add the marker to the cache
        this.markerCache.push(cachedMarker);
        return cachedMarker;
    }

    protected updateMarker(cachedMarker: CachedMarkerType, entity: EntityType): void {
        // Update POI
        cachedMarker.entity = entity;
        // Remove old marker
        if (cachedMarker.marker) cachedMarker.marker.remove();
        cachedMarker.marker = undefined;
        // Render the marker
        if (this.handleRendering) this.renderMarker(cachedMarker);
    }

    protected onViewportChange(bounds: GeoJSON.Polygon): void {
        if (!this.component) return;
        const polygon = turf.polygon(bounds.coordinates);
        const floor = this.component.state.ordinal.value;
        const entities = this.queryHandler(bounds, floor).pipe(
            map((entities) =>
                entities.filter(
                    (entity) =>
                        // Component still has to be present
                        this.component &&
                        // Entities have to be on the current floor
                        entity.location.floor === floor.id &&
                        // Entities have to be within the current viewport
                        turf.booleanPointInPolygon(
                            turf.point([entity.location.longitude, entity.location.latitude]),
                            polygon
                        )
                )
            )
        );
        this.entityStream.next(entities);
    }

    protected registerClickHandler(cachedMarker: CachedMarkerType): void {
        if (!cachedMarker.marker) return;
        const el = cachedMarker.marker.getElement();
        el.onclick = (e): void => {
            e.preventDefault();
            e.stopPropagation();
            el.blur();
            if (this.component) {
                this.component.clickMarker(this.buildClickEvent(cachedMarker));
            }
            if (this.map) {
                this.map.getContainer().focus();
            }
        };
    }

    protected buildClickEvent(cachedMarker: CachedMarkerType): GenericMapMarkerClickEvent<EntityType> {
        return new GenericMapMarkerClickEvent<EntityType>(cachedMarker.marker, this, cachedMarker.entity);
    }
}
