import { Injectable } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { ITask } from 'shared';

import { SocketService } from '../socket.service';
import { SoundService } from '../sound.service';
import { AppStateService } from '../state/app-state.service';
import { TaskService } from '../task.service';
import { UserService } from '../user.service';
import { TaskAssignmentBaseSocketEventHandler } from './task-assignment-base.se-handler';

interface TaskAssignedSocketEvent {
    eventDtoType: 'TaskAssigned';
    assignedById: string;
    previousAssigneeId: string;
    task: ITask;
}

@Injectable({ providedIn: 'root' })
export class TaskAssignedSocketEventHandler extends TaskAssignmentBaseSocketEventHandler<TaskAssignedSocketEvent> {
    protected constructor(
        private readonly soundService: SoundService,
        taskService: TaskService,
        socketService: SocketService,
        userService: UserService,
        appState: AppStateService
    ) {
        super(taskService, userService, appState, socketService);
    }

    protected eventDtoType(): string {
        return 'TaskAssigned';
    }

    protected handleEvent = async (event: TaskAssignedSocketEvent): Promise<void> => {
        const hasPermission = await this.ensureLocalState(event);

        // Play sound effect if needed
        if (hasPermission) {
            this.appState.user.stream
                .pipe(
                    take(1),
                    filter((user) => event.task.assigneeId === user.id),
                    filter((user) => event.assignedById !== user.id)
                )
                .subscribe((_) => this.soundService.playSound('external_assign.mp3'));
        }
    };
}
