import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IPOILinkedLocatable } from 'shared';

import { LocationService } from '../services/location.service';

@Pipe({
    name: 'entityLocationDescription',
    pure: false,
})
export class EntityLocationDescriptionPipe implements PipeTransform {
    public constructor(private locationService: LocationService) {}

    public transform(locatable?: IPOILinkedLocatable): Observable<string> {
        return locatable
            ? this.locationService.getLocationDescription(locatable.location, locatable.poi ?? locatable.poiId)
            : of('');
    }
}
