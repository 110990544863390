import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocaleStringMap } from 'shared';

@Pipe({ name: 'fromLocaleStringMap', pure: false })
export class FromLocaleStringMapPipe implements PipeTransform {
    public constructor(private translate: TranslateService) {}

    public transform(value: any, ...args: Array<any>): any {
        if (typeof value === 'object') {
            return this.getTranslation(value, (args || [])[0]);
        }
    }

    public getTranslation(name: LocaleStringMap, replacements?: Record<string, string>): string | null {
        return this.translate.getParsedResult(name, this.translate.currentLang, replacements);
    }
}
