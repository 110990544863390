import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPOI } from 'shared';

import { POIService } from '../services/poi.service';

@Pipe({
    name: 'poi',
    pure: true,
})
export class PoiPipe implements PipeTransform {
    public constructor(private poiService: POIService) {}

    transform(poiId: string): Observable<IPOI> {
        return this.poiService.pois.pipe(map((pois) => pois.find((poi) => poi.id === poiId)));
    }
}
