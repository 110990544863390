import {
    AfterViewInit,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    QueryList,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { TabComponent } from './tab/tab.component';

@Component({
    selector: 'app-tab-bar',
    templateUrl: './tab-bar.component.html',
    styleUrls: ['./tab-bar.component.scss'],
})
export class TabBarComponent implements AfterViewInit, OnDestroy {
    @ContentChildren(TabComponent) tabComponents: QueryList<TabComponent>;
    @Output() tabChange: EventEmitter<string> = new EventEmitter<string>();
    active: string;
    subscriptions: Array<Subscription> = [];
    tabSubscriptions: Array<[TabComponent, Subscription]> = [];

    @Input() set tab(value: string) {
        this.active = value;
        this.refreshActiveTab();
    }

    ngAfterViewInit() {
        this.refreshActiveTab();
        this.subscriptions.push(this.tabComponents.changes.subscribe(this.refreshActiveTab));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.tabSubscriptions.forEach((s) => s[1].unsubscribe());
    }

    refreshActiveTab = () => {
        setTimeout(() => {
            if (!this.active) this.active = this.tabComponents.first ? this.tabComponents.first.value : null;
            this.tabComponents.forEach((tab) => {
                // Add new subscriptions for tabs that have not yet been subscribed to
                if (!this.tabSubscriptions.find((s) => s[0] === tab)) {
                    this.tabSubscriptions.push([
                        tab,
                        tab.activate.subscribe(() => {
                            this.active = tab.value;
                            this.tabChange.emit(tab.value);
                            this.refreshActiveTab();
                        }),
                    ]);
                }
                // Set the active status of the tab
                tab.active = this.active === tab.value;
            });
            // Remove subscriptions for tabs which are no longer present
            this.tabSubscriptions
                .filter((subscribedTab) => !this.tabComponents.find((_tab) => _tab === subscribedTab[0]))
                .forEach((subscribedTab) => {
                    subscribedTab[1].unsubscribe();
                    this.tabSubscriptions.splice(this.tabSubscriptions.indexOf(subscribedTab), 1);
                });
        }, 0);
    };
}
