import { groupBy } from 'lodash';
import { FillPaint } from 'mapbox-gl';
import {
    BehaviorSubject,
    Observable,
    TimeoutError,
    combineLatest,
    firstValueFrom,
    fromEvent,
    interval,
    merge,
    of,
} from 'rxjs';
import { debounceTime, filter, switchMap, take, takeUntil, timeout } from 'rxjs/operators';
import { IMapIMDFData, IMapIMDFStyles, IOrdinal } from 'shared';

import { DerivedStateValue } from '../../../../../utils/state-value';
import { MapLayerController } from './base-controllers/map-layer-controller';

const LAYER_BACKGROUND_FILL = 'imr-background-fill';
const SOURCE_BUILDINGS = 'imr-buildings';
const SOURCE_LEVELS = 'imr-levels';
const SOURCE_UNITS = 'imr-units';
const SOURCE_FIXTURES = 'imr-fixtures';

interface CachedSource {
    id: string;
    imdfOrdinalId?: string;
    category: string;
}

interface CachedLayer {
    id: string;
    imdfOrdinalId?: string;
}

export class IndoorMapMapLayerController extends MapLayerController {
    public readonly ordinals: DerivedStateValue<Array<IOrdinal> | null>;
    private _imdfDataStream = new BehaviorSubject<Observable<IMapIMDFData | null> | null>(null);
    private _imdfStylesStream = new BehaviorSubject<Observable<IMapIMDFStyles | null> | null>(null);
    private _imdfData = this._imdfDataStream.pipe(switchMap((stream) => stream ?? of(null)));
    private _imdfStyles = this._imdfStylesStream.pipe(switchMap((stream) => stream ?? of(null)));

    private builtSourceCache: CachedSource[] = [];
    private builtLayerCache: CachedLayer[] = [];

    public constructor(
        options: {
            imdfData?: IMapIMDFData;
            imdfDataStream?: Observable<IMapIMDFData>;
            imdfStyles?: IMapIMDFStyles;
            imdfStylesStream?: Observable<IMapIMDFStyles>;
        } = {}
    ) {
        super();

        if (options.imdfData && options.imdfDataStream) {
            throw Error('You cannot specify both `imdfData` and `imdfDataStream`. Pick one.');
        }
        if (options.imdfStyles && options.imdfStylesStream) {
            throw Error('You cannot specify both `imdfStyles` and `imdfStylesStream`. Pick one.');
        }
        if (options.imdfData) this.setIMDFData(options.imdfData);
        else if (options.imdfDataStream) this.setIMDFDataStream(options.imdfDataStream);
        if (options.imdfStyles) this.setIMDFStyles(options.imdfStyles);
        else if (options.imdfStylesStream) this.setIMDFStylesStream(options.imdfStylesStream);
    }

    public setIMDFData(data: IMapIMDFData): IndoorMapMapLayerController {
        return this.setIMDFDataStream(of(data));
    }

    public setIMDFDataStream(data: Observable<IMapIMDFData>): IndoorMapMapLayerController {
        this._imdfDataStream.next(data);
        return this;
    }

    public setIMDFStyles(styles: IMapIMDFStyles): IndoorMapMapLayerController {
        return this.setIMDFStylesStream(of(styles));
    }

    public setIMDFStylesStream(data: Observable<IMapIMDFStyles>): IndoorMapMapLayerController {
        this._imdfStylesStream.next(data);
        return this;
    }

    public override getBottomLayerId(): string | undefined {
        return this.builtLayerCache.length ? this.builtLayerCache[0].id : super.getBottomLayerId();
    }

    protected override onAddController(): void {
        // Completely rebuild sources and layers when IMDF data is updated
        combineLatest([this._imdfData, this._imdfStyles])
            .pipe(
                takeUntil(this.cleanUp$),
                debounceTime(10),
                filter(() => !!this.map),
                switchMap(async ([imdfData, imdfStyles]) => {
                    await this.buildSources(imdfData);
                    await this.buildLayers(imdfData, imdfStyles, this.component.state.ordinal.value);
                })
            )
            .subscribe();
        // Update layer visibility when just the ordinal is updated
        this.component.state.ordinal.stream
            .pipe(
                takeUntil(this.cleanUp$),
                debounceTime(10),
                filter(() => !!this.map),
                switchMap((ordinal) => this.updateLayerVisibility(ordinal))
            )
            .subscribe();
    }

    protected override onRemoveController(): void {
        this.builtSourceCache = [];
        this.builtLayerCache = [];
    }

    private async buildSources(imdfData: IMapIMDFData): Promise<void> {
        if (!this.map) return;
        // Wait for map to be loaded if needed
        await this.waitForMapLoad();
        // Remove pre-existing layers and sources
        this.builtLayerCache.forEach((layer) => {
            if (this.map.getLayer(layer.id)) this.map.removeLayer(layer.id);
        });
        this.builtLayerCache = [];
        this.builtSourceCache.forEach((source) => {
            if (this.map.getSource(source.id)) this.map.removeSource(source.id);
        });
        this.builtSourceCache = [];
        // Stop here if we don't have indoor map data
        if (!imdfData) return;
        // Add buildings source
        this.map.addSource(SOURCE_BUILDINGS, {
            type: 'geojson',
            data: imdfData.buildings,
        });
        this.builtSourceCache.push({ id: SOURCE_BUILDINGS, category: 'Building' } as CachedSource);
        // Define utility function for adding ordinal specific sources
        const addOrdinalBasedSources = (sourceName: string, featureCollection: GeoJSON.FeatureCollection): void => {
            // Group features per ordinal
            const featuresGroupedByOrdinal = groupBy(
                featureCollection.features,
                (feature: GeoJSON.Feature) => feature.properties.LEVEL_ID
            );
            Object.entries(featuresGroupedByOrdinal).forEach(([ordinalId, features]) => {
                // Per ordinal, group features per category
                const featuresGroupedByCategory = groupBy(
                    features,
                    (feature: GeoJSON.Feature) => feature.properties.CATEGORY
                );
                Object.entries(featuresGroupedByCategory).forEach(([category, features]) => {
                    // Determine the source id for this category
                    const sourceId = `${sourceName}-${ordinalId}-${category}`;
                    // Remove pre-existing source if needed
                    if (this.map.getSource(sourceId)) this.map.removeSource(sourceId);
                    // Add source
                    this.map.addSource(sourceId, {
                        type: 'geojson',
                        data: { type: 'FeatureCollection', features },
                    });
                    this.builtSourceCache.push({
                        id: sourceId,
                        imdfOrdinalId: ordinalId,
                        category,
                    } as CachedSource);
                });
            });
        };
        // Add sources for levels, units and fixtures
        addOrdinalBasedSources(SOURCE_LEVELS, imdfData.levels);
        addOrdinalBasedSources(SOURCE_UNITS, imdfData.units);
        addOrdinalBasedSources(SOURCE_FIXTURES, imdfData.fixtures);
    }

    private async waitForMapLoad(): Promise<void> {
        if (!this.map) throw new Error('Map is not initialized');
        if (!this.map.loaded()) {
            // The main load event does not always fire. That's why we'll poll the map's loaded states as well.
            try {
                await firstValueFrom(
                    merge([
                        combineLatest([
                            fromEvent(this.map, 'load'), // Standard load event
                            fromEvent(this.map, 'style.load'), // Style load event
                        ]),
                        interval(100).pipe(filter(() => this.map && this.map.loaded() && this.map.isStyleLoaded())), // Polling the loaded states
                    ]).pipe(take(1), timeout(5000))
                );
            } catch (e) {
                if (e instanceof TimeoutError) return;
                throw e;
            }
        }
    }

    private async updateLayerVisibility(ordinal: IOrdinal): Promise<void> {
        if (!this.map) return;
        // Wait for map to be loaded if needed
        await this.waitForMapLoad();
        // Update layer visibility
        this.builtLayerCache.forEach((layer) => {
            const shouldBeVisible = !layer.imdfOrdinalId || layer.imdfOrdinalId === ordinal.imdfId;
            this.map.setLayoutProperty(layer.id, 'visibility', shouldBeVisible ? 'visible' : 'none');
        });
    }

    private async buildLayers(imdfData: IMapIMDFData, imdfStyles: IMapIMDFStyles, ordinal: IOrdinal): Promise<void> {
        if (!this.map) return;
        // Wait for map to be loaded if needed
        await this.waitForMapLoad();
        // Remove pre-existing layers
        this.builtLayerCache.forEach((layer) => {
            if (this.map.getLayer(layer.id)) this.map.removeLayer(layer.id);
        });
        this.builtLayerCache = [];
        // Determine if there is an outdoor map
        const outdoorMap = Boolean(this.map.getStyle()?.metadata?.['mapbox:origin']);
        // Add a background layer if we don't have an outdoor map
        if (!outdoorMap) {
            this.map.addLayer({
                id: LAYER_BACKGROUND_FILL,
                type: 'background',
                paint: {
                    'background-color': '#dddddd',
                },
            });
            this.builtLayerCache.push({ id: LAYER_BACKGROUND_FILL });
        }
        // Stop here if we don't have indoor map data
        if (!imdfData) return;
        // Add indoor layers
        this.builtSourceCache.forEach((source) => {
            // Get the layer paint
            const paint = this.buildPaint(source.category, imdfStyles);
            if (!paint) return;
            // Add the layer
            this.map.addLayer({
                id: source.id,
                type: 'fill',
                source: source.id,
                layout: {
                    visibility: !source.imdfOrdinalId || source.imdfOrdinalId === ordinal?.imdfId ? 'visible' : 'none',
                },
                paint,
            });
            this.builtLayerCache.push({
                id: source.id,
                imdfOrdinalId: source.imdfOrdinalId,
            });
        });
        // Move all created layers to the correct location in the layer stack
        const nextLayerId = this.getBottomLayerIdForNextController();
        if (nextLayerId) {
            this.builtLayerCache.forEach((layer) => this.map.moveLayer(layer.id, nextLayerId));
        }
    }

    private buildPaint(styleName: string, imdfStyles: IMapIMDFStyles): FillPaint {
        if (!imdfStyles) return null;
        const style = imdfStyles[styleName];
        if (!style) return null;
        const paint: mapboxgl.FillPaint = {};
        paint['fill-color'] = style.fill ? style.fillColor : '#000000';
        paint['fill-opacity'] = style.fill ? style.fillOpacity || 1.0 : 0.0;
        if (style.stroke) {
            paint['fill-outline-color'] = style.color;
        }
        return paint as FillPaint;
    }
}
