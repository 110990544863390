<div class="control-area" [@controlArea]="showControlArea ? 'open' : 'closed'">
    <ng-content select="app-ml-side-control-header"></ng-content>
    <ng-content select="app-ml-side-control-content"></ng-content>
    <ng-content select="app-control-menu"></ng-content>
</div>
<div class="map-area" [class.control-area-visible]="showControlArea">
    <div class="map-container">
        <ng-content select=".map-layout-pane"></ng-content>
    </div>
    <div class="map-controls" [class.map-controls-visible]="showMapControls">
        <ng-content select="app-ml-overlay-controls"></ng-content>
    </div>
</div>
